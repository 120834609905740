import React, {useState} from "react";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/outline";

const Collapsible = ({ title, defaultOpen = false, children, classNames = '' }) => {

  // state
  const [open, setOpen] = useState(defaultOpen);

  // utilities
  const Icon = open ? ChevronDownIcon : ChevronRightIcon;

  return <>
    <div
      className={`flex gap-2 cursor-pointer ${classNames}`}
      onClick={() => { setOpen((prev) => !prev) }}
    >
      <Icon className="w-4" />
      <span className="text-base">{title}</span>
    </div>
    <div className={`border-l-2 pl-3 ml-1 mb-2 py-2 ${!open || !children ? "hidden" : ""}`}>
      {children}
    </div>
  </>
}

export default Collapsible;