import React from "react";

const DocumentationB = ({page}) => (
  <iframe
    title="id"
    width="100%"
    height="100%"
    style={{ minHeight: "100vh" }}
    frameborder="0"
    src={process.env.PUBLIC_URL + `/html-frameb/public/${page}.html`}
  />
);

export default DocumentationB;
