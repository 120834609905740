import React from "react";
import KpiBadge from "./KpiBadge";

const FiltersPreview = ({ filters, filterableColumns }) => {
  return (
    <div className="mb-4 md:mb-0">
      {filters.length ? (
        <div className="flex-wrap items-start flex md:justify-end max-w-md md:pl-10">
          {filters
            .sort((a, b) => {
              if (a.key < b.key) return -1;
              if (a.key > b.key) return 1;
              return 0;
            })
            .map((filter) => (
              <KpiBadge
                {...filter}
                key={`${filter.key}-${filter.value}`}
                colorIndex={filterableColumns.reduce(
                  (acc, col, index) =>
                    !acc && col.key === filter.key ? index : acc,
                  0
                )}
              />
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default FiltersPreview;
