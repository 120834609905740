export const COLUMNS = [
  {
    title: "ID",
    field: "id",
    type: "numeric",
  },
  {
    title: "ADR",
    field: "adr_ammagamma",
  },
  {
    title: "Volume",
    field: "val_volume",
    type: "numeric",
  },
  {
    title: "Numero pratiche",
    field: "practices_count",
    type: "numeric",
  },
  {
    title: "Dominio",
    field: "cod_domain",
  },
  {
    title: "Performance (previsto)",
    field: "performance_forecast",
    type: "numeric",
  },
  {
    title: "Recupero (previsto)",
    field: "val_collected_forecast",
    type: "numeric",
  },
  {
    title: "Performance (reale)",
    field: "performance_real",
    type: "numeric",
  },
  {
    title: "Recupero (reale)",
    field: "val_collected_real",
    type: "numeric",
  },
  {
    title: "Caricamento Norma",
    field: "status",
  },
];

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 20,
  },
  filterCellStyle: { paddingTop: 8, maxWidth: 200 },
  cellStyle: { border: "none" },
  actions: {
    border: "none",
  },
  actionsCellStyle: { border: "none" },
  pageSize: 10,
  pageSizeOptions: [5, 10, 20],
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
};
