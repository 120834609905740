import React, { useEffect, useState } from "react";
// UI
import MaterialTable from "material-table";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./LotDetailTableParams";
import { tableIcons } from "../../../ui-components/TableIcons";
import ApiService from "@services/ApiService";
import { useSelector } from "react-redux";

export default function LotDetailTable() {
  const currentLotId = useSelector((state) => state.run.currentLotId);
  const [lotDetail, setLotDetail] = useState(null);

  useEffect(() => {
    async function getLotDetail() {
      await ApiService.getApi(`optimization/lots/${currentLotId}`).then(
        (response) => {
          setLotDetail(response);
        }
      );
    }
    getLotDetail();
  }, [currentLotId]);
  return (
    <>
      <MaterialTable
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          borderRadius: 20,
          border: "none",
          zIndex: 0,
        }}
        columns={COLUMNS}
        data={lotDetail ?? []}
        isLoading={lotDetail === null}
        icons={tableIcons}
        options={OPTIONS}
        localization={LOCALIZATION}
      />
    </>
  );
}
