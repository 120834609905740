import React, { useCallback, useContext, useState } from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import ApiService from "@services/ApiService";

// Ui
import { LinearProgress, Paper, TableCell } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import { TableContainer, Table, TableBody, TableRow } from "@material-ui/core";
import theme from "@utils/theme";

import { PageHeading } from "../../Container";
import UpdatePassword from "../../forms/UpdatePassword";
import Input from "../../Input";
import Modal from "../../Modal";

import { NotificationsContext } from "../../Notifications";
import Button from "../../Button";

function Profile() {
  const [loading, setLoading] = useState(false);
  const userData = AuthenticationService.getUserData();
  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { push } = useContext(NotificationsContext);

  const handleChangePassword = useCallback(
    async (e) => {
      e.preventDefault();

      const data = {
        email: userData.email,
        password: password,
        new_password: newPassword,
      };
      try {
        setLoading(true);
        await ApiService.postApiJson("users/password/reset", data);
        setOpenPassword(!openPassword);
        push({
          title: "Password modificata con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Accesso negato",
          type: "error",
        });
      } finally {
        setLoading(false);
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    },
    [push, newPassword, userData, password, openPassword]
  );

  function readOnlyField(label, key) {
    return (
      <>
        <TableRow>
          <TableCell width="30%" height={50}>
            <b>{label}</b>
          </TableCell>
          <TableCell>{userData[key]}</TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      <LinearProgress
        style={{
          visibility: loading ? "visible" : "hidden",
        }}
      />

      <PageHeading title="Il mio profilo" />

      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {readOnlyField("Tipo utente", "role")}
            {readOnlyField("Nome", "full_name")}
            {readOnlyField("Email", "email")}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="w-full flex justify-between items-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPassword(!openPassword)}
        >
          Modifica password
        </Button>

        <Button
          styleType="secondary"
          onClick={() => {
            AuthenticationService.logout();
            window.location = "/login";
          }}
        >
          Logout
        </Button>
      </div>
      {openPassword ? (
        <Modal onExit={() => setOpenPassword(!openPassword)}>
          <div className="m-8">
            <h3 className="text-center text-2xl font-extrabold text-gray-900">
              Modifica password
            </h3>
          </div>

          <form onSubmit={handleChangePassword}>
            <Input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label="Password"
              type="password"
              errorMessage="Inserisci password attuale"
              required
            />
            <DialogContentText style={{ marginTop: theme.spacing(2) }}>
              Inserire una password lunga almeno 8 caratteri, senza spazi
            </DialogContentText>
            <UpdatePassword
              setPassword={setNewPassword}
              setConfirmPassword={setConfirmPassword}
              password={newPassword}
              confirmPassword={confirmPassword}
            />
            <div className="mt-4 flex items-end justify-end">
              <Button type="submit" submitting={loading}>
                Invia
              </Button>
            </div>
          </form>
        </Modal>
      ) : null}
    </>
  );
}

export default Profile;
