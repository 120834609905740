import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  configurationData: null,
  refreshConfigurationData: false,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setConfigurationData: (state, action) => {
      state.configurationData = action.payload;
    },
    setRefreshConfigurationData: (state, action) => {
      state.refreshConfigurationData = action.payload;
    },
  },
});

export const {
  setConfigurationData,
  setRefreshConfigurationData,
} = configurationSlice.actions;

export default configurationSlice.reducer;
