import {
  HomeIcon,
  ChartBarIcon,
  AdjustmentsIcon,
  PhoneMissedCallIcon,
  DocumentTextIcon,
  TemplateIcon,
} from "@heroicons/react/outline";
import Home from "@pages/Home";
import Analytics from "@pages/Analytics";
import ControlPanel from "@pages/ControlPanel";
import UnreachableUsers from "@pages/UnreachableUsers";
import DocumentationA from "../components/Tables/Params/DocumentationA";
import DocumentationB from "../components/Tables/Params/DocumentationB";
import ConfigureOptimizerPage from "../pages/LotsCreation/ConfigureOptimizer/ConfigureOptimizerPage";
import HistoryOptimizerPage from "../pages/LotsCreation/HistoryOptimizer/HistoryOptimizerPage";
import RunOptimizerPage from "../pages/LotsCreation/RunOptimizer/RunOptimizerPage";

const routes = [
  {
    scopes: ["frame-a:read"],
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
  },
  {
    scopes: ["frame-a:read"],
    name: "Analytics",
    href: "/analytics",
    Icon: ChartBarIcon,
    component: Analytics,
  },
  {
    scopes: ["frame-a:read"],
    name: "Pannello di controllo",
    href: "/control-panel",
    Icon: AdjustmentsIcon,
    component: ControlPanel,
  },
  {
    scopes: ["frame-a:read"],
    name: "Utenze non contattabili",
    href: "/unreachable-users",
    Icon: PhoneMissedCallIcon,
    component: UnreachableUsers,
  },
  {
    name: "Documentazione",
    Icon: DocumentTextIcon,
    dropdown: [
      {
        scopes: ["frame-a:read"],
        name: "Invio solleciti",
        href: "/doc-a",
        component: DocumentationA,
      },
      {
        scopes: ["optimization:read"],
        name: "Ottimizzazione lotti - Modellazione",
        href: "/doc-b-solution",
        component: (props) => <DocumentationB page="solution" {...props} />,
      },
      {
        scopes: ["optimization:read"],
        name: "Ottimizzazione lotti - Uso dell'applicativo",
        href: "/doc-b-usage",
        component: (props) => <DocumentationB page="usage" {...props} />,
      },
    ],
  },
  {
    name: "Creazione lotti",
    Icon: TemplateIcon,
    dropdown: [
      {
        scopes: ["optimization:read"],
        href: "/configure-optimizer",
        name: "Configura ottimizzatore",
        component: ConfigureOptimizerPage,
      },
      {
        scopes: ["optimization:read"],
        href: "/run-optimizer",
        name: "Suggerimenti di assegnazione",
        component: RunOptimizerPage,
      },
      {
        scopes: ["optimization:read"],
        href: "/history-optimizer",
        name: "Analisi storico recupero",
        component: HistoryOptimizerPage,
      },
    ],
  },
];

export default routes;
