import { PageHeading } from "../../../ui-components/Container";
import StatusHeader from "./StatusHeader";
import StepperControl from "./StepperControl";
import ScopeDefinition from "./ScopeDefinition";
import SplitDefinition from "./SplitDefinition";
import ADRSelection from "./ADRSelection";
import ListOptimizerPage from "./ListOptimizerPage";
import useConfigureContextHook from "../State/ConfigureOptimizerContext";
import NameDefinition from "./NameDefinition";

export default function ConfigureOptimizerPage() {
  const { context, ConfigureOptimizerContext } = useConfigureContextHook();

  function displaySteps(currentStep) {
    switch (currentStep) {
      case 1:
        return <ScopeDefinition />;
      case 2:
        return <SplitDefinition />;
      case 3:
        return <ADRSelection />;
      case 4:
        return <NameDefinition />;
      default:
        return <ScopeDefinition />;
    }
  }

  return (
    <ConfigureOptimizerContext.Provider value={context}>
      {context.isConfigureOpen ? (
        <>
          <PageHeading title="Configura ottimizzatore" />
          <StatusHeader />
          {displaySteps(context.currentStep)}
          <StepperControl />
        </>
      ) : (
        <ListOptimizerPage />
      )}
    </ConfigureOptimizerContext.Provider>
  );
}
