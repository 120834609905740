import React, { useCallback, useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";
import Label from "../../ui-components/Label";
import Button from "../../ui-components/Button";
import Placeholder from "../../ui-components/Placeholder";
import ApiService from "../../services/ApiService";
import { NotificationsContext } from "../../ui-components/Notifications";

const dateAlreadyClicked = (dates, date) =>
  dates.map((d) => d.getTime()).indexOf(date.getTime()) >= 0;

const today = new Date();
const getStringDate = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const getNewDate = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);

  return d;
};

const CalendarInput = () => {
  const [savedOffDays, setSavedOffDays] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [offDays, setOffDays] = useState([]);
  const [error, setError] = useState(false);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    let cancel = false;
    const fetch = async () => {
      try {
        setSubmitting(true);
        const response = await ApiService.getApi("control-panel/off-days");

        if (!cancel) {
          setSavedOffDays(response.map((d) => getNewDate(d.data)));
          setOffDays(response.map((d) => getNewDate(d.data)));
        }
      } catch (error) {
        if (!cancel) {
          setError(error);
        }
      } finally {
        setSubmitting(false);
      }
    };

    fetch();

    return () => {
      cancel = true;
    };
  }, []);

  const saveOffDays = useCallback(async () => {
    try {
      const daysToUpdate = [
        ...offDays
          .filter(
            (d) => savedOffDays.map((o) => o.getTime()).indexOf(d.getTime()) < 0
          )
          .map((d) => ({ data: d, add: true })),

        ...savedOffDays
          .filter(
            (d) => offDays.map((d) => d.getTime()).indexOf(d.getTime()) < 0
          )
          .map((d) => ({ data: d, add: false })),
      ].map((d) => ({ ...d, data: getStringDate(d.data) }));

      const response = await ApiService.postApiJson(
        "control-panel/off-days",
        daysToUpdate
      );
      setSavedOffDays(response.map((d) => getNewDate(d.data)));
      setOffDays(response.map((d) => getNewDate(d.data)));
      push({
        title: "Giornate disattivate salvate",
        type: "success",
      });
    } catch (error) {
      push({
        title: "Qualcosa non ha funzionato durante il salvataggio",
        type: "error",
      });
    }
  }, [offDays, savedOffDays]);

  const tileClassName = useCallback(
    ({ date }) => {
      if (dateAlreadyClicked(offDays, new Date(date))) return "day-disabled";
      return null;
    },
    [offDays]
  );

  if (error) {
    throw error;
  }

  return (
    <div className="flex flex-col">
      <Label>Giorni disattivati</Label>
      <div className="mb-4" />
      {!savedOffDays || !offDays ? (
        <Placeholder height="h-80" width="w-full" />
      ) : (
        <Calendar
          className={["shadow bg-gray-50 rounded-lg px-4 py-6 border-0"]}
          tileClassName={tileClassName}
          onClickDay={(e) => {
            const clickDate = getNewDate(e);
            if (
              offDays.map((d) => d.getTime()).indexOf(clickDate.getTime()) >= 0
            ) {
              setOffDays([
                ...offDays.filter((d) => d.getTime() !== clickDate.getTime()),
              ]);
              return;
            }
            setOffDays([...offDays, clickDate]);
          }}
          minDate={tomorrow}
        />
      )}
      <div className="mb-4" />

      <div className="flex justify-end">
        <Button submitting={submitting} onClick={saveOffDays}>
          SALVA
        </Button>
      </div>
    </div>
  );
};

export default CalendarInput;
