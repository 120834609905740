import React from "react";

const AlertAnalyticsEmptyData = () => {

  return (
    <div className="bg-gray-100 text-gray-500 h-32 flex items-center justify-center">
      <span>Dati non disponibili per il periodo selezionato</span>
    </div>
  )
}

export default AlertAnalyticsEmptyData;