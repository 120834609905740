import 'react-tippy/dist/tippy.css';
import { withTooltip } from 'react-tippy';
import {QuestionMarkCircleIcon} from "@heroicons/react/solid";

const Tooltip = ({
    text,
    placement="top",
    className="w-4 h-4 ml-1 inline-block",
    options={},
    ...rest
}) => {

  const component = (props) => (
    <QuestionMarkCircleIcon
      className={className}
      {...props}
    />
  );

  // @ts-ignore
  return withTooltip(
    component,
    {
      title: text,
      position: placement,
      animation: 'fade',
      arrow: true,
      hideOnClick: true,
      ...options
    }
  )();
};

export default Tooltip;
