import React, {useEffect, useRef} from "react";
import MaterialTable from "material-table";
import {tableIcons} from "../../ui-components/TableIcons";
import {COLUMNS, LOCALIZATION, OPTIONS} from "./Params/SolicitsHistory";
import _ from "lodash";

const SolicitsHistoryTable = ({dataFn, refreshValues, downloading}) => {

  // ref
  const didMount = useRef(false);
  const tableRef = useRef(null);

  // utilities
  const refresh = () => { tableRef.current?.onQueryChange(undefined); }

  useEffect(() => {
    if (didMount.current)
      refresh();
    else
      didMount.current = true;
  }, refreshValues);

  return <MaterialTable
    tableRef={tableRef}
    columns={COLUMNS}
    data={dataFn}
    icons={tableIcons}
    localization={LOCALIZATION}
    isLoading={downloading}
    options={OPTIONS}
  />
}

export default React.memo(SolicitsHistoryTable, (props, nextProps) => {
  return _.isEqual(props.refreshValues, nextProps.refreshValues)
});
