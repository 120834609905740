export const getInitials = (name) => {
  let initials = name.split(" ");

  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }

  return initials.toUpperCase();
};

// ##### DATE FUNCTIONALITIES ######
export const getDateOfISOWeek = (weekNumber, year) => {
  // given week number and year, return a Date object
  // with day as the first day of the week
  let simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

export const getDateOfISOYearMonth = (year) => {
  return new Date(year, 0, 1);
};

export const getWeekFromDate = (inputDate) => {
  // given a date, returns the week number of that day
  const firstMonday = getNextMonday(new Date(inputDate.getFullYear(), 0, 1));
  const numberOfDays = Math.floor(
    (inputDate - firstMonday) / (24 * 60 * 60 * 1000)
  ); // milliseconds in one day
  if (numberOfDays < 0) return 52;
  return Math.floor(numberOfDays / 7) + 1;
};

const getCurrentMondayDate = (inputDate) => {
  let result = new Date(inputDate);
  const dayNumber = (result.getDay() || 7) - 1; // Get current day number, converting Sun. to 7
  result.setDate(result.getDate() - dayNumber);
  return result;
};

const getNextMonday = (inputDate) => {
  const lastMonday = getCurrentMondayDate(inputDate);

  let result = new Date(lastMonday);
  result.setDate(result.getDate() + 7);
  return result;
};
