import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import React, { useLayoutEffect } from "react";
import {addLicense} from "./commons";
import _ from "lodash";

function AdrPerformanceBarchart({
    adrs,
    data,
    showPercentages,
    height="350px"
}) {
  useLayoutEffect(
    () => {
      addLicense();
      var root = am5.Root.new("adr-chart");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      root.numberFormatter.set("numberFormat", showPercentages ? "#.##" : "#.0a");

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        })
      );

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          layout: root.gridLayout,
        })
      );

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.3,
        cellEndLocation: 0.7,
        minGridDistance: 10,
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "year",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xRenderer.grid.template.setAll({
        location: 1,
      });

      xAxis.data.setAll(data);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          ...(showPercentages ? {max: 100} : {}),
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1,
          }),
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name, fieldName, stacked, color) {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            stacked: stacked,
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: "year",
            fill: color,
          })
        );

        series.columns.template.setAll({
          tooltipText: `[bold]ADR[/]: {name}\n[bold]{categoryX}[/]: ${!showPercentages ? '€' : ''}{valueY}${showPercentages ? '%' : ''}`,
          width: am5.percent(90),
          tooltipY: am5.percent(10),
        });
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        // series.bullets.push(function() {
        //   return am5.Bullet.new(root, {
        //     locationY: 0.5,
        //     sprite: am5.Label.new(root, {
        //       text: "{valueY}",
        //       fill: root.interfaceColors.get("alternativeText"),
        //       centerY: am5.percent(50),
        //       centerX: am5.percent(50),
        //       populateText: true
        //     })
        //   });
        // });

        if (!stacked)
          legend.data.push(series);

        return series;
      }

      const colors = am5.ColorSet.new(root, {});
      let currentColor = colors.next();
      Object.entries(adrs).forEach(([id, name]) => {
        makeSeries(name, showPercentages ? id : `${id}-collected`, false, currentColor);
        if (!showPercentages) {
          const alphaColor = am5.Color.lighten(currentColor, .5);
          makeSeries(`${name} (non recuperato)`, `${id}-uncollected`, true, alphaColor);
        }
        currentColor = colors.next();
      });

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    },
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div id="adr-chart" style={{ height: height }} />
  );
}

export default React.memo(AdrPerformanceBarchart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
