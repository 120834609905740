import _ from "lodash";
import XLSX from "xlsx";

export const excelDownload = (data, columns, fields, name) => {
  const select = data.map((key) => {
    return _.pick(key, fields);
  });

  const workSheet = XLSX.utils.json_to_sheet([]);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(workSheet, [columns]);
  XLSX.utils.sheet_add_json(workSheet, select, {
    origin: "A2",
    skipHeader: true,
  });
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${name}.xlsx`);
};
