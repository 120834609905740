import { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";

export default function StatusHeader() {
  const [newStep, setNewStep] = useState([]);
  const stepRef = useRef();
  const context = useContext(ConfigureOptimizerContext);

  const checkmark = "✓";

  const stepDescription = [
    "Definizione perimetro",
    "Definizione split",
    "Selezione ADR",
    "Nome configurazione",
  ];

  function updateStep(stepNumber, steps) {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: false,
        };
        count++;
      } else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  }

  useEffect(() => {
    const stepState = stepDescription.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );
    stepRef.current = stepState;
    const current = updateStep(context.currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [context.currentStep]);

  const displaySteps = newStep?.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "w-full flex items-center"
            : "flex items-center"
        }
      >
        <div className="relative flex flex-col items-center text-teal-600">
          <div
            className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-9 w-9 flex items-center justify-center ${
              step.selected ? "bg-am-600 text-white font-bold border " : ""
            }`}
          >
            {step.completed ? (
              <span className="text-white font-bold text-xl">{checkmark}</span>
            ) : (
              index + 1
            )}
          </div>
          <div
            className={`absolute top-0 text-center mt-10 w-32 text-xs font-medium ${
              step.highlighted ? "text-gray-900" : "text-gray-400"
            }`}
          >
            {step.description}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 transition duration-500 ease-out ${
            step.completed ? "border-gray-900" : "border-gray-300"
          }`}
        ></div>
      </div>
    );
  });

  return (
    <div className="md:w-1/2 mx-auto pb-6">
      <div className="container horizontal mt-0">
        <div className="mx-2 p-4 flex justify-between items-center">
          {displaySteps}
        </div>
      </div>
    </div>
  );
}
