import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getTooltip, getXYChart, addCursor} from "./commons";


function ReasonBarChart({id, data}) {

  useLayoutEffect(() => {

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getXYChart(root, {})
    addCursor(root, chart)

    // Create axes
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'ragione',
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 10
      })
    }))

    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontSize: 8
    });
    yRenderer.grid.template.set("forceHidden", true);

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      logarithmic: true
    }))

    // Add series
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "value",
      categoryYField: "ragione",
      tooltip: getTooltip(root, "[bold]{categoryY}[/] {valueX}")
    }))

    series.columns.template.setAll({
      cornerRadiusTR: 5,
      cornerRadiusBR: 5
    });

    // Add data
    yAxis.data.setAll(data);
    series.data.setAll(data);

    // Make stuff animate on load
    series.appear(1000)

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minWidth: "750px", minHeight: "400px"}}/>
}

export default React.memo(ReasonBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
