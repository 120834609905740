import React, { useEffect, useState, useContext } from "react";

import {
  setDetailRun,
  setCurrentRunId,
  setActiveRun,
  setCurrentRun,
} from "../../../store/runSlice";

// UI
import MaterialTable from "material-table";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./RunOptimizerTableParams";
import { tableIcons } from "../../../ui-components/TableIcons";
import { useDispatch } from "react-redux";
import ApiService from "@services/ApiService";
import { NotificationsContext } from "../../../ui-components/Notifications";

export default function RunOptimizerTable({ refresh }) {
  const dispatch = useDispatch();
  const [runs, setRuns] = useState(null);
  const { push } = useContext(NotificationsContext);
  const [triggerApiCall, setTriggerApiCall] = useState(false);

  useEffect(() => {
    async function getRuns() {
      await ApiService.getApi("optimization/run/all").then((response) => {
        const runs = response.map((item) => {
          let norma_status = "";
          if (item.norma_upload_status === "PENDING") {
            norma_status = "In caricamento";
          } else if (item.norma_upload_status === "UPLOADED") {
            norma_status = "Caricato";
          } else if (item.norma_upload_status === "NOT_UPLOADED") {
            norma_status = "Non caricato";
          }
          let run_status = "";
          if (
            item.cod_status === "PROCESSING" ||
            item.cod_status === "PENDING"
          ) {
            run_status = "In caricamento";
          } else if (item.cod_status === "SUCCESS") {
            run_status = "Terminata con successo";
          } else if (item.cod_status === "ERROR") {
            run_status = "Errore";
          }
          return {
            id: item.id,
            date: item.tms_start,
            volume: item.val_volume,
            author: item.author.full_name,
            name: item.configuration_name,
            upload_status: item.norma_upload_status,
            norma_status: norma_status,
            run_status: run_status,
            cod_status: item.cod_status,
          };
        });
        const activeRun = runs.some((item) => {
          return (
            item.cod_status === "PENDING" || item.cod_status === "PROCESSING"
          );
        });
        dispatch(setActiveRun(activeRun));
        setRuns(runs);
      });
    }
    getRuns();
  }, [refresh, triggerApiCall]);

  // upload_status
  // PENDING = 'PENDING'
  // UPLOADED = 'UPLOADED'
  // NOT_UPLOADED = 'NOT_UPLOADED'

  // cod status
  // PENDING = 'PENDING'
  // PROCESSING = 'PROCESSING'
  // SUCCESS = 'SUCCESS'
  // ERROR = 'ERROR'

  function handleInfo(rowData) {
    if (rowData.cod_status === "SUCCESS") {
      dispatch(setCurrentRunId(rowData.id));
      dispatch(setDetailRun(true));
      dispatch(setCurrentRun(rowData));
    }
  }

  async function handleExport(rowData) {
    if (
      rowData.upload_status === "NOT_UPLOADED" &&
      rowData.cod_status === "SUCCESS"
    ) {
      try {
        await ApiService.postApiJson(`optimization/run/${rowData.id}/export`);
        setTriggerApiCall(!triggerApiCall);
        push({
          title: "File caricato verso Norma con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Errore nel caricamento verso Norma",
          type: "error",
        });
      }
    }
  }

  return (
    <>
      <MaterialTable
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          borderRadius: 20,
          border: "none",
          zIndex: 0,
        }}
        columns={COLUMNS}
        data={runs ?? []}
        isLoading={runs === null}
        icons={tableIcons}
        options={OPTIONS}
        localization={LOCALIZATION}
        actions={[
          (rowData) => ({
            icon:
              rowData.cod_status === "PROCESSING" ||
              rowData.cod_status === "PENDING"
                ? tableIcons.Spinner
                : rowData.cod_status === "ERROR"
                ? tableIcons.Error
                : tableIcons.Info,
            tooltip: rowData.cod_status === "SUCCESS" ? "Dettaglio Run" : null,
            onClick: (event, rowData) => handleInfo(rowData),
          }),
          (rowData) => rowData.cod_status === "SUCCESS"
            ? {
              icon: rowData.upload_status === "PENDING"
                ? tableIcons.Clock
                : (
                  rowData.upload_status === "UPLOADED"
                    ? tableIcons.Check
                    : tableIcons.CloudUpload
                ),
              tooltip: "Invio a Norma",
              onClick: (event, rowData) => handleExport(rowData),
            }
            : null,
        ]}
      />
    </>
  );
}

// export default React.memo(RunOptimizerTable, (props, nextProps) => {
//   // Render component only when users changes
//   return _.isEqual(props.data, nextProps.data);
// });

// refresh icon per il running -> animate spin di tailwind
// cloud heroicon
// export
