import {useContext} from "react";
import ApiService from "../../../../services/ApiService";
import { useSelector } from "react-redux";
import _ from "lodash";
import {NotificationsContext} from "../../../../ui-components/Notifications";
import {isBlockValid} from "../utilities";

const KEYS = [
  'grp_stati_soggetto',
  'grp_stati_contratto',
  'grp_portali',
  'grp_regioni',
  'grp_tipologie_cliente',
  'grp_utenze',
  'grp_commodity',
  'grp_stazione_spool',
  'rng_aging',
  'rng_eta',
  'rng_volume',
];

function useListOptimizerData(blockPreviews, setBlockPreviews) {

  // context
  const { push } = useContext(NotificationsContext);

  // shared state
  const configurationData = useSelector(
    (state) => state.configuration.configurationData
  );

  const handleBlocksPreview = async (blocks) =>
    await ApiService.postApiJson(
      `optimization/practice/blocks-preview`,
      blocks.map((b) => _.pick(b, KEYS)),
    );

  const fetchBlocksPreview = async (cid) => {
    if (cid in blockPreviews)
      return;

    const configuration = configurationData?.find((conf) => conf.id === cid);
    if (!configuration) {
      push({type: 'error', title: "Impossibile recuperare i blocchi per la configurazione selezionata"});
      return
    }

    const blocksWithPreview = await handleBlocksPreview(configuration.blocks)
    setBlockPreviews((prev) => ({
      ...prev,
      [cid]: blocksWithPreview
        .map((b) => {
          const previous = configuration.blocks.find((pb) => _.isEqual(_.pick(pb, KEYS), _.pick(b, KEYS)));
          const current = {...(previous ?? {}), ...b}
          return {...current, valid: isBlockValid(current)};
        })
    }));
  }

  return { fetchBlocksPreview };
}

export default useListOptimizerData;
