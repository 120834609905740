import {useContext, useState} from "react";
import ApiService from "../services/ApiService";
import {NotificationsContext} from "../ui-components/Notifications";
import {createFilterParams, createOrderingParams} from "../utils/pagination";

const useSolicitsHistory = () => {

  // hooks
  const { push } = useContext(NotificationsContext);

  // state
  const [downloading, setDownloading] = useState(false);

  const fetch = (query, start, end=null) =>
    new Promise(async (resolve, reject) => {
      const filters = createFilterParams(query);
      const ordering = createOrderingParams(query);
      const params = new URLSearchParams({
        start: start.toISOString(),
        ...(!!end ? {end: end.toISOString()} : {}),
        ...({offset: query.page * query.pageSize}),
        ...({limit: query.pageSize}),
        ...(filters && {filters}),
        ...(ordering && {ordering})
      })

      setDownloading(true);
      try {
        const {items, total} = await ApiService.getApi('analytics/solicits-history', '?' + params.toString());
        resolve({data: items, page: query.page, totalCount: total});
      } catch (e) {
        push({type: 'error', title: 'Errore durante il caricamento', text: 'Storico solleciti'});
        reject();
      } finally {
        setDownloading(false);
      }
    })

  return { fetch, downloading };
}

export default useSolicitsHistory;