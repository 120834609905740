import React, { useCallback, useContext, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import styled from "styled-components";
// UI
import {PageHeading, PageSection} from "../../ui-components/Container";
import Input from "../../ui-components/Input";
import Label from "../../ui-components/Label";
import Placeholder from "../../ui-components/Placeholder";
import Slider from "../../ui-components/Slider";
import Summary from "./Summary";
import useUpdateSummary from "./useUpdateSummary";
import { NotificationsContext } from "../../ui-components/Notifications";
import Toggle from "../../ui-components/Toggle";
import CalendarInput from "./CalendarInput";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import ApplicationStatus from "../../components/ApplicationStatus";
import Container from "../../ui-components/Container";
import InhibitionsHandler from "../../components/InhibitionsHandler";

const sliderSmsEmailMarks = [
  {
    value: 0,
    label: "SMS",
  },
  {
    value: 1,
    label: "EMAIL",
  },
];
const sliderContentMarks = [
  {
    value: 0,
    label: "TEXT",
  },
  {
    value: 0.5,
    label: "VIDEO",
  },
  {
    value: 1,
    label: "WEB",
  },
];

const initialSummary = {
  title: "Impostazioni attuali",
};

const ControlPanel = () => {
  const [currentSetting, setCurrentSetting] = useState(null);
  const [defaultSetting, setDefaultSetting] = useState(null);
  const [error, setError] = useState(false);
  const [sliderSmsEmail, setSliderSmsEmail] = useState(0.5);
  const [sliderContent, setSliderContent] = useState([0.3, 0.8]);
  const [minSolicitousValue, setMinSolicitousValue] = useState(0);
  const [monthlyCap, setMonthlyCap] = useState(0);
  const [dailyCap, setDailyCap] = useState(0);
  const [onlyCompanion, setOnlyCompanion] = useState(false);
  const [systemDisabled, setSystemDisabled] = useState(false);
  const setDefault = useCallback(() => {
    setFullPanelInput(defaultSetting, true);
  }, [defaultSetting]);
  const { push } = useContext(NotificationsContext);
  const setFullPanelInput = useCallback(
    (
      {
        sms,
        ptext,
        pvideo,
        minimo_valore_sollecitabile,
        cap_invii_giornalieri,
        cap_invii_mensili,
        solo_accompagnamenti,
        sistema_spento,
      },
      skipSystemDisabled
    ) => {
      setSliderSmsEmail(sms);
      setSliderContent([ptext, ptext + pvideo]);
      setMinSolicitousValue(minimo_valore_sollecitabile);
      setDailyCap(cap_invii_giornalieri);
      setMonthlyCap(cap_invii_mensili);
      setOnlyCompanion(solo_accompagnamenti);
      if (!skipSystemDisabled) {
        setSystemDisabled(sistema_spento);
      }
    },
    []
  );

  const [summary, setSummary] = useState(initialSummary);

  const saveCurrentSettings = useCallback(async () => {
    try {
      const response = await ApiService.postApiJson("control-panel/current", {
        tipologia: "current",
        sms: parseFloat(sliderSmsEmail.toFixed(2)),
        email: parseFloat((1 - sliderSmsEmail).toFixed(2)),
        pweb: parseFloat((1 - sliderContent[1]).toFixed(2)),
        ptext: parseFloat(sliderContent[0].toFixed(2)),
        pvideo: parseFloat((sliderContent[1] - sliderContent[0]).toFixed(2)),
        minimo_valore_sollecitabile: minSolicitousValue,
        cap_invii_giornalieri: dailyCap,
        cap_invii_mensili: monthlyCap,
        solo_accompagnamenti: onlyCompanion,
        sistema_spento: systemDisabled,
      });
      setCurrentSetting(response);
      push({
        title: "Impostazioni salvate",
        type: "success",
      });
    } catch (error) {
      push({
        title: "Qualcosa non ha funzionato durante il salvataggio",
        type: "error",
      });
    }
  }, [
    currentSetting,
    sliderSmsEmail,
    sliderContent,
    minSolicitousValue,
    monthlyCap,
    dailyCap,
    onlyCompanion,
    systemDisabled,
  ]);

  useUpdateSummary({
    summary,
    defaultSetting,
    currentSetting,
    sliderSmsEmail,
    sliderContent,
    minSolicitousValue,
    monthlyCap,
    dailyCap,
    onlyCompanion,
    systemDisabled,
    setDefault,
    setSummary,
    saveCurrentSettings,
  });

  useEffect(() => {
    let cancel = false;
    const fetch = async () => {
      try {
        const response = await ApiService.getApi("control-panel");

        if (!cancel) {
          const current = response.find((r) => r.tipologia === "current");
          setDefaultSetting(response.find((r) => r.tipologia === "default"));
          setCurrentSetting(current);
          setFullPanelInput(current);
        }
      } catch (error) {
        if (!cancel) {
          setError(error);
        }
      }
    };

    fetch();

    return () => {
      cancel = true;
    };
  }, []);

  if (error) {
    throw error;
  }

  let component = (
    <div className="flex flex-col w-full">
      <Placeholder height="h-60" classNames="mb-8 rounded-lg" />
    </div>
  );

  if (currentSetting) {
    component = (
      <div className="flex flex-col w-full">
        <Summary {...summary} />
        <div className="mb-12" />
        <div className="flex w-full flex-col md:flex-row-reverse">
          <div className="flex flex-col w-full md:w-2/4 md:pl-6">
            <ErrorBoundary from="calendar-input" hide>
              <CalendarInput />
            </ErrorBoundary>
          </div>
          <div className="flex flex-col w-full md:w-2/4 md:pr-6">
            <div className="flex w-full items-center justify-between">
              <Label>Sistema disabilitato</Label>
              <Toggle
                checked={systemDisabled}
                onChange={() => setSystemDisabled(!systemDisabled)}
                classNames="mt-0"
              />
            </div>
            <div className="flex w-full items-center justify-between">
              <Label>Solo accompagnamenti</Label>
              <Toggle
                checked={onlyCompanion}
                onChange={() => setOnlyCompanion(!onlyCompanion)}
                classNames="mt-0"
              />
            </div>
            <div className="mb-12" />
            <Input
              value={minSolicitousValue}
              onChange={(e) => {
                if (!e.target.value) {
                  setMinSolicitousValue(0);
                  return;
                }
                setMinSolicitousValue(parseInt(e.target.value));
              }}
              label="Valore minimo sollecitibile (€)"
              validation={(value) => {
                return parseInt(value) >= 0;
              }}
              type="number"
              errorMessage="Valore minimo sollecitabile non valido"
              required
            />
            <div className="mb-6" />
            <Input
              value={dailyCap}
              onChange={(e) => {
                if (!e.target.value) {
                  setDailyCap(0);
                  return;
                }
                setDailyCap(parseInt(e.target.value));
              }}
              label="Numero massimo invii giornalieri"
              validation={(value) => {
                return parseInt(value) >= 0;
              }}
              type="number"
              errorMessage="Questo valore non può essere negativo"
              required
            />
            <div className="mb-6" />
            <Input
              value={monthlyCap}
              onChange={(e) => {
                if (!e.target.value) {
                  setMonthlyCap(0);
                  return;
                }
                setMonthlyCap(parseInt(e.target.value));
              }}
              label="Numero massimo invii mensili"
              validation={(value) => {
                return parseInt(value) >= 0;
              }}
              type="number"
              errorMessage="Questo valore non può essere negativo"
              required
            />
          </div>
        </div>
        <div className="mb-12" />

        <SliderSmsEmailStyle className="flex flex-col w-full">
          <Label>Ripartizione sui canali</Label>
          <div className="mb-6" />
          <Slider
            value={sliderSmsEmail}
            onChange={(e, newValue) => setSliderSmsEmail(newValue)}
            min={0}
            max={1}
            step={0.01}
            marks={sliderSmsEmailMarks}
          />
        </SliderSmsEmailStyle>

        <div className="mb-12" />
        <SliderContentStyle className="flex flex-col w-full">
          <Label>Ripartizione sui contenuti</Label>
          <div className="mb-6" />

          <Slider
            value={sliderContent}
            onChange={(e, newValue) => setSliderContent(newValue)}
            min={0}
            max={1}
            step={0.01}
            marks={sliderContentMarks}
          />
        </SliderContentStyle>
        <div className="mb-12" />
      </div>
    );
  }
  return (
    <div>
      <div className="sticky w-full top-0 bg-white z-10">
        <Container>
          <div className="flex-shrink-0 flex justify-between items-center w-full h-16 bg-white border-b border-gray-200 justify-between items-center">
            <p className="hidden md:block">Stato applicazione</p>

            <ApplicationStatus />
          </div>
        </Container>
      </div>
      <PageHeading title="Pannello di controllo" />
      {component}
      <PageSection title="Gestione inibizioni">
        <InhibitionsHandler />
      </PageSection>
    </div>
  );
};

const SliderSmsEmailStyle = styled.div`
  .MuiSlider-markLabel {
    transform: translate(0);

    &[data-index="0"] {
      transform: translate(0);
    }
    &[data-index="1"] {
      transform: translate(-100%);
    }
  }
`;
const SliderContentStyle = styled.div`
  .MuiSlider-markLabel {
    transform: translate(0);

    &[data-index="0"] {
      transform: translate(0);
    }
    &[data-index="1"] {
      transform: translate(-50%);
    }
    &[data-index="2"] {
      transform: translate(-100%);
    }
  }
`;

export default ControlPanel;
