import React from "react";
import CheckboxGroup from "../ui-components/CheckboxGroup";
import Select from "../ui-components/Select";
import _ from "lodash";

const Filters = ({
  data,
  filterableColumns,
  onChange,
  filters,
  clearFilters,
}) => {
  return (
    <div className="flex flex-col md:w-2/4">
      <div className="flex items-end mb-4">
        <div>
          <b className="text-lg font-extrabold text-gray-900 sm:text-xl sm:leading-none leading-none">
            Filtri
          </b>
        </div>
        <div onClick={clearFilters}>
          <p className="ml-4 cursor-pointer text-xs text-blue-600 hover:text-blue-500">
            PULISCI
          </p>
        </div>
      </div>
      {filterableColumns.map(({ key, title, type, selectType }) => {
        const options = [...new Set(data.map((row) => row[key]))]
          .filter((v) => !!v)
          .map((value) => {
            return {
              value,
              name:
                type === "number"
                  ? value
                  : value.charAt(0).toUpperCase() + value.slice(1),
            };
          });

        if (!options?.length)
          return <></>;

        switch (selectType) {
          case "select":
            return (
              <div className="mb-2" key={key}>
                <Select
                  label={title}
                  options={_.orderBy(options, ['label'], ['asc'])}
                  value={filters.filter((f) => f.key === key)[0].value}
                  onChange={(e) => {
                    onChange({ value: e.target.value, key, title }, selectType);
                  }}
                />
              </div>
            );

          default:
            return (
              <div className="mb-2" key={key}>
                <CheckboxGroup
                  horizontal
                  title={title}
                  options={options}
                  values={filters
                    .filter((f) => f.key === key)
                    .map((f) => f.value)}
                  onChange={(value) => {
                    onChange({ value: value, key, title });
                  }}
                />
              </div>
            );
        }
      })}
    </div>
  );
};

export default Filters;
