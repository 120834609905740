export const isBlockValid = (block) => {
  const {
    val_min_practices_count,
    val_max_practices_count,
    val_min_practices_volume,
    val_max_practices_volume,
    val_practices_count,
    val_practices_volume,
  } = block;
  return (
    (val_min_practices_count >= 0 && val_min_practices_count < val_practices_count) &&
    (val_min_practices_count < val_max_practices_count) &&
    (val_min_practices_volume >= 0 && val_min_practices_volume < val_practices_volume) &&
    (val_min_practices_volume < val_max_practices_volume)
  );
}