import React, { useState } from "react";
import {getMonthsFromDate} from "./utilities";
import moment from "moment";
import _ from "lodash";

// UI
import { PageHeading } from "../../ui-components/Container";
import Select from "../../ui-components/Select";
import ErrorBoundary from "../../ui-components/ErrorBoundary";

import History from "./History";
import Notification from "./Notification";
import PaymentTree from "./PaymentTree";
import ApplicationStatus from "../../components/ApplicationStatus";
import Container from "../../ui-components/Container";
import SolicitsHistory from "./SolicitsHistory";
// import {PaymentOutlooks} from "./PaymentOutlooks";

const lastYear = new Date();
lastYear.setFullYear(lastYear.getFullYear() - 1);

const lastTwoYear = new Date();
lastTwoYear.setFullYear(lastTwoYear.getFullYear() - 2);

const lastTwoWeeks = new Date();
lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 14);

const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

const lastThreeMonth = new Date();
lastThreeMonth.setMonth(lastThreeMonth.getMonth() - 3);

const startOfYear = new Date((new Date()).getFullYear(), 0, 1);

const periods = {
  lastTwoWeeks: {
    name: "Ultime due settimane",
    value: {
      periodName: "ultime due settimane",
      start: lastTwoWeeks,
    },
  },
  lastMonth: {
    name: "Ultimo mese",
    value: {
      periodName: "ultimo mese",
      start: lastMonth,
    },
  },
  lastQuarter: {
    name: "Ultimo trimestre",
    value: {
      periodName: "ultimo trimestre",
      start: lastThreeMonth,
    },
  },
  lastYear: {
    name: "Ultimo anno",
    value: {
      periodName: "ultimo anno",
      start: lastYear,
    },
  },
  lastTwoYears: {
    name: "Ultimi due anni",
    value: {
      periodName: "ultimi due anni",
      start: lastTwoYear,
    },
  },
  ytd: {
    name: "YTD",
    value: {
      periodName: () => getMonthsFromDate(startOfYear),
      start: startOfYear,
    },
  },
  month: {
    name: "Mese",
    value: {
      periodName: (month, year) => `${year}-M${('00'+month).slice(-2)}`,
      start: (month, year) => new Date(year, month-1, 1),
      end: (month, year) => {
        const d = new Date(year, month-1, 1);
        return moment(d).add(1, 'month').startOf('month').toDate();
      },
    },
  },
  quarter: {
    name: "Trimestre",
    value: {
      periodName: (quarter, year) => `${year}-Q${quarter}`,
      start: (quarter, year) => {
        const month = (quarter - 1) * 3;
        return new Date(year, month, 1);
      },
      end: (quarter, year) => {
        const month = (quarter - 1) * 3;
        const d = new Date(year, month, 1);
        return moment(d).add(3, 'month').startOf('month').toDate();
      },
    },
  },
};

const currentYear = new Date().getFullYear();
const yearOptions = (startYear) => Array.from(
  { length: currentYear - startYear + 1 },
  (_, index) => startYear + index
);
const monthOptions = Array.from(
  { length: 12 },
  (_, i) => new Date(0, i).toLocaleString('it-IT', { month: 'long' })
).reduce(
  (acc, month, index) => ({...acc, [index+1]: _.capitalize(month)}),
  {}
);
const quarterOptions = Array(4).fill(null).reduce(
  (acc, _, index) => ({...acc, [index +1]: `Q${index+1}`}),
  {}
)

function Analytics() {

  // state
  const [selectedPeriod, setSelectedPeriod] = useState('lastYear');
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedQuarter, setSelectedQuarter] = useState(1);

  // utilities
  const buildPeriodOption = (key) => {
    if (!(key in periods))
      throw Error(`Invalid period ${key}`);

    const {name} = periods[key];
    return {name, value: key}
  }
  const {start, end, periodName} = periods[selectedPeriod].value;
  const [actualStart, actualEnd, actualPeriodNames] = (() => {
    let args;
    switch (selectedPeriod) {
      case 'ytd':
        return [start, null, periodName()];
      case 'month':
        args = [selectedMonth, selectedYear];
        return [start(...args), end(...args), [periodName(...args)]];
      case 'quarter':
        args = [selectedQuarter, selectedYear];
        return [start(...args), end(...args), [periodName(...args)]];
      default:
        return [start, end, [periodName]]
    }
  })(); // remove this

  return (
    <ErrorBoundary from="analytics" hide>
      <div className="sticky w-full top-0 bg-white z-10">
        <Container>
          <div className="flex-shrink-0 flex justify-between items-center w-full h-16 bg-white border-b border-gray-200 justify-between items-center">
            <p className="hidden md:block">Stato applicazione</p>

            <ApplicationStatus />
          </div>
        </Container>
      </div>
      <div>
        <PageHeading
          title="Analytics"
          contentActions={<div className="flex items-center gap-3">
            <Select
              options={Object.keys(periods).map(buildPeriodOption)}
              value={selectedPeriod}
              onChange={(e) => {setSelectedPeriod(e.target.value)}}
              label="Orizzonte temporale"
            />
            {selectedPeriod === 'month' && (
              <Select
                options={Object.entries(monthOptions).map(([value, name]) => ({name, value}))}
                value={selectedMonth}
                onChange={(e) => {setSelectedMonth(e.target.value)}}
                label="Mese"
              />
            )}
            {selectedPeriod === 'quarter' && (
              <Select
                options={Object.entries(quarterOptions).map(([value, name]) => ({name, value}))}
                value={selectedQuarter}
                onChange={(e) => {setSelectedQuarter(e.target.value)}}
                label="Trimestre"
              />
            )}
            {['month', 'quarter'].includes(selectedPeriod) && (
              <Select
                options={yearOptions(2021).map((y) => ({name: y, value: y}))}
                value={selectedYear}
                onChange={(e) => {setSelectedYear(e.target.value)}}
                label="Anno"
              />
            )}
          </div>}
        />
        <History start={actualStart} end={actualEnd} />
        <SolicitsHistory start={actualStart} end={actualEnd} />
        <Notification start={actualStart} end={actualEnd} />
        <PaymentTree periodNames={actualPeriodNames} />

        {/*/!* NOTE: general period selector (last year has no effect from here *!/*/}
        {/*<hr />*/}
        {/*<PaymentOutlooks />*/}

      </div>
    </ErrorBoundary>
  );
}

export default Analytics;
