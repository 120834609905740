import React, { useRef, useState } from "react";
import CalendarInput from "./CalendarInput";
import Input from "./Input";
import moment from "moment";
import { getDateOfISOWeek } from "../utils";

// Language
import { localeMapper } from "../locale";

// Constants
import { CALENDAR_MODE } from "../utils/constants";
const today = moment().startOf("day").toDate();

// there are three mode options: daily, weekly, monthly.
const DatePicker = ({
  value,
  onChange,
  zIndexVal = 20,
  minDate,
  maxDate,
  onClear = null,
  selectableDates = null,
  mode = CALENDAR_MODE.DAILY,
  showErrorDialog = false,
  materialTable = false,
  ...rest
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const inputRef = useRef(null);

  const dateString = value;

  const valueString = () => {
    if (!value) return "";

    switch (mode) {
      case CALENDAR_MODE.WEEKLY:
        return "Settimana " + value.week + " - " + value.year;
      case CALENDAR_MODE.MONTHLY:
        return (
          value.toLocaleString(
            localeMapper[localStorage.getItem("lang")] ?? "en-EN",
            { month: "long" }
          ) +
          " " +
          value?.getFullYear()
        );
      default:
        return dateString;
    }
  };

  const calendarStartDate = () => {
    if (!value) return today;

    switch (mode) {
      case CALENDAR_MODE.WEEKLY:
        return getDateOfISOWeek(value.week, value.year);
      default:
        return value;
    }
  };

  const setCorrectDates = (dates, onChange) => {
    switch (mode) {
      case CALENDAR_MODE.MONTHLY:
        value &&
        value.getFullYear() === dates.getFullYear() &&
        value.getMonth() === dates.getMonth()
          ? onChange(undefined)
          : onChange(dates);
        break;
      case CALENDAR_MODE.WEEKLY:
        value && value.year === dates.year && value.week === dates.week
          ? onChange(undefined)
          : onChange(dates);
        break;
      default:
        value &&
        value.getFullYear() === dates[0].getFullYear() &&
        value.getMonth() === dates[0].getMonth() &&
        value.getDate() === dates[0].getDate()
          ? onChange(undefined)
          : onChange(dates[0]);
        break;
    }
  };

  const calendarValues = () => {
    switch (mode) {
      case CALENDAR_MODE.WEEKLY:
      case CALENDAR_MODE.MONTHLY:
        return value;
      default:
        return value ? [value] : [];
    }
  };

  return (
    <div
      className="w-full relative"
      onBlur={(e) => {
        if (
          !showCalendar ||
          calendarRef.current?.contains(e.relatedTarget) ||
          inputRef.current?.contains(e.relatedTarget)
        )
          return;
        setShowCalendar(!showCalendar);
      }}
    >
      <div ref={inputRef}>
        <Input
          style={{ cursor: "pointer" }}
          autoComplete="off"
          value={valueString()}
          onChange={() => null}
          onClick={(e) => {
            e.stopPropagation();
            setShowCalendar(!showCalendar);
          }}
          showCalendar={showCalendar}
          showErrorDialog={showErrorDialog}
          materialTable={materialTable}
          onClear={onClear}
          {...rest}
        />
      </div>
      {showCalendar && (
        <div
          style={{
            zIndex: zIndexVal,
            left: materialTable
              ? inputRef.current.clientWidth / 2 - inputRef.current.clientWidth
              : "auto",
          }}
          ref={calendarRef}
          className="absolute min-w-full"
        >
          <CalendarInput
            minDate={minDate}
            maxDate={maxDate}
            selectableDates={selectableDates}
            calendarStartDate={calendarStartDate()}
            showCalendar={showCalendar}
            dates={calendarValues()}
            setDates={(dates) => {
              setShowCalendar(false);
              setCorrectDates(dates, onChange);
            }}
            mode={mode}
            view={mode === CALENDAR_MODE.MONTHLY ? "year" : undefined}
            single
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
