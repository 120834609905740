import React, {useContext} from "react";
import {ConfigureOptimizerContext} from "../State/ConfigureOptimizerContext";
import Input from "../../../ui-components/Input";

const NameDefinition = () => {

  // context
  const context = useContext(ConfigureOptimizerContext);

  return (
    <div className="mx-auto my-20 w-full md:w-3/4 lg:w-1/2">
      <Input
        label="Nome della configurazione"
        value={context.configurationName}
        onChange={(e) => context.setConfigurationName(e.target.value)}
      />
    </div>
  )
}

export default NameDefinition;