import { PageHeading } from "../../../ui-components/Container";
import DatePicker from "../../../ui-components/DatePicker";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import {useEffect, useState, useContext} from "react";
import SliderFilter from "../Components/SliderFilter";
import AdrPerformanceBarchart from "../Components/AdrPerformanceBarchart";
import ToggleSelection from "../Components/ToggleSelection";
import { CALENDAR_MODE } from "../../../utils/constants";
import moment from "moment";
import ApiService from "../../../services/ApiService";
import {NotificationsContext} from "../../../ui-components/Notifications";
import CheckboxGroup from "../../../ui-components/CheckboxGroup";
import Placeholder from "../../../ui-components/Placeholder";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import _ from "lodash";


const SPOOL_STATION_CHOICES = {
  "ADR prima battuta": "Prima battuta",
  "ADR seconda battuta": "Seconda battuta",
};
const CUSTOMER_STATE_CHOICES = {
  "ATTIVO": "Attivi",
  "CESSATO": "Cessati",
};
const ADR_RANKING_PAGE_SIZE = 4;


function ADRCard({ item, index, showPerformance }) {
  return (
    <div className="flex-1 bg-gray-100 rounded-xl py-4">
      <div className="grid grid-cols-6">
        <div className="col-span-1 flex items-center justify-center text-xl font-bold">
          <span className="ml-1.5">{index + 1}°</span>
        </div>
        <div className="col-span-5">
          <div className="text-base font-bold">{item.adr}</div>
          <div className="text-sm flex flex-row">
            <div className="pr-1.5">Recuperato:</div>
            {
              showPerformance
                ? <span className="font-bold">{item.performance.toFixed(2)}%</span>
                : <>
                  <div className="text-sm font-bold pr-1">{item.collected_volume.toFixed(0)}€</div>
                  <div className="px-1">/</div>
                  {item.total_volume.toFixed(0)}€
                </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default function HistoryOptimizerPage() {

  // context
  const {push} = useContext(NotificationsContext);

  // state
  const [volumiPerc, setVolumiPerc] = useState(false);
  // const [volumiMonth, setVolumiMonth] = useState(true);
  const [performanceAdrPerc, setPerformanceAdrPerc] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').subtract(1, 'years').toDate());
  const [to, setTo] = useState(moment().startOf('month').add(1, 'months').toDate());
  const [lotDimension, setLotDimension] = useState([0, 200_000]);
  const [debouncedLotDimension, setDebouncedLotDimension] = useState(undefined);
  const [agingLot, setAgingLot] = useState([0, 40]);
  const [debouncedAgingLot, setDebouncedAgingLot] = useState(undefined);
  const [spoolStations, setSpoolStations] = useState(Object.keys(SPOOL_STATION_CHOICES));
  const [customerStateFilter, setCustomerStateFilter] = useState(Object.keys(CUSTOMER_STATE_CHOICES));
  const [adrPerformances, setAdrPerformances] = useState(null);
  const [adrRankingPageIdx, setAdrRankingPageIdx] = useState(0);

  // utilities
  const adrs = adrPerformances?.reduce((prev, curr) => ({...prev, [curr.id_adr]: curr.adr}), {}) ?? {};
  const adrInfo = adrPerformances
    ?.reduce((prev, curr) => {
      const idx = prev.findIndex((x) => x.adr === curr.adr);
      if (idx < 0)
        return [...prev, curr];
      prev[idx].collected_volume += curr.collected_volume;
      prev[idx].total_volume += curr.total_volume;
      prev[idx].performance = prev[idx].collected_volume / prev[idx].total_volume * 100;
      return prev;
    }, [])
    ?.sort(performanceAdrPerc
      ? ((a, b) => a.performance > b.performance ? -1 : 1)
      : ((a, b) => a.collected_volume > b.collected_volume ? -1 : 1)
    );
  const adrRankingPageStart = adrRankingPageIdx * ADR_RANKING_PAGE_SIZE;
  const adrRankingPage = adrInfo?.slice(adrRankingPageStart, adrRankingPageStart + ADR_RANKING_PAGE_SIZE);
  const showAdrRankingPrev = adrRankingPageIdx > 0;
  const showAdrRankingNext = !!adrInfo && (adrRankingPageIdx + 1) * ADR_RANKING_PAGE_SIZE < adrInfo.length;
  const generatePlotData = () => {
    const quarters = adrPerformances
      ?.reduce((prev, curr) => {
        const key = `Q${curr.quarter} ${curr.year}`;
        if (!(key in prev))
          prev[key] = {};

        prev[key]._year= curr.year;
        prev[key]._quarter= curr.quarter;
        if (volumiPerc) {
          prev[key][curr.id_adr] = curr.performance;
        }
        else {
          prev[key][`${curr.id_adr}-collected`] = curr.collected_volume;
          prev[key][`${curr.id_adr}-uncollected`] = curr.total_volume - curr.collected_volume;
        }
        return prev;
      }, {});

    const result = Object.entries(quarters ?? {})
      .reduce((prev, [year, data]) => [...prev, {year, ...data}], []);

    return _.sortBy(result, ['_year', '_quarter'], ['asc', 'asc'])
  }
  const plotData = generatePlotData();

  useEffect(() => {
    setDebouncedLotDimension(lotDimension);
    setDebouncedAgingLot(agingLot);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedLotDimension(lotDimension);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [lotDimension, 1000]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedAgingLot(agingLot);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [agingLot, 1000]);


  useEffect(() => {

    // wait until both debounced values are set
    if (debouncedLotDimension === undefined || debouncedAgingLot === undefined)
      return;

    setAdrPerformances(null);

    const payload = {
      from_: from.toISOString(),
      to: to.toISOString(),
      spool_station: spoolStations,
      customer_states: customerStateFilter,
      min_volume: debouncedLotDimension[0],
      max_volume: debouncedLotDimension[1],
      min_avg_aging: debouncedAgingLot[0] * 30,
      max_avg_aging: debouncedAgingLot[1] * 30,
    };
    ApiService.postApiJson('optimization/adr/performance', payload)
      .then(setAdrPerformances)
      .catch((err) => {
        push({type: 'error', title: 'Si è verificato un errore'})
        throw err;
      })
      .finally(() => { setAdrRankingPageIdx(0); })

  }, [from, to, spoolStations, customerStateFilter, debouncedLotDimension, debouncedAgingLot]);

  const noDataPlaceholder = <div className="w-full h-96 flex items-center justify-center text-base text-gray-400 bg-gray-50 rounded-md">
    Nessun dato disponibile
  </div>

  return (
    <div>
      <PageHeading title="Analisi storico recupero" />
      <div className="flex flex-row mt-8 mb-14">
        <div className="flex-1 w-64 mr-4 px-8 bg-gray-100 rounded-md">
          <div className="pt-4 pb-6">
            <div className="py-2 text-black text-xl font-semibold">Filtri</div>
            <div className="grid grid-cols-6 gap-4 py-2">
              <div className="col-span-1 flex justify-start items-center">
                Periodo:
              </div>
              <div className="col-span-5 ">
                <div className="flex flex-row items-center">
                  <div className="flex pr-2 w-64">
                    <DatePicker
                      value={from}
                      minDate={null}
                      onChange={setFrom}
                      showErrorDialog={false}
                      materialTable={false}
                      mode={CALENDAR_MODE.MONTHLY}
                    />
                  </div>
                  <ArrowRightAlt />
                  <div className="flex pl-2 w-64">
                    <DatePicker
                      value={to}
                      minDate={null}
                      onChange={setTo}
                      showErrorDialog={false}
                      materialTable={false}
                      mode={CALENDAR_MODE.MONTHLY}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 py-2">
              <div className="col-span-1 flex justify-start items-center">
                Stazione di spool:
              </div>
              <div className="col-span-5">
                <div className="flex flex-row py-1 items-center">
                  <CheckboxGroup
                    horizontal
                    options={Object.entries(SPOOL_STATION_CHOICES).map(([k, v]) => ({label: v, value: k}))}
                    values={spoolStations}
                    onChange={(value) => {
                      if (spoolStations.indexOf(value) >= 0) {
                        setSpoolStations([...spoolStations.filter((v) => v !== value)]);
                      } else {
                        setSpoolStations([...spoolStations, value]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 py-2">
              <div className="col-span-1 flex justify-start items-center">
                Stato soggetto:
              </div>
              <div className="col-span-5">
                <div className="flex flex-row py-1 items-center">
                  <CheckboxGroup
                    horizontal
                    options={Object.entries(CUSTOMER_STATE_CHOICES).map(([k, v]) => ({label: v, value: k}))}
                    values={customerStateFilter}
                    onChange={(value) => {
                      if (customerStateFilter.indexOf(value) >= 0) {
                        setCustomerStateFilter([...customerStateFilter.filter((v) => v !== value)]);
                      } else {
                        setCustomerStateFilter([...customerStateFilter, value]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 py-2">
              <div className="col-span-1 flex justify-start items-end pb-1">
                Dimensione lotti:
              </div>
              <div className="col-span-5">
                <SliderFilter
                  description={""}
                  symbol={"€"}
                  min={0}
                  max={1e6}
                  step={100}
                  handleFunction={setLotDimension}
                  valueContent={lotDimension}
                />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 py-2">
              <div className="col-span-1 flex justify-start items-end pb-1">
                Aging medio (mesi):
              </div>
              <div className="col-span-5">
                <SliderFilter
                  description={""}
                  symbol={"m"}
                  min={0}
                  max={60}
                  step={1}
                  // handleFunction={context.handleValueScope}
                  handleFunction={setAgingLot}
                  valueContent={agingLot}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2 mb-8 mr-4">
        <div className="col-span-1 pr-6">
          <div className="flex flex-row justify-between items-center mb-5">
            Performance ADR
            <div className="flex flex-row items-center">
              <div className="pr-4 flex items-center">€</div>
              <ToggleSelection
                checked={performanceAdrPerc}
                onChange={setPerformanceAdrPerc}
              />
              <div className="pl-4 flex items-center">%</div>
            </div>
          </div>
          {
            !adrInfo
              ? <div className="flex flex-col gap-1">
                {Array(ADR_RANKING_PAGE_SIZE).fill(null).map((_, idx) =>
                  <Placeholder key={idx} height="h-18" classNames="rounded-md" />
                )}
              </div>
              : (
                adrInfo.length > 0
                  ? <>
                    {adrRankingPage.map((item, index) => (
                      <div key={index} className="my-2">
                        <ADRCard
                          item={item}
                          index={index + adrRankingPageIdx * ADR_RANKING_PAGE_SIZE}
                          showPerformance={!!performanceAdrPerc}
                        />
                      </div>
                    ))}
                    {(showAdrRankingPrev || showAdrRankingNext) &&
                    <div className="flex items-center justify-between mt-3">
                      <ChevronLeftIcon
                        className={`w-4 cursor-pointer ${!showAdrRankingPrev ? 'opacity-0 pointer-events-none' : ''}`}
                        onClick={() => { setAdrRankingPageIdx((prev) => prev - 1); }}
                      />
                      <span className="text-xs">
                        Pagina {adrRankingPageIdx+1} di {Math.ceil(adrInfo.length / ADR_RANKING_PAGE_SIZE)}
                      </span>
                      <ChevronRightIcon
                        className={`w-4 cursor-pointer  ${!showAdrRankingNext ? 'opacity-0 pointer-events-none' : ''}`}
                        onClick={() => { setAdrRankingPageIdx((prev) => prev + 1); }}
                      />
                    </div>}
                  </>
                  : noDataPlaceholder
              )
          }
        </div>
        <div className="col-span-2 pl-6">
          <div className="flex flex-row justify-between items-center mb-4">
            Volumi assegnati
            <div className="flex flex-row">
              {/*<div className="pr-2 flex items-center">1D</div>*/}
              {/*<ToggleSelection*/}
              {/*  checked={volumiMonth}*/}
              {/*  onChange={setVolumiMonth}*/}
              {/*/>*/}
              {/*<div className="pl-2 pr-4 flex items-center">3M</div>*/}
              <div className="px-2 flex items-center">€</div>
              <ToggleSelection
                checked={volumiPerc}
                onChange={setVolumiPerc}
              />
              <div className="pl-2 flex items-center">%</div>
            </div>
          </div>

          {!adrInfo
            ? <Placeholder height="h-96" classNames="rounded-md" />
            : (
              plotData.length > 0
                ? <AdrPerformanceBarchart
                  height="24rem"
                  adrs={adrs}
                  data={plotData}
                  showPercentages={volumiPerc}
                />
                : noDataPlaceholder
          )}
        </div>
      </div>
    </div>
  );
}
