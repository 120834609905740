import { useState } from "react";
import CheckboxGroup from "../../../ui-components/CheckboxGroup";

function CheckBoxFilter({ description, options, currentValues, handleChange }) {
  const [state, setState] = useState(currentValues.map((item) => item.label));
  return (
    <div className="grid grid-flow-row-dense grid-cols-6 mt-4 mr-20">
      <div className="col-span-1 justify-start flex items-center text-black font-semibold">
        {description}
      </div>
      <div className="col-span-4 ml-10">
        <CheckboxGroup
          horizontal
          options={options}
          values={state}
          onChange={(value) => {
            if (state.indexOf(value) >= 0) {
              setState([...state.filter((v) => v !== value)]);
              handleChange([...state.filter((v) => v !== value)]);
            } else {
              setState([...state, value]);
              handleChange([...state, value]);
            }
          }}
        />
      </div>
    </div>
  );
}

export default CheckBoxFilter;
