import { useEffect } from "react";
import ApiService from "../../../../services/ApiService";
import { useSelector, useDispatch } from "react-redux";
import { setConfigurationData } from "../../../../store/configurationSlice";

function useListOptimizerData(active_only=false) {
  const dispatch = useDispatch();
  const refreshConfigurationData = useSelector(
    (state) => state.configuration.refreshConfigurationData
  );

  useEffect(() => {
    ApiService.getApi("optimization/configuration", active_only ? "?active_only=true" : undefined)
      .then((result) => result.map((configuration) => ({
        ...configuration,
        configuration_name: configuration.des_name,
        author: configuration.author.full_name,
        attivo: configuration.flg_primary,
        date: configuration.tms_created_on,
      })))
      .then((result) => {
        dispatch(setConfigurationData(result));
      })
  }, [refreshConfigurationData]);
}

export default useListOptimizerData;
