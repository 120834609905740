import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  getTooltip,
  getXYChart,
  addCursor,
  getRenderAxisX,
  getRenderAxisY,
  addSeriesBullet,
} from "./commons";
import {
  defaultFilters,
  getFilteredData,
  openFiltreableColumns,
} from "./utilities";
import Filters from "../Filters";
import FiltersPreview from "../FiltersPreview";
import AlertAnalyticsEmptyData from "../AlertAnalyticsEmptyData";

function OpenCreditLineChart({ id, data }) {
  const [filters, setFilters] = useState(defaultFilters);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(getFilteredData(data, filters));
  }, [filters, data]);

  const clearFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useLayoutEffect(() => {
    if (!data?.length)
      return;

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    root.dateFormatter.set("dateFormat", "dd/MM/yyyy");

    // Create chart
    let chart = getXYChart(root, {});
    addCursor(root, chart);

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: getRenderAxisX(root, false),
      })
    );
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMin: 0.1,
        extraMax: 0.1,
        numberFormat: "#.0 a €",
        renderer: getRenderAxisY(root),
      })
    );

    // Add series
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "val_to_pay",
        valueXField: "dat_history",
        tooltip: getTooltip(
          root,
          "[bold]{dat_history.formatDate()}:[/] {val_to_pay.formatNumber('#.0 a €')}"
        ),
      })
    );

    // Add bullet
    addSeriesBullet(root, series);

    // Add data
    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ["val_aging"],
      dateFields: ["dat_history"],
      dateFormat: "yyyy-MM-dd",
    });
    series.data.setAll(filteredData);

    // Make stuff animate on load
    series.appear(1000);

    return () => {
      root.dispose();
    };
  }, [filteredData, id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data?.length)
    return <AlertAnalyticsEmptyData />;

  return (
    <div className="flex w-full flex-col">
      <div
        className="mb-6"
        id={id}
        style={{ minWidth: "500px", minHeight: "350px" }}
      />
      <div
        className={`flex flex-col-reverse md:flex-row w-full justify-between mt-6`}
      >
        <Filters
          clearFilters={clearFilters}
          filterableColumns={openFiltreableColumns}
          filters={filters}
          data={data}
          onChange={(filterValue) => {
            // check if value is already in filter
            const remove = filters.find((f) => {
              return f.value === filterValue.value && f.key === filterValue.key;
            });

            if (remove) {
              setFilters([
                ...filters.filter((f) => {
                  return !(
                    f.value === filterValue.value && f.key === filterValue.key
                  );
                }),
              ]);
              return;
            }

            // push value in filter
            setFilters([...filters, filterValue]);
          }}
        />
        <FiltersPreview
          filters={filters}
          filterableColumns={openFiltreableColumns}
        />
      </div>
    </div>
  );
}

export default React.memo(OpenCreditLineChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data);
});
