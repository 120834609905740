import React, { useEffect, useState } from "react";
import { PageSection } from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";
import { getKpi } from "./kpi";
import Grid from "@material-ui/core/Grid";
import PaymentTreeChart from "../../components/Charts/PaymentTreeChart";
import ApiService from "../../services/ApiService";
import AlertAnalyticsEmptyData from "../../components/AlertAnalyticsEmptyData";


const fetchPaymentTree = (periodNames, setData) => {
  setData(null);
  ApiService.getApi("analytics/payment-tree", `?periods=${periodNames.join(',')}`)
    .then((res) => {
      setData(res);
    })
    .catch(() => {
      console.error("Error when fetching notification");
    });
};

const PaymentTree = ({ periodNames }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (periodNames?.length)
      fetchPaymentTree(periodNames, setData);
  }, [periodNames]);

  return data ? (
    <PageSection title="Ripartizione tra i contenuti inviati">
      {
        data.tree.children.length > 0
          ? (
            <Grid container>
              <Grid item style={{ minWidth: "80%" }}>
                {data["tree"]["children"][0] && (
                  <PaymentTreeChart
                    id="payment-tree-chart-1"
                    data={data["tree"]["children"][0]}
                  />
                )}
                {data["tree"]["children"][1] && (
                  <PaymentTreeChart
                    id="payment-tree-chart-2"
                    data={data["tree"]["children"][1]}
                  />
                )}
              </Grid>
              <Grid item>
                <div className="flex-col flex">
                  {getKpi(
                    "Totale Invii",
                    data["kpi"]["invii"].toLocaleString(),
                    data["kpi"]["importo"].toLocaleString()
                  )}
                  {getKpi(
                    "Invii recapitati",
                    data["kpi"]["invii_ok"].toLocaleString(),
                    data["kpi"]["importo_ok"].toLocaleString(),
                    data["kpi"]["perc_invii_ok"].toLocaleString(),
                    data["kpi"]["perc_importo_ok"].toLocaleString()
                  )}
                  {getKpi(
                    "Invii pagati",
                    data["kpi"]["invii_pagato"].toLocaleString(),
                    data["kpi"]["importo_pagato"].toLocaleString(),
                    data["kpi"]["perc_invii_pagato"].toLocaleString(),
                    data["kpi"]["perc_importo_pagato"].toLocaleString()
                  )}
                </div>
              </Grid>
            </Grid>
          )
          : <AlertAnalyticsEmptyData />
      }
    </PageSection>
  ) : (
    <div className="flex flex-col w-full mb-4">
      <Placeholder height="h-40" classNames="mb-4 rounded-lg" />
    </div>
  );
};

export default PaymentTree;
