import React from "react";
import moment from "moment";
import formatCurrency from "../../../utils/formatCurrency";

export const COLUMNS = [
  {
    title: "Configurazione",
    field: "name",
    type: "string",
  },
  {
    title: "Inizio",
    field: "date",
    type: "date",
    defaultSort: "desc",
    render: (rowData) => <>{moment.utc(rowData["date"]).local().format("DD/MM/YYYY HH:mm:ss")}</>,
  },
  {
    title: "Volume",
    field: "volume",
    type: "numeric",
    render: ({volume}) => formatCurrency(volume),
  },
  {
    title: "Autore",
    field: "author",
    type: "string",
  },
  {
    title: "Caricamento Norma",
    field: "norma_status",
    type: "string",
  },
  {
    title: "Stato Run",
    field: "run_status",
    type: "string",
  },
];

export const OPTIONS = {
  actionsColumnIndex: 0,
  filtering: true,
  showTitle: false,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 20,
  },
  filterCellStyle: { paddingTop: 8, maxWidth: 200 },
  cellStyle: { border: "none" },
  actions: {
    border: "none",
  },
  actionsCellStyle: { border: "none" },
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
};
