import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import menu from "./menu";
import UserBox from "./UserBox";
import Logo from "@components/Logo";
import MenuLink from "./MenuLink";
import MenuDropdown from "./MenuDropdown";
import filterRoutesByScopes from "../../utils/filterRoutesByScopes";

function AppNavigation({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const menuLink = filterRoutesByScopes(menu).map((item) => {
    if (item.dropdown) {
      return <MenuDropdown {...item} key={item.name} />;
    }

    return <MenuLink {...item} key={item.name} />;
  });

  const containerStyle = {height: !['local', 'dev'].includes(process.env.REACT_APP_ENV) ? '100%' : 'calc(100% - 35px)'};
  return (
    <>
      <div className="h-full flex" style={containerStyle}>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-100 focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <Logo />
                  <nav aria-label="Sidebar" className="mt-3">
                    <UserBox />
                    <div className="px-2 space-y-1">{menuLink}</div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-100">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <Logo />
                <nav className="mt-3 flex-1" aria-label="Sidebar">
                  <UserBox />
                  <div className="px-2 space-y-1">{menuLink}</div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between bg-gray-100 border-b border-gray-200 py-1.5">
              <div>
                <Logo />
              </div>
              <div className="px-4">
                <button
                  type="button"
                  className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 relative z-0 flex overflow-hidden max-w-full">
            <div className="flex flex-col flex-1 max-w-full">
              <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last max-w-full">
                {/* <div className="sticky w-full top-0 bg-white z-10">
                  <Container>
                    <div className="flex-shrink-0 flex justify-between items-center w-full h-16 bg-white border-b border-gray-200 justify-between items-center">
                      <p className="hidden md:block">Stato applicazione</p>

                      <ApplicationStatus />
                    </div>
                  </Container>
                </div> */}
                {children}
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppNavigation;
