import React from "react";

const Container = ({ children }) => {
  return (
    <div className="container mx-auto px-6 max-w-6xl md:px-8">{children}</div>
  );
};

const PageHeading = ({ title, contentActions }) => {
  return (
    <div className="pt-8 pb-10 md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h1 className="text-3xl font-extrabold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {title}
        </h1>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">{contentActions}</div>
    </div>
  );
};

const PageSection = ({ title, description, children, containerCls="mb-12" }) => (
  <div className={containerCls}>
    {title && (
      <>
        <div className="mb-1">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">{description}</p>
          </div>
        </div>
        <div className="mb-5">
          <div className="w-full border-t border-gray-300" />
        </div>
      </>
    )}
    {children}
  </div>
);

const Section = ({ title, description, children }) => (
  <div className="mb-12">
    {title && (
      <>
        <div className="mb-1">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-am-800">
              {title}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">{description}</p>
          </div>
        </div>
        <div className="mb-5">
          <div className="w-full border-t border-gray-300" />
        </div>
      </>
    )}
    {children}
  </div>
);

export default Container;
export { Container, PageHeading, PageSection, Section };
