import React from "react";
import {BeakerIcon} from "@heroicons/react/outline";

const EnvironmentBanner = () => {
  if (!['local', 'dev'].includes(process.env.REACT_APP_ENV))
    return null;

  return (
    <div
      className="flex items-center justify-center gap-1.5 w-full bg-indigo-500 text-white p-2 border-b-2 border-indigo-400 sticky top-0"
      style={{textShadow: "1px 1px 0.5px #00000044"}}
    >
      <BeakerIcon className="w-3" />
      <span className="text-xs font-bold">DEVELOPMENT</span>
    </div>
  );
}

export default EnvironmentBanner;