import { useState, useContext, useEffect } from "react";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";
import ApiService from "../../../services/ApiService";
import ADRTable from "../Components/ADRTable";
import formatCurrency from "../../../utils/formatCurrency";
function ADRSelection() {
  const [blockNumber, setBlockNumber] = useState(0);
  const [minVolume, setMinVolume] = useState(0);
  const [maxVolume, setMaxVolume] = useState(0);
  const context = useContext(ConfigureOptimizerContext);

  const fetchData = (setData) => {
    ApiService.getApi("optimization/adr")
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        console.error("Error when fetching data");
      });
  };

  const originalVolume = context.volumeDataList[0];
  const partialVolume = context.blockSelected.reduce(
    (acc, {val_limit, val_practices_volume}) => acc + (
      val_limit !== undefined ? parseFloat(val_limit) : val_practices_volume
    ),
    0
  );
  const hasPartialVolume = partialVolume < originalVolume;

  useEffect(() => {
    fetchData(context.setADRList);
    const volumes = context.blockSelected.map(
      ({val_limit: limited, val_practices_volume: original}) => limited !== undefined
        ? parseFloat(limited)
        : original
    );
    setMaxVolume(Math.max(...volumes));
    setMinVolume(Math.min(...volumes));
    setBlockNumber(volumes.length);
  }, []);

  return (
    <>
      <div className="max-w-7xl mx-auto pt-10">
        <ADRTable />
      </div>
      <div className="mx-auto my-10 max-w-7xl sm:px-6 lg:px-8 bg-gray-200 rounded-md">
        <div className="pt-4 pb-6">
          <div className="py-2 text-black text-xl font-semibold">
            Riepilogo configurazione
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2">
              <div className="flex flex-row py-2">
                Volume (perimetro):
                <div className="text-black font-semibold pl-2 pr-1.5">
                  {" "}
                  {formatCurrency(hasPartialVolume ? partialVolume : originalVolume)}
                </div>
                / {context.volumeDataList[1]?.toLocaleString("de-DE")} €
              </div>
              <div className="flex flex-row py-2">
                Numero pratiche (perimetro):
                <div className="text-black font-semibold pl-2 pr-1.5">
                  {" "}
                  {context.numberPracticesList[0]?.toLocaleString("de-DE")}
                </div>
                / {context.numberPracticesList[1]?.toLocaleString("de-DE")}
              </div>
              <div className="flex flex-row py-2">
                Numero blocchi:{" "}
                <div className="text-black font-semibold px-2">
                  {" "}
                  {blockNumber}{" "}
                </div>
              </div>
              <div />
            </div>
            <div className="col-span-2">
              <div className="flex flex-row py-2">
                Volume minimo blocchi:
                <div className="text-black font-semibold px-2">
                  {" "}
                  {formatCurrency(minVolume)}
                </div>
              </div>
              <div className="flex flex-row py-2">
                Volume massimo blocchi:
                <div className="text-black font-semibold px-2">
                  {" "}
                  {formatCurrency(maxVolume)}
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ADRSelection;
