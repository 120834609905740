import React, { Fragment, useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import Button from "../../ui-components/Button";
import Placeholder from "../../ui-components/Placeholder";

const Summary = ({ title, rows, buttons }) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <section
      aria-labelledby="summary-heading"
      className="shadow bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
    >
      {title ? (
        <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
          {title}
        </h2>
      ) : null}

      <dl className="mt-6 space-y-4">
        {rows?.length ? (
          rows.map((row) => (
            <div
              key={row.title}
              className="border-t border-gray-200 pt-4 flex items-center justify-between"
            >
              <dt className="flex items-center text-sm text-gray-600">
                <span>{row.title}</span>
                {row.tooltip ? (
                  <Fragment>
                    <div className="has-tooltip ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">{row.tooltip}</span>
                      <QuestionMarkCircleIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                      <div className="tooltip z-10 inline-block bg-gray-900 text-xs text-center shadow-sm text-white py-2 px-3 rounded-lg opacity-0 duration-300 transition-opacity invisible dark:bg-am-700">
                        {row.tooltip}
                      </div>
                    </div>
                  </Fragment>
                ) : null}
              </dt>
              <dd className="text-sm font-medium text-gray-900">{row.value}</dd>
            </div>
          ))
        ) : (
          <Fragment>
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </Fragment>
        )}
      </dl>

      <div className="flex w-full md:justify-end">
        {buttons?.length && rows?.length ? (
          <div className="mt-12 flex flex-col w-full items-end md:max-w-xs">
            {buttons.map((button) => (
              <Fragment key={button.text}>
                <Button
                  full
                  submitting={submitting === button.title}
                  disabled={submitting}
                  {...button}
                  onClick={
                    button.async
                      ? async () => {
                          try {
                            setSubmitting(button.title);
                            await button.onClick();
                          } catch (error) {
                          } finally {
                            setSubmitting(false);
                          }
                        }
                      : button.onClick
                  }
                >
                  {button.text}
                </Button>
                <div className="mb-2" />
              </Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Summary;
