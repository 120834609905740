import React, { useEffect, useState } from "react";

// UI
import ApiService from "../../services/ApiService";
import UnreachableUsersTable from "../../components/Tables/UnreachableUsersTable";
import { getKpi } from "./kpi";
import Grid from "@material-ui/core/Grid";
import ReasonBarChart from "../../components/Charts/ReasonBarChart";
import { PageSection } from "../../ui-components/Container";
import ApplicationStatus from "../../components/ApplicationStatus";
import Container from "../../ui-components/Container";

function UnreachableUsers() {
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data]);

  const fetchData = () => {
    ApiService.getApi("unreachable-users")
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        console.error("Error when fetching data");
      });
  };

  return (
    <div>
      <div className="sticky w-full top-0 bg-white z-10">
        <Container>
          <div className="flex-shrink-0 flex justify-between items-center w-full h-16 bg-white border-b border-gray-200 justify-between items-center">
            <p className="hidden md:block">Stato applicazione</p>

            <ApplicationStatus />
          </div>
        </Container>
      </div>
      <UnreachableUsersTable data={data && data["detail"]} />
      {data && data["kpi"] && (
        <Grid container spacing={5}>
          <Grid item>
            {getKpi("Totale Utenze", data["kpi"]["total"])}
            {getKpi(
              "Con Credito Aperto",
              data["kpi"]["credito_aperto"],
              data["kpi"]["credito_aperto_perc"]
            )}
            {getKpi(
              "Recapito mancante",
              data["kpi"]["nessun_recapito"],
              data["kpi"]["nessun_recapito_perc"]
            )}
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <PageSection title="Altre ragioni">
              <ReasonBarChart
                id="reason-bar-chart"
                data={data["kpi"]["altre_ragioni"]}
              />
            </PageSection>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default UnreachableUsers;
