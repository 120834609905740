import CustomSlider from "../../../components/Slider/Slider";
import { useEffect, useState } from "react";
import { MultipleCustomSlider } from "../../../components/Slider/Slider";

export default function SliderFilter({
  description,
  symbol,
  min,
  max,
  step,
  marks,
  handleFunction,
  valueContent,
}) {
  return (
    <div className="grid grid-flow-row-dense grid-cols-6 mt-8 mr-20">
      <div className="col-span-1 justify-start flex items-center text-black font-semibold">
        {description}
      </div>
      <div className="col-span-1 justify-center flex items-center">
        {min <= 0.01 ? 0 : min} {symbol}
      </div>
      <div className="col-span-3">
        <CustomSlider
          sliderContent={valueContent}
          setSliderContent={handleFunction}
          min={min}
          max={max}
          step={step}
          marks={marks}
        />
      </div>
      <div className="col-span-1 justify-center flex items-center">
        {max} {symbol}
      </div>
    </div>
  );
}

export function MultipleSliderFilter({
  description,
  symbol,
  min,
  max,
  step,
  numberOfSlider,
  marks,
  setSplit,
}) {
  const [sliderContent, setSliderContent] = useState(
    Array.from({ length: numberOfSlider }, () =>
      Math.floor(Math.random() * (max - min + 1) + min)
    )
  );
  function calculateSplitSelected() {
    const sortedArray = sliderContent.sort(function (a, b) {
      return a - b;
    });
    let tmpList = [];
    if (numberOfSlider === 0) {
      tmpList.push([min, max]);
    } else {
      for (let [index, val] of sortedArray.entries()) {
        if (index === 0) {
          tmpList.push([min, val]);
        } else {
          tmpList.push([sortedArray[index - 1], val]);
        }
      }
      tmpList.push([sortedArray.slice(-1)[0], max]);
    }
    setSplit(tmpList);
  }

  useEffect(() => {
    setSliderContent(
      Array.from({ length: numberOfSlider }, () =>
        Math.floor(Math.random() * (max - min + 1) + min)
      )
    );
  }, [numberOfSlider]);

  useEffect(() => {
    calculateSplitSelected();
  }, [sliderContent]);

  return (
    <div className="grid grid-flow-row-dense grid-cols-6 mt-8 mr-20 w-full">
      <div className="col-span-1 justify-start flex items-center text-black font-semibold">
        {description}
      </div>
      <div className="col-span-1 justify-center flex items-center">
        {min <= 0.01 ? 0 : min} {symbol}
      </div>
      <div className="col-span-3">
        <MultipleCustomSlider
          sliderContent={sliderContent}
          setSliderContent={setSliderContent}
          min={min}
          max={max}
          step={step}
          marks={marks}
        />
      </div>
      <div className="col-span-1 justify-center flex items-center">
        {max} {symbol}
      </div>
    </div>
  );
}
