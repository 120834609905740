import { useContext } from "react";
import SliderFilter from "../Components/SliderFilter";
import SearchSelect from "../../../ui-components/SearchSelect";
import CheckBoxFilter from "../Components/CheckBoxFilter";
import Placeholder from "../../../ui-components/Placeholder";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";
import Cached from "@material-ui/icons/Cached";
import ApiService from "../../../services/ApiService";

const yearsTicks = 25;
const yearMarks = Array(yearsTicks).fill(null)
  .map((_, idx) => {
    if (idx > 5 && idx % 5 > 0 && idx !== yearsTicks - 1)
      return null;
    return {
      value: idx * 12,
      label: `${idx}y`,
    }
  })
  .filter((t) => t !== null);

function ScopeDefinition() {
  const context = useContext(ConfigureOptimizerContext);
  function fetchData() {
    const obj = {
      volume: context.valueScopeDefault, //
      customer_age: context.ageScopeDefault, //
      aging: context.agingScopeDefault.map((value) => value * 30), //
      regions: context.stateScopeSelected.map((obj) => obj.value), //
      customer_categories: context.customerTypeScopeSelected.map(
        (obj) => obj.value
      ), //
      customer_states: context.customerStateScopeSelected.map((obj) => obj.value), //
      contract_states: context.contractStateScopeSelected.map((obj) => obj.value), //
      customer_types: context.utilityScopeSelected.map((obj) => obj.value), //
      portals: context.portalScopeSelected.map((obj) => obj.value), //
      commodity: context.commodityScopeSelected.map((obj) => obj.value), //
      spool_station: context.spoolStationScopeSelected.map((obj) => obj.value), //
    };

    context.setVolumeDataList([null, null]);
    context.setNumberPracticesList([null, null]);
    ApiService.postApiJson("optimization/practice/perimeter-summary", obj)
      .then((res) => {
        context.setVolumeDataList([res.filtered_value, res.total_value]);
        context.setNumberPracticesList([res.filtered_count, res.total_count]);
      })
      .catch((e) => {
        console.error("Error when fetching data", e);
      });

    context.setScopeDataAligned();
  }

  const getPerimeterValue = (v, um=null) => !!v || v === 0
    ? v.toLocaleString("de-DE") + (!!um ? ` ${um}` : "")
    : <Placeholder width="w-16" height="h-3" classNames="bg-gray-400 mt-2" />;
  const getPerimeterValueRange = ([a, b], um=null) => (
    <div className="flex items-center gap-2 ml-2 -mt-1">
      <span className="font-bold">{getPerimeterValue(a, um)}</span>
      <span>/</span>
      <span>{getPerimeterValue(b, um)}</span>
    </div>
  );

  return (
    <>
      <SliderFilter
        description={"Valore"}
        symbol={"€"}
        min={26}
        max={20000}
        step={50}
        handleFunction={context.handleValueScope}
        valueContent={context.valueScopeDefault}
      />
      <SliderFilter
        description={"Età"}
        symbol={"y"}
        min={18}
        max={124}
        step={1}
        handleFunction={context.handleAgeScope}
        valueContent={context.ageScopeDefault}
      />
      <SliderFilter
        description={"Aging"}
        symbol={"m"}
        min={0}
        max={288}
        step={1}
        marks={yearMarks}
        handleFunction={context.handleAgingScope}
        valueContent={context.agingScopeDefault}
      />
      <div className="grid grid-flow-row-dense grid-cols-6 mt-8 mr-20">
        <div className="col-span-1 justify-start flex items-center text-black font-semibold">
          Regioni
        </div>
        <div className="col-span-4 ml-10">
          <SearchSelect
            placeholder={"Seleziona le regioni"}
            disabled
            options={context.stateFilterOptions}
            isMulti
            value={context.stateScopeSelected}
            onChange={(e) => {
              context.handleStateScope(e);
            }}
          />
        </div>
      </div>
      <CheckBoxFilter
        description={"Utenze"}
        options={context.utilityFilterOptions}
        currentValues={context.utilityScopeSelected}
        handleChange={context.handleUtilityScope}
      />
      <CheckBoxFilter
        description={"Stato soggetto"}
        options={context.customerStateFilterOptions}
        currentValues={context.customerStateScopeSelected}
        handleChange={context.handleCustomerStateScope}
      />
      <CheckBoxFilter
        description={"Stato contratto"}
        options={context.contractStateFilterOptions}
        currentValues={context.contractStateScopeSelected}
        handleChange={context.handleContractStateScope}
      />
      <CheckBoxFilter
        description={"Tipologia di cliente"}
        options={context.customerTypeFilterOptions}
        currentValues={context.customerTypeScopeSelected}
        handleChange={context.handleCustomerTypeScope}
      />
      <CheckBoxFilter
        description={"Portale"}
        options={context.portalFilterOptions}
        currentValues={context.portalScopeSelected}
        handleChange={context.handlePortalScope}
      />
      <CheckBoxFilter
        description={"Commodity"}
        options={context.commodityFilterOptions}
        currentValues={context.commodityScopeSelected}
        handleChange={context.handleCommodityScope}
      />
      <CheckBoxFilter
        description={"Stazione di spool"}
        options={context.spoolStationFilterOptions}
        currentValues={context.spoolStationScopeSelected}
        handleChange={context.handleSpoolStationScope}
      />
      <div className="mx-auto my-10 max-w-7xl sm:px-6 lg:px-8 bg-gray-200 rounded-md">
        <div className="pt-4 pb-6">
          <div className="flex flex-row justify-between">
            <div className="py-2 text-black text-xl font-semibold">
              Perimetro globale
            </div>
            {context.isScopeDisaligned ? (
              <div className="flex flex-row justify-center items-center">
                <div className="pr-4 cursor-pointer" onClick={fetchData}>
                  <Cached />
                </div>
                <div className="p-4 bg-yellow-100 rounded-xl">
                  Dati di perimetro non aggiornati
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-row py-1">
            Volume: {getPerimeterValueRange(context.volumeDataList, "€")}
          </div>
          <div className="flex flex-row py-1">
            Numero pratiche: {getPerimeterValueRange(context.numberPracticesList)}
          </div>
        </div>
      </div>
    </>
  );
}

export default ScopeDefinition;
