import formatCurrency from "../../../utils/formatCurrency";
import Tooltip from "../../../ui-components/Tooltip";
import {TextField} from "@material-ui/core";

const renderRange = (values, formatter) => {
  if (!values)
    return '-';
  const [v0, v1] = values;
  return (
    <span className="whitespace-nowrap">
      {formatter(v0)} <>&rarr;</> {formatter(v1)}
    </span>
  );
}
const renderGroup = (list) => list.length <= 1
  ? list[0] || "Tutti"
  : (
    <ul className="text-xs list-disc list-inside">
      {list.map((value) => <li>{value}</li>)}
    </ul>
  )

export const COLUMNS = [
  {
    title: "Volume",
    field: "rng_volume",
    type: "string",
    editable: "never",
    render: ({rng_volume: list}) => renderRange(list, formatCurrency),
  },
  {
    title: "Età",
    field: "rng_eta",
    type: "string",
    editable: "never",
    render: ({rng_eta: list}) => renderRange(list, (x) => x),
  },
  {
    title: "Aging",
    field: "rng_aging",
    type: "string",
    editable: "never",
    render: ({rng_aging: list}) => renderRange(list, (x) => `${x}g`),
  },
  {
    title: "Regioni",
    field: "grp_regioni",
    type: "string",
    editable: "never",
    render: ({grp_regioni: list}) => renderGroup(list),
  },
  {
    title: "Utenze",
    field: "grp_utenze",
    type: "string",
    editable: "never",
    render: ({grp_utenze: list}) => renderGroup(list),
  },
  {
    title: "Stato soggetto",
    field: "grp_stati_soggetto",
    type: "string",
    editable: "never",
    render: ({grp_stati_soggetto: list}) => renderGroup(list),
  },
  {
    title: "Stato contratto",
    field: "grp_stati_contratto",
    type: "string",
    editable: "never",
    render: ({grp_stati_contratto: list}) => renderGroup(list),
  },
  {
    title: "Tipo cliente",
    field: "grp_tipologie_cliente",
    type: "string",
    editable: "never",
    render: ({grp_tipologie_cliente: list}) => renderGroup(list),
    headerStyle: {minWidth: 200},
  },
  {
    title: "Portali",
    field: "grp_portali",
    type: "string",
    editable: "never",
    render: ({grp_portali: list}) => renderGroup(list),
  },
  {
    title: "Stazione di spool",
    field: "grp_stazione_spool",
    type: "string",
    editable: "never",
    render: ({grp_stazione_spool: list}) => renderGroup(list),
  },
  {
    title: "Numero pratiche ad oggi",
    field: "val_practices_count",
    type: "numeric",
    editable: "never",
    render: ({val_practices_count}) => <span className="font-bold">{val_practices_count}</span>
  },
  {
    title: "Cardinalità minima lotti",
    field: "val_min_practices_count",
    type: "numeric",
  },
  {
    title: "Cardinalità massima lotti",
    field: "val_max_practices_count",
    type: "numeric",
  },
  {
    title: "Volume pratiche ad oggi",
    field: "val_practices_volume",
    type: "numeric",
    editable: "never",
    render: ({val_practices_volume}) => <span className="font-bold">{formatCurrency(val_practices_volume)}</span>
  },
  {
    title: <>
      Svuotamento parziale
      <Tooltip
        text={
          "Tetto massimo di volume € per il gruppo di pratiche. Se impostato, alcune pratiche potrebbero non essere " +
          "incluse nei lotti finali."
        }
        placement="top"
      />
    </>,
    field: "val_limit",
    type: "numeric",
    render: ({val_limit}) => val_limit > 0
      ? <span className="font-semibold text-yellow-600">{formatCurrency(val_limit)}</span>
      : <span className="text-gray-400">Nessun limite</span>,
    editComponent: ({value, onChange}) => (
      <TextField
        id="standard-basic"
        label=""
        variant="standard"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Nessun limite"
        type="number"
        inputProps={{style: {fontSize: 13}}}
      />
    ),
  },
  {
    title: "Volume minimo lotti",
    field: "val_min_practices_volume",
    type: "numeric",
    render: ({val_min_practices_volume}) => formatCurrency(val_min_practices_volume)
  },
  {
    title: "Volume massimo lotti",
    field: "val_max_practices_volume",
    type: "numeric",
    render: ({val_max_practices_volume}) => formatCurrency(val_max_practices_volume)
  },
];

export const getOptions = (editable) => ({
  filtering: false,
  showTitle: false,
  toolbar: false,
  paging: true,
  sorting: true,
  selection: editable,
  headerStyle: {
    fontWeight: "bold",
    fontSize: 14,
    backgroundColor: "#f5f5f5",
    borderRadius: 20,
  },
  filterCellStyle: { paddingTop: 8, maxWidth: 180 },
  cellStyle: { border: "none" },
  actions: {
    border: "none",
  },
  actionsCellStyle: { border: "none" },
  rowStyle: ({valid}) => {
    return {backgroundColor: valid ? "" : "rgba(248, 113, 113)"};
  },
  pageSize: 10,
  pageSizeOptions: [5, 10, 20],
});

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
};
