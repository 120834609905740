import React, { useCallback, useContext, useEffect, useState } from "react";

// Services

import Input from "../../Input";
import Modal from "../../Modal";

import Button from "../../Button";
import { NotificationsContext } from "../../Notifications";
import ApiService from "../../../services/ApiService";
import Placeholder from "../../Placeholder";
import CheckboxGroup from "../../CheckboxGroup";

function AddRoleDialog({ open, toggleOpen, roles, setRoles }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [policies, setPolicies] = useState(null);
  const [scopes, setScopes] = useState([]);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    let closed = false;
    const fetchPolicies = async () => {
      try {
        const data = await ApiService.getApi("policies");

        if (typeof data !== "object") {
          throw new Error("not object");
        }

        if (!closed) {
          setPolicies(data);
        }
      } catch (error) {
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    fetchPolicies();

    return () => {
      closed = true;
    };
  }, [push]);

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!name) {
          return;
        }

        setLoading(true);
        const data = await ApiService.postApiJson("roles", {
          name,
          description,
          scopes: scopes.join(";"),
        });
        setRoles({ ...roles, [data.id]: data });
        toggleOpen();
        push({
          title: "Ruolo creato con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Ruolo già in uso!",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [name, setRoles, toggleOpen, roles, description, scopes, push]
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi ruolo
          </h3>
        </div>

        <form id="add-role-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Nome ruolo"
              type="text"
              errorMessage="Inserisci il nome del ruolo"
              required
            />
          </div>
          <div>
            <Input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              label="Descrizione"
              type="text"
            />
          </div>

          <div className="mb-6">
            {policies ? (
              Object.keys(policies).map((key) => (
                <div key={key}>
                  <CheckboxGroup
                    labelClasses="font-bold"
                    horizontal
                    title={policies[key].description}
                    options={policies[key].scopes.map((o) => ({
                      value: o,
                      label: o,
                    }))}
                    values={scopes}
                    onChange={(value) => {
                      if (scopes.indexOf(value) >= 0) {
                        setScopes([...scopes.filter((v) => v !== value)]);
                      } else {
                        setScopes([...scopes, value]);
                      }
                    }}
                  />
                </div>
              ))
            ) : (
              <div>
                <Placeholder width="w-4/5" />
                <Placeholder width="w-3/5" />
              </div>
            )}
          </div>

          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              AGGIUNGI
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddRoleDialog;
