import React, {useState} from "react";
import Tabs from "../ui-components/Tabs";
import _ from "lodash";
import {ClientInhibitionsTable, FornituraInhibitionsTable} from "./Tables/InhibitionsTable";
import useInhibitions from "../hooks/useInhibitions";


const TABS = ['fornitura', 'cliente'];


const InhibitionsHandler = () => {

  // state
  const [activeTab, setActiveTab] = useState(0);

  // hooks
  const { fetch, exportCsv, rowEditFns, downloading } = useInhibitions(TABS[activeTab]);

  // utilities
  const getContent = () => {
    const commonProps = {
      fetchDataFn: fetch,
      exportCsvFn: exportCsv,
      rowEditFns: rowEditFns,
      downloading: downloading,
    };
    switch (TABS[activeTab]) {
      case 'fornitura':
        return <FornituraInhibitionsTable {...commonProps} />;
      case 'cliente':
        return <ClientInhibitionsTable {...commonProps} />;
      default:
        const choices = TABS.map((t) => '"' + t + '"').join('|');
        throw Error(`Unhandled inhibitions tab "${activeTab}" - please choose between ${choices}`);
    }
  }

  return <>
    <Tabs
      tabs={TABS.map((t) => ({name: _.capitalize(t)}))}
      active={activeTab}
      setActiveTab={setActiveTab}
    />
    <div className="mt-4">
      {getContent()}
    </div>
  </>
}

export default InhibitionsHandler;