import { createContext, useReducer } from "react";

const initialState = {
  isConfigureOpen: false, // set to false
  isScopeDisaligned: true,
  isSplitDefinitionDisaligned: true,
  currentStep: 1, // set to 1
  volumeDataList: [],
  numberPracticesList: [],
  isConfigurationSideOverOpen: false,
  currentConfigurationData: {},
  blockSelected: [],
  redLines: false,
  // SCOPE
  valueScopeDefault: [26, 1200],
  ageScopeDefault: [18, 124],
  agingScopeDefault: [0, 288],
  stateScopeSelected: [{ label: "Italia", value: "Italia" }],
  utilityScopeSelected: [
    { label: "CF", value: "CF" },
    { label: "PIVA", value: "PIVA" },
  ],
  customerStateScopeSelected: ["ATTIVO", "CESSATO"].map((s) => ({label: s, value: s})),
  contractStateScopeSelected: [
    // "ATTIVO",
    "CESSATO",
  ].map((s) => ({label: s, value: s})),
  commodityScopeSelected: [
    { label: "Vendita di Energia Elettrica", value: "Vendita di Energia Elettrica" },
    { label: "Vendita gas", value: "Vendita gas" },
    { label: "Vendita GPL", value: "Vendita GPL" },
    { label: "Altro tipo Gas", value: "Altro tipo Gas" },
    { label: "Vendita TLR", value: "Vendita TLR" },
    { label: "Servizi Idrici", value: "Servizi Idrici" },
    { label: "Fruitore", value: "Fruitore" },
    { label: "Multi-commodity", value: "Multi-commodity" },
    { label: "Assente", value: "Assente" },
  ],
  spoolStationScopeSelected: ["ADR prima battuta", "ADR seconda battuta"]
    .map((v) => ({label: v, value: v})),
  customerTypeScopeSelected: [
    { label: "Micro Business", value: "Micro Business" },
    { label: "Small Business", value: "Small Business" },
    // { label: "Altre PA", value: "Altre PA" },
    // { label: "Parti Correlate", value: "Parti Correlate" },
    { label: "Mid Business", value: "Mid Business" },
    { label: "Large Business", value: "Large Business" },
    { label: "Condomini", value: "Condomini" },
    { label: "Residenziale", value: "Residenziale" },
    { label: "Retail Non Classificabile", value: "Retail Non Classificabile" },
    // { label: "Comuni Soci Parti Correlate", value: "Comuni Soci Parti Correlate" },
    // { label: "Comuni Soci Non Classificati Come Parti Correlate", value: "Comuni Soci Non Classificati Come Parti Correlate" },
  ],
  portalScopeSelected: [
    // { label: "SEV", value: "SEV" },
    // { label: "ATENA", value: "ATENA" },
    { label: "IME", value: "IME" },
    // { label: "IDRICO", value: "IDRICO" },
  ],
  // SPLIT SELECTED
  valueSplitSelected: [],
  etaSplitSelected: [],
  agingSplitSelected: [],
  stateSplitSelected: [],
  utilitySplitSelected: [],
  customerStateSplitSelected: [],
  contractStateSplitSelected: [],
  commoditySplitSelected: [],
  customerTypeSplitSelected: [],
  portalSplitSelected: [],
  // TABLES DATA
  configurationData: [],
  adrList: [],
  adrSelectedList: [],
  // RANGE FILTER
  stateFilterOptions: [
    { label: "Italia", value: "Italia" },
    { label: "Liguria", value: "Liguria" },
    { label: "Calabria", value: "Calabria" },
    { label: "Lombardia", value: "Lombardia" },
    { label: "Emilia Romagna", value: "Emilia-Romagna" },
    { label: "Valle d'Aosta", value: "Valle d'Aosta" },
    { label: "Marche", value: "Marche" },
    { label: "Trentino-Alto Adige", value: "Trentino-Alto Adige" },
    { label: "Sicilia", value: "Sicilia" },
    { label: "Campania", value: "Campania" },
    { label: "Puglia", value: "Puglia" },
    { label: "Abruzzo", value: "Abruzzo" },
    { label: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia" },
    { label: "Piemonte", value: "Piemonte" },
    { label: "Toscana", value: "Toscana" },
    { label: "Lazio", value: "Lazio" },
    { label: "Molise", value: "Molise" },
    { label: "Sardegna", value: "Sardegna" },
    { label: "Umbria", value: "Umbria" },
    { label: "Veneto", value: "Veneto" },
  ],
  utilityFilterOptions: [
    { label: "Codice Fiscale", value: "CF" },
    { label: "Partita Iva", value: "PIVA" },
  ],
  customerStateFilterOptions: ["Attivo", "Cessato"].map((s) => ({label: s, value: s.toUpperCase()})),
  contractStateFilterOptions: ["Attivo", "Cessato"].map((s) => ({label: s, value: s.toUpperCase()})),
  commodityFilterOptions: [
    { label: "Vendita di Energia Elettrica", value: "Vendita di Energia Elettrica" },
    { label: "Vendita gas", value: "Vendita gas" },
    { label: "Vendita GPL", value: "Vendita GPL" },
    { label: "Altro tipo Gas", value: "Altro tipo Gas" },
    { label: "Vendita TLR", value: "Vendita TLR" },
    { label: "Servizi Idrici", value: "Servizi Idrici" },
    { label: "Fruitore", value: "Fruitore" },
    { label: "Multi-commodity", value: "Multi-commodity" },
    { label: "Assente", value: "Assente" },
  ],
  spoolStationFilterOptions: ["ADR prima battuta", "ADR seconda battuta"]
    .map((v) => ({label: v, value: v})),
  customerTypeFilterOptions: [
    { label: "Micro Business", value: "Micro Business" },
    { label: "Small Business", value: "Small Business" },
    { label: "Altre PA", value: "Altre PA" },
    { label: "Parti Correlate", value: "Parti Correlate" },
    { label: "Mid Business", value: "Mid Business" },
    { label: "Large Business", value: "Large Business" },
    { label: "Condomini", value: "Condomini" },
    { label: "Residenziale", value: "Residenziale" },
    { label: "Retail Non Classificabile", value: "Retail Non Classificabile" },
    { label: "Comuni Soci Parti Correlate", value: "Comuni Soci Parti Correlate" },
    { label: "Comuni Soci Non Classificati Come Parti Correlate", value: "Comuni Soci Non Classificati Come Parti Correlate" },
  ],
  portalFilterOptions: [
    { label: "SEV", value: "SEV" },
    { label: "ATENA", value: "ATENA" },
    { label: "IME", value: "IME" },
    { label: "IDRICO", value: "IDRICO" },
  ],
  // AVAILABLE FILTERS
  availableStateFilter: [],
  availableUtilityFilter: [],
  availableCustomerStateFilter: [],
  availableContractStateFilter: [],
  availableCommodityFilter: [],
  availableCustomerTypeFilter: [],
  availablePortalFilter: [],
};

const actions = {
  SET_SPLIT_DEFINITION_ALIGNED: "SET_SPLIT_DEFINITION_ALIGNED",
  SET_REDLINES: "SET_REDLINES",
  SET_BLOCK_SELECTED: "SET_BLOCK_SELECTED",
  SET_VALUE_SPLIT_SELECTED: "SET_VALUE_SPLIT_SELECTED",
  SET_ETA_SPLIT_SELECTED: "SET_ETA_SPLIT_SELECTED",
  SET_AGING_SPLIT_SELECTED: "SET_AGING_SPLIT_SELECTED",
  SET_STATE_SPLIT_SELECTED: "SET_STATE_SPLIT_SELECTED",
  SET_UTILITY_SPLIT_SELECTED: "SET_UTILITY_SPLIT_SELECTED",
  SET_CUSTOMER_STATE_SPLIT_SELECTED: "SET_CUSTOMER_STATE_SPLIT_SELECTED",
  SET_CONTRACT_STATE_SPLIT_SELECTED: "SET_CONTRACT_STATE_SPLIT_SELECTED",
  SET_COMMODITY_SPLIT_SELECTED: "SET_COMMODITY_SPLIT_SELECTED",
  SET_CUSTOMER_TYPE_SPLIT_SELECTED: "SET_CUSTOMER_TYPE_SPLIT_SELECTED",
  SET_PORTAL_SPLIT_SELECTED: "SET_PORTAL_SPLIT_SELECTED",
  SET_VOLUME_DATA_LIST: "SET_VOLUME_DATA_LIST",
  SET_NUMBER_PRACTICES_LIST: "SET_NUMBER_PRACTICES_LIST",
  UPDATE_CONFIGURATION_DATA: "UPDATE_CONFIGURATION_DATA",
  SET_CONFIGURATION_DATA: "SET_CONFIGURATION_DATA",
  SET_ADR_LIST: "SET_ADR_LIST",
  SET_SCOPE_DATA_ALIGNED: "SET_SCOPE_DATA_ALIGNED",
  TOGGLE_CONFIGURE_PAGE: "TOGGLE_CONFIGURE_PAGE",
  TOGGLE_CONFIGURATION_SIDEOVER: "TOGGLE_CONFIGURATION_SIDEOVER",
  SET_CURRENT_CONFIGURATION_DATA: "SET_CURRENT_CONFIGURATION_DATA",
  CHANGE_VALUE_SCOPE: "CHANGE_VALUE_SCOPE",
  CHANGE_AGE_SCOPE: "CHANGE_AGE_SCOPE",
  CHANGE_AGING_SCOPE: "CHANGE_AGING_SCOPE",
  CHANGE_UTILITY_SCOPE: "CHANGE_UTILITY_SCOPE",
  CHANGE_STATE_SCOPE: "CHANGE_STATE_SCOPE",
  CHANGE_CUSTOMER_STATE_SCOPE: "CHANGE_CUSTOMER_STATE_SCOPE",
  CHANGE_CONTRACT_STATE_SCOPE: "CHANGE_CONTRACT_STATE_SCOPE",
  CHANGE_COMMODITY_SCOPE: "CHANGE_COMMODITY_SCOPE",
  CHANGE_SPOOL_STATION_SCOPE: "CHANGE_SPOOL_STATION_SCOPE",
  CHANGE_CUSTOMER_TYPE_SCOPE: "CHANGE_CUSTOMER_TYPE_SCOPE",
  CHANGE_PORTAL_SCOPE: "CHANGE_PORTAL_SCOPE",
  SET_UTILITY_AVAILABLE_FILTER: "SET_UTILITY_AVAILABLE_FILTER",
  SET_STATE_AVAILABLE_FILTER: "SET_STATE_AVAILABLE_FILTER",
  SET_CUSTOMER_STATE_AVAILABLE_FILTER: "SET_CUSTOMER_STATE_AVAILABLE_FILTER",
  SET_CONTRACT_STATE_AVAILABLE_FILTER: "SET_CONTRACT_STATE_AVAILABLE_FILTER",
  SET_COMMODITY_AVAILABLE_FILTER: "SET_COMMODITY_AVAILABLE_FILTER",
  SET_CUSTOMER_TYPE_AVAILABLE_FILTER: "SET_CUSTOMER_TYPE_AVAILABLE_FILTER",
  SET_PORTAL_AVAILABLE_FILTER: "SET_PORTAL_AVAILABLE_FILTER",
  RESET_CURRENT_STEP: "RESET_CURRENT_STEP",
  ADD_STEP: "ADD_STEP",
  REMOVE_STEP: "REMOVE_STEP",
  ADD_AVAILABLE_STATE_FILTER: "ADD_AVAILABLE_STATE_FILTER",
  REMOVE_AVAILABLE_STATE_FILTER: "REMOVE_AVAILABLE_STATE_FILTER",
  ADD_AVAILABLE_UTILITY_FILTER: "ADD_AVAILABLE_UTILITY_FILTER",
  REMOVE_AVAILABLE_UTILITY_FILTER: "REMOVE_AVAILABLE_UTILITY_FILTER",
  ADD_AVAILABLE_CUSTOMER_STATE_FILTER: "ADD_AVAILABLE_CUSTOMER_STATE_FILTER",
  REMOVE_AVAILABLE_CUSTOMER_STATE_FILTER: "REMOVE_AVAILABLE_CUSTOMER_STATE_FILTER",
  ADD_AVAILABLE_CONTRACT_STATE_FILTER: "ADD_AVAILABLE_CONTRACT_STATE_FILTER",
  REMOVE_AVAILABLE_CONTRACT_STATE_FILTER: "REMOVE_AVAILABLE_CONTRACT_STATE_FILTER",
  ADD_AVAILABLE_COMMODITY_FILTER: "ADD_AVAILABLE_COMMODITY_FILTER",
  REMOVE_AVAILABLE_COMMODITY_FILTER: "REMOVE_AVAILABLE_COMMODITY_FILTER",
  ADD_AVAILABLE_CUSTOMER_TYPE_FILTER: "ADD_AVAILABLE_CUSTOMER_TYPE_FILTER",
  REMOVE_AVAILABLE_CUSTOMER_TYPE_FILTER:
    "REMOVE_AVAILABLE_CUSTOMER_TYPE_FILTER",
  ADD_AVAILABLE_PORTAL_FILTER: "ADD_AVAILABLE_PORTAL_FILTER",
  REMOVE_AVAILABLE_PORTAL_FILTER: "REMOVE_AVAILABLE_PORTAL_FILTER",
  SET_CONFIGURATION_NAME: "SET_CONFIGURATION_NAME",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_REDLINES:
      return {
        ...state,
        redLines: action.payload,
      };
    case actions.SET_BLOCK_SELECTED:
      return {
        ...state,
        blockSelected: action.payload,
      };
    case actions.SET_VALUE_SPLIT_SELECTED:
      return {
        ...state,
        valueSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_ETA_SPLIT_SELECTED:
      return {
        ...state,
        etaSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_AGING_SPLIT_SELECTED:
      return {
        ...state,
        agingSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_STATE_SPLIT_SELECTED:
      return {
        ...state,
        stateSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_UTILITY_SPLIT_SELECTED:
      return {
        ...state,
        utilitySplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_CUSTOMER_STATE_SPLIT_SELECTED:
      return {
        ...state,
        customerStateSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_CONTRACT_STATE_SPLIT_SELECTED:
      return {
        ...state,
        contractStateSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_COMMODITY_SPLIT_SELECTED:
      return {
        ...state,
        commoditySplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_CUSTOMER_TYPE_SPLIT_SELECTED:
      return {
        ...state,
        customerTypeSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_PORTAL_SPLIT_SELECTED:
      return {
        ...state,
        portalSplitSelected: action.payload,
        isSplitDefinitionDisaligned: true,
      };
    case actions.SET_VOLUME_DATA_LIST:
      return {
        ...state,
        volumeDataList: action.payload,
      };
    case actions.SET_NUMBER_PRACTICES_LIST:
      return {
        ...state,
        numberPracticesList: action.payload,
      };
    case actions.UPDATE_CONFIGURATION_DATA:
      const objIdx = state.configurationData.findIndex(
        (x) => x.id === action.payload.id
      );
      state.configurationData[objIdx] = action.payload;
      return {
        ...state,
        configurationData: state.configurationData,
      };
    case actions.SET_CONFIGURATION_DATA:
      return {
        ...state,
        configurationData: action.payload,
      };
    case actions.SET_ADR_LIST:
      return {
        ...state,
        adrList: action.payload,
      };
    case actions.SET_ADR_SELECTED_LIST:
      return {
        ...state,
        adrSelectedList: action.payload,
      };
    case actions.SET_SCOPE_DATA_ALIGNED:
      return {
        ...state,
        isScopeDisaligned: false,
      };
    case actions.SET_SPLIT_DEFINITION_ALIGNED:
      return {
        ...state,
        isSplitDefinitionDisaligned: false,
      };
    case actions.SET_CURRENT_CONFIGURATION_DATA:
      return {
        ...state,
        currentConfigurationData: action.payload,
      };
    case actions.RESET_CURRENT_STEP:
      return {
        ...state,
        currentStep: 1,
      };
    case actions.TOGGLE_CONFIGURE_PAGE:
      return {
        ...state,
        isConfigureOpen: !state.isConfigureOpen,
      };
    case actions.TOGGLE_CONFIGURATION_SIDEOVER:
      return {
        ...state,
        isConfigurationSideOverOpen: action.payload,
      };
    case actions.CHANGE_VALUE_SCOPE:
      return {
        ...state,
        valueScopeDefault: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_AGE_SCOPE:
      return {
        ...state,
        ageScopeDefault: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_AGING_SCOPE:
      return {
        ...state,
        agingScopeDefault: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_UTILITY_SCOPE:
      return {
        ...state,
        utilityScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_CUSTOMER_STATE_SCOPE:
      return {
        ...state,
        customerStateScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_CONTRACT_STATE_SCOPE:
      return {
        ...state,
        contractStateScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_STATE_SCOPE:
      return {
        ...state,
        stateScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_COMMODITY_SCOPE:
      return {
        ...state,
        commodityScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_SPOOL_STATION_SCOPE:
      return {
        ...state,
        spoolStationScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_CUSTOMER_TYPE_SCOPE:
      return {
        ...state,
        customerTypeScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.CHANGE_PORTAL_SCOPE:
      return {
        ...state,
        portalScopeSelected: action.payload,
        isScopeDisaligned: true,
      };
    case actions.ADD_STEP:
      if (state.currentStep === 4) {
        return state;
      } else {
        return {
          ...state,
          currentStep: state.currentStep + 1,
        };
      }
    case actions.REMOVE_STEP:
      if (state.currentStep === 1) {
        return {
          ...state,
          isConfigureOpen: !state.isConfigureOpen,
        };
      } else {
        return {
          ...state,
          currentStep: state.currentStep - 1,
        };
      }
    case actions.SET_STATE_AVAILABLE_FILTER:
      return {
        ...state,
        availableStateFilter: action.payload ? state.stateScopeSelected : [],
        stateScopeSelected: action.payload ? state.stateScopeSelected : [],
      };
    case actions.SET_UTILITY_AVAILABLE_FILTER:
      return {
        ...state,
        availableUtilityFilter: action.payload
          ? state.utilityScopeSelected
          : [],
        utilityScopeSelected: action.payload ? state.utilityScopeSelected : [],
      };
    case actions.SET_CUSTOMER_STATE_AVAILABLE_FILTER:
      return {
        ...state,
        availableCustomerStateFilter: action.payload
          ? state.customerStateScopeSelected
          : [],
        customerStateScopeSelected: action.payload
          ? state.customerStateScopeSelected
          : [],
      };
    case actions.SET_CONTRACT_STATE_AVAILABLE_FILTER:
      return {
        ...state,
        availableContractStateFilter: action.payload
          ? state.contractStateScopeSelected
          : [],
        contractStateScopeSelected: action.payload
          ? state.contractStateScopeSelected
          : [],
      };
    case actions.SET_COMMODITY_AVAILABLE_FILTER:
      return {
        ...state,
        availableCommodityFilter: action.payload
          ? state.commodityScopeSelected
          : [],
        commodityScopeSelected: action.payload
          ? state.commodityScopeSelected
          : [],
      };
    case actions.SET_CUSTOMER_TYPE_AVAILABLE_FILTER:
      return {
        ...state,
        availableCustomerTypeFilter: action.payload
          ? state.customerTypeScopeSelected
          : [],
        customerTypeScopeSelected: action.payload
          ? state.customerTypeScopeSelected
          : [],
      };
    case actions.SET_PORTAL_AVAILABLE_FILTER:
      return {
        ...state,
        availablePortalFilter: action.payload ? state.portalScopeSelected : [],
        portalScopeSelected: action.payload ? state.portalScopeSelected : [],
      };
    case actions.ADD_AVAILABLE_STATE_FILTER:
      return {
        ...state,
        availableStateFilter: [...state.availableStateFilter, action.payload],
      };
    case actions.REMOVE_AVAILABLE_STATE_FILTER:
      return {
        ...state,
        availableStateFilter: state.availableStateFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.ADD_AVAILABLE_UTILITY_FILTER:
      return {
        ...state,
        availableUtilityFilter: [
          ...state.availableUtilityFilter,
          action.payload,
        ],
      };
    case actions.REMOVE_AVAILABLE_UTILITY_FILTER:
      return {
        ...state,
        availableUtilityFilter: state.availableUtilityFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.ADD_AVAILABLE_CUSTOMER_STATE_FILTER:
      return {
        ...state,
        availableCustomerStateFilter: [
          ...state.availableCustomerStateFilter,
          action.payload,
        ],
      };
    case actions.REMOVE_AVAILABLE_CUSTOMER_STATE_FILTER:
      return {
        ...state,
        availableCustomerStateFilter: state.availableCustomerStateFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.ADD_AVAILABLE_CONTRACT_STATE_FILTER:
      return {
        ...state,
        availableContractStateFilter: [
          ...state.availableContractStateFilter,
          action.payload,
        ],
      };
    case actions.REMOVE_AVAILABLE_CONTRACT_STATE_FILTER:
      return {
        ...state,
        availableContractStateFilter: state.availableContractStateFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.ADD_AVAILABLE_COMMODITY_FILTER:
      return {
        ...state,
        availableCommodityFilter: [
          ...state.availableCommodityFilter,
          action.payload,
        ],
      };
    case actions.REMOVE_AVAILABLE_COMMODITY_FILTER:
      return {
        ...state,
        availableCommodityFilter: state.availableCommodityFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.ADD_AVAILABLE_CUSTOMER_TYPE_FILTER:
      return {
        ...state,
        availableCustomerTypeFilter: [
          ...state.availableCustomerTypeFilter,
          action.payload,
        ],
      };
    case actions.REMOVE_AVAILABLE_CUSTOMER_TYPE_FILTER:
      return {
        ...state,
        availableCustomerTypeFilter: state.availableCustomerTypeFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.ADD_AVAILABLE_PORTAL_FILTER:
      return {
        ...state,
        availablePortalFilter: [...state.availablePortalFilter, action.payload],
      };
    case actions.REMOVE_AVAILABLE_PORTAL_FILTER:
      return {
        ...state,
        availablePortalFilter: state.availablePortalFilter.filter(
          (state, index) => state.value !== action.payload.value
        ),
      };
    case actions.SET_CONFIGURATION_NAME:
      return {
        ...state,
        configurationName: action.payload,
      };
    default:
      return state;
  }
};

export const ConfigureOptimizerContext = createContext();

export default function useConfigureContextHook() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const context = {
    redLines: state.redLines,
    blockSelected: state.blockSelected,
    blocks: state.blocks,
    volumeDataList: state.volumeDataList,
    numberPracticesList: state.numberPracticesList,
    isScopeDisaligned: state.isScopeDisaligned,
    isSplitDefinitionDisaligned: state.isSplitDefinitionDisaligned,
    currentStep: state.currentStep,
    isConfigureOpen: state.isConfigureOpen,
    isConfigurationSideOverOpen: state.isConfigurationSideOverOpen,
    configurationData: state.configurationData,
    currentConfigurationData: state.currentConfigurationData,
    adrList: state.adrList,
    adrSelectedList: state.adrSelectedList,
    // SPLIT SELECTED
    valueSplitSelected: state.valueSplitSelected,
    etaSplitSelected: state.etaSplitSelected,
    agingSplitSelected: state.agingSplitSelected,
    stateSplitSelected: state.stateSplitSelected,
    utilitySplitSelected: state.utilitySplitSelected,
    customerStateSplitSelected: state.customerStateSplitSelected,
    contractStateSplitSelected: state.contractStateSplitSelected,
    commoditySplitSelected: state.commoditySplitSelected,
    spoolStationSplitSelected: state.spoolStationSplitSelected,
    customerTypeSplitSelected: state.customerTypeSplitSelected,
    portalSplitSelected: state.portalSplitSelected,
    // DEFAULT SCOPE
    valueScopeDefault: state.valueScopeDefault,
    ageScopeDefault: state.ageScopeDefault,
    agingScopeDefault: state.agingScopeDefault,
    stateScopeSelected: state.stateScopeSelected,
    stateFilterOptions: state.stateFilterOptions,
    utilityScopeSelected: state.utilityScopeSelected,
    utilityFilterOptions: state.utilityFilterOptions,
    customerStateScopeSelected: state.customerStateScopeSelected,
    customerStateFilterOptions: state.customerStateFilterOptions,
    contractStateScopeSelected: state.contractStateScopeSelected,
    contractStateFilterOptions: state.contractStateFilterOptions,
    commodityScopeSelected: state.commodityScopeSelected,
    commodityFilterOptions: state.commodityFilterOptions,
    spoolStationScopeSelected: state.spoolStationScopeSelected,
    spoolStationFilterOptions: state.spoolStationFilterOptions,
    customerTypeScopeSelected: state.customerTypeScopeSelected,
    customerTypeFilterOptions: state.customerTypeFilterOptions,
    portalScopeSelected: state.portalScopeSelected,
    portalFilterOptions: state.portalFilterOptions,
    // AVAILABLE FILTERS
    availableStateFilter: state.availableStateFilter,
    availableUtilityFilter: state.availableUtilityFilter,
    availableCustomerStateFilter: state.availableCustomerStateFilter,
    availableContractStateFilter: state.availableContractStateFilter,
    availableCommodityFilter: state.availableCommodityFilter,
    availableCustomerTypeFilter: state.availableCustomerTypeFilter,
    availablePortalFilter: state.availablePortalFilter,
    setVolumeGlobalScope: (volumeData) => {
      dispatch({ type: actions.SET_VOLUME_GLOBAL_SCOPE, payload: volumeData });
    },
    setCountGlobalScope: (countData) => {
      dispatch({ type: actions.SET_COUNT_GLOBAL_SCOPE, payload: countData });
    },
    setVolumeDataList: (volumeData) => {
      dispatch({ type: actions.SET_VOLUME_DATA_LIST, payload: volumeData });
    },
    setRedlines: (boolean) => {
      dispatch({
        type: actions.SET_REDLINES,
        payload: boolean,
      });
    },
    setBlockSelected: (blocks) => {
      dispatch({
        type: actions.SET_BLOCK_SELECTED,
        payload: blocks,
      });
    },
    setValueSplitSelected: (valueSplitSelected) => {
      dispatch({
        type: actions.SET_VALUE_SPLIT_SELECTED,
        payload: valueSplitSelected,
      });
    },
    setEtaSplitSelected: (etaSplitSelected) => {
      dispatch({
        type: actions.SET_ETA_SPLIT_SELECTED,
        payload: etaSplitSelected,
      });
    },
    setAgingSplitSelected: (agingSplitSelected) => {
      dispatch({
        type: actions.SET_AGING_SPLIT_SELECTED,
        payload: agingSplitSelected,
      });
    },
    setStateSplitSelected: (stateSplitSelected) => {
      dispatch({
        type: actions.SET_STATE_SPLIT_SELECTED,
        payload: stateSplitSelected,
      });
    },
    setUtilitySplitSelected: (utilitySplitSelected) => {
      dispatch({
        type: actions.SET_UTILITY_SPLIT_SELECTED,
        payload: utilitySplitSelected,
      });
    },
    setCustomerStateSplitSelected: (customerStateSplitSelected) => {
      dispatch({
        type: actions.SET_CUSTOMER_STATE_SPLIT_SELECTED,
        payload: customerStateSplitSelected,
      });
    },
    setContractStateSplitSelected: (contractStateSplitSelected) => {
      dispatch({
        type: actions.SET_CONTRACT_STATE_SPLIT_SELECTED,
        payload: contractStateSplitSelected,
      });
    },
    setCommoditySplitSelected: (commoditySplitSelected) => {
      dispatch({
        type: actions.SET_COMMODITY_SPLIT_SELECTED,
        payload: commoditySplitSelected,
      });
    },
    setCustomerTypeSplitSelected: (customerTypeSplitSelected) => {
      dispatch({
        type: actions.SET_CUSTOMER_TYPE_SPLIT_SELECTED,
        payload: customerTypeSplitSelected,
      });
    },
    setPortalSplitSelected: (portalSplitSelected) => {
      dispatch({
        type: actions.SET_PORTAL_SPLIT_SELECTED,
        payload: portalSplitSelected,
      });
    },
    setNumberPracticesList: (numberPractices) => {
      dispatch({
        type: actions.SET_NUMBER_PRACTICES_LIST,
        payload: numberPractices,
      });
    },
    toggleConfigurePage: () => {
      dispatch({ type: actions.TOGGLE_CONFIGURE_PAGE });
    },
    setCurrentConfigurationData: (data) => {
      dispatch({ type: actions.SET_CURRENT_CONFIGURATION_DATA, payload: data });
    },
    toggleConfigurationSideOver: (newState) => {
      dispatch({
        type: actions.TOGGLE_CONFIGURATION_SIDEOVER,
        payload: newState,
      });
    },
    handleValueScope: (newState) => {
      dispatch({
        type: actions.CHANGE_VALUE_SCOPE,
        payload: newState,
      });
    },
    handleAgeScope: (newState) => {
      dispatch({
        type: actions.CHANGE_AGE_SCOPE,
        payload: newState,
      });
    },
    handleAgingScope: (newState) => {
      dispatch({
        type: actions.CHANGE_AGING_SCOPE,
        payload: newState,
      });
    },
    handleUtilityScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_UTILITY_SCOPE,
        payload: formattedArray,
      });
    },
    handleCustomerStateScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_CUSTOMER_STATE_SCOPE,
        payload: formattedArray,
      });
    },
    handleContractStateScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_CONTRACT_STATE_SCOPE,
        payload: formattedArray,
      });
    },
    handleCommodityScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_COMMODITY_SCOPE,
        payload: formattedArray,
      });
    },
    handleSpoolStationScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_SPOOL_STATION_SCOPE,
        payload: formattedArray,
      });
    },
    handleCustomerTypeScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_CUSTOMER_TYPE_SCOPE,
        payload: formattedArray,
      });
    },
    handlePortalScope: (newState) => {
      const formattedArray = [];
      newState.map((value) =>
        formattedArray.push({ label: value, value: value })
      );
      dispatch({
        type: actions.CHANGE_PORTAL_SCOPE,
        payload: formattedArray,
      });
    },
    handleStateScope: (newState) => {
      dispatch({
        type: actions.CHANGE_STATE_SCOPE,
        payload: newState,
      });
    },
    addAvailableStateFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_STATE_FILTER,
        payload: newState,
      });
    },
    removeAvailableStateFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_STATE_FILTER,
        payload: newState,
      });
    },
    addAvailableUtilityFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_UTILITY_FILTER,
        payload: newState,
      });
    },
    removeAvailableUtilityFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_UTILITY_FILTER,
        payload: newState,
      });
    },
    addAvailableCustomerStateFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_CUSTOMER_STATE_FILTER,
        payload: newState,
      });
    },
    removeAvailableCustomerStateFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_CUSTOMER_STATE_FILTER,
        payload: newState,
      });
    },
    addAvailableContractStateFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_CONTRACT_STATE_FILTER,
        payload: newState,
      });
    },
    removeAvailableContractStateFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_CONTRACT_STATE_FILTER,
        payload: newState,
      });
    },
    addAvailableCommodityFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_COMMODITY_FILTER,
        payload: newState,
      });
    },
    removeAvailableCommodityFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_COMMODITY_FILTER,
        payload: newState,
      });
    },
    addAvailableCustomerTypeFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_CUSTOMER_TYPE_FILTER,
        payload: newState,
      });
    },
    removeAvailableCustomerTypeFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_CUSTOMER_TYPE_FILTER,
        payload: newState,
      });
    },
    addAvailablePortalFilter: (newState) => {
      dispatch({
        type: actions.ADD_AVAILABLE_PORTAL_FILTER,
        payload: newState,
      });
    },
    removeAvailablePortalFilter: (newState) => {
      dispatch({
        type: actions.REMOVE_AVAILABLE_PORTAL_FILTER,
        payload: newState,
      });
    },
    resetConfigurePage: () => {
      dispatch({
        type: actions.RESET_CONFIGURE_PAGE,
      });
    },
    setADRList: (ADRList) => {
      dispatch({
        type: actions.SET_ADR_LIST,
        payload: ADRList,
      });
    },
    setADRSelectedList: (ADRSelectedList) => {
      dispatch({
        type: actions.SET_ADR_SELECTED_LIST,
        payload: ADRSelectedList,
      });
    },
    setConfigurationData: (ConfigurationData) => {
      dispatch({
        type: actions.SET_CONFIGURATION_DATA,
        payload: ConfigurationData,
      });
    },
    updateConfigurationData: (ConfigurationData) => {
      dispatch({
        type: actions.UPDATE_CONFIGURATION_DATA,
        payload: ConfigurationData,
      });
    },
    addStep: () => {
      if (state.currentStep === 1) {
        dispatch({ type: actions.SET_STATE_AVAILABLE_FILTER, payload: true });
        dispatch({ type: actions.SET_UTILITY_AVAILABLE_FILTER, payload: true });
        dispatch({
          type: actions.SET_CUSTOMER_STATE_AVAILABLE_FILTER,
          payload: true,
        });
        dispatch({
          type: actions.SET_CONTRACT_STATE_AVAILABLE_FILTER,
          payload: true,
        });
        dispatch({
          type: actions.SET_COMMODITY_AVAILABLE_FILTER,
          payload: true,
        });
        dispatch({
          type: actions.SET_CUSTOMER_TYPE_AVAILABLE_FILTER,
          payload: true,
        });
        dispatch({ type: actions.SET_PORTAL_AVAILABLE_FILTER, payload: true });
      }
      if (state.currentStep === 3) {
        dispatch({
          type: actions.SET_STATE_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_UTILITY_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_COMMODITY_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_CUSTOMER_TYPE_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({ type: actions.SET_PORTAL_AVAILABLE_FILTER, payload: false });
      }
      if (state.currentStep === 4) {
        dispatch({ type: actions.TOGGLE_CONFIGURE_PAGE });
        dispatch({ type: actions.RESET_CURRENT_STEP });
      }
      dispatch({ type: actions.ADD_STEP });
    },
    removeStep: () => {
      if (state.currentStep < 4) {
        dispatch({
          type: actions.SET_STATE_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_UTILITY_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_CUSTOMER_STATE_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_CONTRACT_STATE_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_COMMODITY_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({
          type: actions.SET_CUSTOMER_TYPE_AVAILABLE_FILTER,
          payload: false,
        });
        dispatch({ type: actions.SET_PORTAL_AVAILABLE_FILTER, payload: false });
      }
      dispatch({ type: actions.REMOVE_STEP });
    },
    setScopeDataAligned: () => {
      dispatch({
        type: actions.SET_SCOPE_DATA_ALIGNED,
      });
    },
    setSplitDefitionAligned: () => {
      dispatch({
        type: actions.SET_SPLIT_DEFINITION_ALIGNED,
      });
    },

    configurationName: state.configurationName,
    setConfigurationName: (value) => {
      dispatch({
        type: actions.SET_CONFIGURATION_NAME,
        payload: value,
      });
    }
  };

  return { context, ConfigureOptimizerContext };
}
