export const getKpi = (title, num, perc) => (
  <div className="mt-4 mb-5 px-2 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-6 flex flex-col w-full">
    <div
      className={`flex flex-col md:flex-row w-full justify-between md:items-center`}
    >
      <div className="flex flex-col text-left">
        <dt className="text-sm text-lg font-medium text-gray-500 truncate">
          {title}
        </dt>
        <dd className="mt-1 text-3xl font-semibold text-am-600"># {num.toLocaleString()}</dd>
        {perc ? (
          <p className="text-gray-700 font-semibold text-base">{perc}%</p>
        ) : null}
      </div>
    </div>
  </div>
);
