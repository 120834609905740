import React from "react";

const LogoAmmagamma = ({ height = "10", width = "auto" }) => (
  <div className="flex-shrink-0 flex items-center px-4">
    <img
      className={`h-${height} w-${width}`}
      src={process.env.PUBLIC_URL + "/ammagamma.png"}
      alt="ammagamma logo"
    />
  </div>
);

export default LogoAmmagamma;
