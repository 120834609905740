import React from "react";
import { PageSection } from "../../ui-components/Container";
import SolicitsHistoryTable from "../../components/Tables/SolicitsHistoryTable";
import useSolicitsHistory from "../../hooks/useSolicitsHistory";

const SolicitsHistory = ({ start, end=null }) => {
  // hooks
  const { fetch, downloading } = useSolicitsHistory();

  return <PageSection title="Storico solleciti">
    <SolicitsHistoryTable
      dataFn={(query) => fetch(query, start, end)}
      refreshValues={[start, end]}
      downloading={downloading}
    />
  </PageSection>
};

export default SolicitsHistory;
