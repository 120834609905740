import {ACTIVITY_TYPE_LOOKUP, getInhibitionColorCls} from "./InhibitionsCommons";

export const COLUMNS = [
  {
    title: 'ID fornitura',
    field: 'id_fornitura',
    type: 'numeric',
    defaultSort: 'asc',
    hidden: true,
  },
  {
    title: 'ID fornitura terr',
    field: 'id_fornitura_terr',
    type: 'string',
  },
  {
    title: 'Contratto collegato',
    field: 'contratto_collegato',
    type: 'string',
  },
  {
    title: 'Data inizio',
    field: 'data_inizio_inibizione',
    type: 'date',
  },
  {
    title: 'Data fine',
    field: 'data_fine_inibizione',
    type: 'date',
  },
  {
    title: 'Tipologia',
    field: 'activity_type',
    type: 'lookup',
    editable: false,
    lookup: ACTIVITY_TYPE_LOOKUP,
    render: ({activity_type}) => (
      <span className={getInhibitionColorCls(activity_type)}>
        {ACTIVITY_TYPE_LOOKUP[activity_type]}
      </span>
    ),
  },
  {
    title: 'Note',
    field: 'note',
    type: 'string',
  },
];