export const OPTIONS = {
  filtering: true,
  search: false,
  showTitle: true,
  toolbar: true,
  pageSize: 5,
  sorting: true,
  addRowPosition: 'first',
  filterCellStyle: {
    maxWidth: 400,
  },

  headerStyle: {
    backgroundColor: '#F9FAFB',
    fontWeight: 'bold',
    zIndex: 5,
  }
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} inibizioni",
    labelRowsSelect: "inibizioni",
  },
  body: {
    editRow: {
      deleteText: "Confermi di volere eliminare questa inibizione?"
    }
  }
};

export const ACTIVITY_TYPE_LOOKUP = {
  past: 'Passata',
  current: 'In corso',
  future: 'Futura',
}

export const getInhibitionColorCls = (t) => {
  switch (t) {
    case 'current': return "text-green-600";
    case 'future': return "text-blue-500";
    default:
    case 'past': return "text-red-400";
  }
}
