import React, { useEffect, useState, useContext } from "react";
// UI
import MaterialTable from "material-table";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./LotListTableParams";
import { tableIcons } from "../../../ui-components/TableIcons";
import { useSelector, useDispatch } from "react-redux";
import ApiService from "@services/ApiService";
import {
  setDetailLot,
  setCurrentLotId,
  setCurrentLot,
} from "../../../store/runSlice";
import { NotificationsContext } from "../../../ui-components/Notifications";
import formatPercentage from "../../../utils/formatPercentage";
import formatCurrency from "../../../utils/formatCurrency";

export default function LotListTable() {
  const dispatch = useDispatch();
  const currentRunId = useSelector((state) => state.run.currentRunId);
  const [runsDetail, setRunsDetail] = useState(null);
  const [downloading, setDownloading] = useState(null);
  const { push } = useContext(NotificationsContext);
  const [triggerApiCall, setTriggerApiCall] = useState(false);

  useEffect(() => {
    async function getRunDetail() {
      await ApiService.getApi(`optimization/run/${currentRunId}`).then(
        (response) => {
          const newArray = response.map((item) => {

            let norma_status = "";
            if (item.norma_upload_status === "PENDING") {
              norma_status = "In caricamento";
            } else if (item.norma_upload_status === "UPLOADED") {
              norma_status = "Caricato";
            } else if (item.norma_upload_status === "NOT_UPLOADED") {
              norma_status = "Non caricato";
            }

            return {
              id: item.id,
              adr_ammagamma: item.adr_ammagamma?.des_name || "-",
              performance_real: formatPercentage(item.performance_real),
              performance_forecast: formatPercentage(item.performance_forecast),
              val_volume: formatCurrency(item.val_volume),
              val_collected_real: formatCurrency(item.val_collected_real),
              val_collected_forecast: formatCurrency(item.val_collected_forecast),
              upload_status: item.norma_upload_status,
              status: norma_status,
              cod_domain: item.cod_domain,
              practices_count: item.practices_count,
              flg_uploaded: item.flg_uploaded,
            };
          });
          setRunsDetail(newArray);
        }
      );
    }
    getRunDetail();
  }, [currentRunId, triggerApiCall]);

  function handleInfo(rowData) {
    dispatch(setCurrentLotId(rowData.id));
    dispatch(setDetailLot(true));
    dispatch(setCurrentLot(rowData));
  }

  async function handleExport(rowData) {
    const editRowUploadStatus = (status) => {
      setRunsDetail((prev) => prev.map((l) => ({
        ...l,
        ...(l.id === rowData.id ? {upload_status: status} : {})
      })));
    }

    if (!rowData.flg_uploaded) {
      editRowUploadStatus("PENDING");
      try {
        await ApiService.postApiJson(`optimization/lots/${rowData.id}/export`);
        setTriggerApiCall(!triggerApiCall);
        push({
          title: "File caricato verso Norma con successo",
          type: "success",
        });
      } catch (error) {
        editRowUploadStatus("NOT_UPLOADED");
        push({
          title: "Errore nel caricamento verso Norma",
          type: "error",
        });
      }
    }
  }

  async function handleDownload(rowData) {
    if (!!downloading)
      return;

    setDownloading(rowData.id);
    ApiService.getApiBlog(`optimization/lots/${rowData.id}/download`)
      .then(
        (response) =>{
        // create file link in browser's memory
        const href = URL.createObjectURL(response);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        }
      )
      .catch((error) => {
        push({
          title: "Errore nel download del file",
          type: "error",
        });
        console.error(error);
      })
      .finally(() => {
        setDownloading(null);
      });
  }

  return (
    <>
      <MaterialTable
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          borderRadius: 20,
          border: "none",
          zIndex: 0,
        }}
        columns={COLUMNS}
        data={runsDetail ?? []}
        isLoading={runsDetail === null}
        icons={tableIcons}
        options={OPTIONS}
        localization={LOCALIZATION}
        actions={[
          (rowData) => ({
            icon: tableIcons.Info,
            onClick: (event, rowData) => handleInfo(rowData),
            tooltip: "Dettaglio Lotto",
          }),
          (rowData) => ({
            disabled: rowData.practices_count === 0,
            icon: downloading === rowData.id ? tableIcons.Spinner : tableIcons.Export,
            onClick: (event, rowData) => handleDownload(rowData),
            tooltip: rowData.practices_count > 0 ? "Download lotto" : "Download lotto non disponibile",
          }),
          (rowData) => ({
            icon: rowData.upload_status === "PENDING"
              ? tableIcons.Clock
              : (
                rowData.upload_status === "UPLOADED"
                  ? tableIcons.Check
                  : tableIcons.CloudUpload
              ),
            onClick: (event, rowData) => handleExport(rowData),
            tooltip: "Invio a Norma",
          }),
        ]}
      />
    </>
  );
}
