import React, { useState } from "react";

import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/UnreachableUsers";
import { tableIcons } from "../../ui-components/TableIcons";
import { excelDownload } from "../../utils/excelDownload";
import Button from "../../ui-components/Button";
import {PageHeading} from "../../ui-components/Container";

function UnreachableUsersTable({ data }) {
  const [submitting, setSubmitting] = useState(false);
  const tableRef = React.useRef(null);
  const lkp_ragione =
    data && data.reduce((acc, i) => _.set(acc, i["ragione"], i["ragione"]), {});
  const columns = lkp_ragione && getColumns(lkp_ragione);

  const download = (filteredData) => {
    const pairs = {
      "Id Cliente": "id_cliente",
      "Id Fornitura": "id_fornitura",
      "Id Fornitura Terr": "id_fornitura_terr",
      Recapito: "recapito",
      Ragione: "ragione",
      "Credito aperto": "con_credito_aperto",
    };
    let columns = Object.keys(pairs);
    let fields = Object.values(pairs);
    excelDownload(filteredData, columns, fields, "utenze non contattabili");
  };

  return (
    <>
      <PageHeading
        title="Utenze non contattabili"
        contentActions={
          <Button
            submitting={submitting}
            onClick={() => {
              if (tableRef.current) {
                setSubmitting(true);
                setTimeout(() => {
                  download(tableRef.current.state.data);
                  setSubmitting(false);
                }, 300);
              }
            }}
          >
            Download
          </Button>
        }
      />
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={data}
        icons={tableIcons}
        isLoading={!data || !columns}
        options={OPTIONS}
        localization={LOCALIZATION}
        style={{marginBottom: 15}}
      />
    </>
  );
}

export default UnreachableUsersTable;
