import React from "react";

const DocumentationA = () => {
  return (
    <iframe
      title="id"
      width="100%"
      height="100%"
      style={{ minHeight: "100vh" }}
      frameborder="0"
      src={process.env.PUBLIC_URL + "/html/index.html"}
    />
  );
};

export default DocumentationA;
