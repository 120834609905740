import { Fragment, useCallback, useEffect } from "react";

const useUpdateSummary = ({
  summary,
  setDefault,
  defaultSetting,
  onlyCompanion,
  systemDisabled,
  currentSetting,
  sliderSmsEmail,
  sliderContent,
  minSolicitousValue,
  monthlyCap,
  dailyCap,
  setSummary,
  saveCurrentSettings,
}) => {
  const updateSummary = useCallback(() => {
    if (!currentSetting) {
      return null;
    }
    const setValue = (value, key, percentage, bool, disabled) => {
      let textValue = value.toLocaleString();
      let textCurrent = currentSetting[key].toLocaleString();

      if (percentage) {
        textValue = `${(value * 100).toFixed(0)}%`;
        textCurrent = `${(currentSetting[key] * 100).toFixed(0)}%`;
      }

      if (bool) {
        textValue = value ? "Attivo" : "Disattivato";
        textCurrent = currentSetting[key] ? "Attivo" : "Disattivato";
      }

      if (disabled) {
        textValue = value ? "Disabilitato" : "Attivo";
        textCurrent = currentSetting[key] ? "Disabilitato" : "Attivo";
      }

      if (textValue === textCurrent) {
        return textCurrent;
      }

      return (
        <Fragment>
          <span className="line-through mr-4 text-gray-400">{textCurrent}</span>
          <span className="">{textValue}</span>
        </Fragment>
      );
    };

    setSummary({
      ...summary,
      rows: [
        {
          title: "Valore minimo sollecitabile",
          tooltip:
            "Importo residuo da pagare totale minimo per cui fare partire solleciti sulla singola fornitura. Es: se impostato a 10€ una fornitura con una fattura con importo residuo di 7€ e una fattura con importo residuo di 4€ verrebbe sollecitata.",
          value: setValue(minSolicitousValue, "minimo_valore_sollecitabile"),
        },
        {
          title: "Numero massimo invii giornalieri",
          tooltip:
            "Limite non superabile di invii in un singolo giorno (indipendentemente dai messaggi residui mensili)",
          value: setValue(dailyCap, "cap_invii_giornalieri"),
        },
        {
          title: "Numero massimo invii mensili",
          tooltip:
            "Limite non superabile di invii in un singolo mese di calendario",
          value: setValue(monthlyCap, "cap_invii_mensili"),
        },
        {
          title: "SMS",
          tooltip:
            "Percentuale di SMS da inviare sul totale di invii. Questo numero influsice sull'associazione tra fornitura e canale di contatto fatta durante la presa in carico della fornitura stessa. Es: Una nuova fornitura entra nel perimetro dello strumento e i valori impostati sono SMS=30% e Email=70%. In questo caso con il 30% della probabilità la fornitura sarà associata ad SMS, con il 70% della probabilità sarà associata ad Email. Eventuali ribilanciamenti su forniture già associate a canali di contatto richiedono un intervento manuale.",
          value: setValue(sliderSmsEmail, "sms", true),
        },
        {
          title: "Email",
          tooltip:
            "Percentuale di Email da inviare sul totale di invii. Questo numero influsice sull'associazione tra fornitura e canale di contatto fatta durante la presa in carico della fornitura stessa. Es: Una nuova fornitura entra nel perimetro dello strumento e i valori impostati sono SMS=30% e Email=70%. In questo caso con il 30% della probabilità la fornitura sarà associata ad SMS, con il 70% della probabilità sarà associata ad Email. Eventuali ribilanciamenti su forniture già associate a canali di contatto richiedono un intervento manuale.",
          value: setValue(1 - sliderSmsEmail, "email", true),
        },
        {
          title: "Contenuto testuale",
          tooltip:
            "Percentuale di contenuti testuali (PTEXT) da inviare sul totale. Questo numero influsice sull'associazione tra fornitura e contenuto fatta durante la presa in carico della fornitura stessa. Es: Una nuova fornitura entra nel perimetro dello strumento e i valori impostati sono PTEXT=20%, PVIDEO=40% e PWEB=40%. In questo caso con il 20% della probabilità la fornitura sarà associata a PTEXT, con il 40% della probabilità sarà associata a PVIDEO e con il 40% della probabilità a PWEB. Eventuali ribilanciamenti su forniture già associate a contenuti richiedono un intervento manuale.",
          value: setValue(sliderContent[0], "ptext", true),
        },
        {
          title: "Contenuto video",
          tooltip:
            "Percentuale di contenuti video (PVIDEO) da inviare sul totale. Questo numero influsice sull'associazione tra fornitura e contenuto fatta durante la presa in carico della fornitura stessa. Es: Una nuova fornitura entra nel perimetro dello strumento e i valori impostati sono PTEXT=20%, PVIDEO=40% e PWEB=40%. In questo caso con il 20% della probabilità la fornitura sarà associata a PTEXT, con il 40% della probabilità sarà associata a PVIDEO e con il 40% della probabilità a PWEB. Eventuali ribilanciamenti su forniture già associate a contenuti richiedono un intervento manuale.",
          value: setValue(sliderContent[1] - sliderContent[0], "pvideo", true),
        },
        {
          title: "Contenuto web",
          tooltip:
            "Percentuale di contenuti web (PWEB) da inviare sul totale. Questo numero influsice sull'associazione tra fornitura e contenuto fatta durante la presa in carico della fornitura stessa. Es: Una nuova fornitura entra nel perimetro dello strumento e i valori impostati sono PTEXT=20%, PVIDEO=40% e PWEB=40%. In questo caso con il 20% della probabilità la fornitura sarà associata a PTEXT, con il 40% della probabilità sarà associata a PVIDEO e con il 40% della probabilità a PWEB. Eventuali ribilanciamenti su forniture già associate a contenuti richiedono un intervento manuale.",
          value: setValue(1 - sliderContent[1], "pweb", true),
        },
        {
          title: "Solo accompagnamenti",
          tooltip:
            "Indicatore di stato per la funzionalità di invio dei soli accompagnamenti.",
          value: setValue(onlyCompanion, "solo_accompagnamenti", false, true),
        },
        {
          title: "Stato sistema",
          tooltip: "Indicatore di stato del sistema.",
          value: setValue(systemDisabled, "sistema_spento", false, false, true),
        },
      ],
      buttons: [
        { text: "SALVA", onClick: saveCurrentSettings, async: true },
        {
          text: "IMPOSTA VALORI DI DEFAULT",
          default: true,
          styleType: "secondary",
          onClick: setDefault,
        },
      ],
    });
  }, [
    setDefault,
    defaultSetting,
    currentSetting,
    sliderSmsEmail,
    sliderContent,
    minSolicitousValue,
    monthlyCap,
    dailyCap,
    saveCurrentSettings,
    onlyCompanion,
    systemDisabled,
  ]);

  useEffect(() => {
    updateSummary();
  }, [
    sliderSmsEmail,
    sliderContent,
    minSolicitousValue,
    monthlyCap,
    dailyCap,
    updateSummary,
  ]);

  return updateSummary;
};

export default useUpdateSummary;
