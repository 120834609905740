import React from "react";
import Label from "./Label";

const RadioGroup = ({
  id = "r-groups",
  title,
  description,
  options,
  onChange,
  currentValue,
  horizontal,
  labelClasses = "",
}) => {
  if (!options || !options.length) {
    throw new Error("missing options");
  }

  return (
    <div className="mb-1">
      {title ? (
        <Label
          className={`text-base font-medium text-gray-900 ${labelClasses}`}
        >
          {title}
        </Label>
      ) : null}
      {description ? (
        <p className="text-sm leading-5 text-gray-500">{description}</p>
      ) : null}
      <fieldset
        className={`mt-1 flex ${
          horizontal ? "flex-wrap -mx-4" : "flex-col space-y-4"
        }`}
      >
        {options.map(({ name, value }) => (
          <div
            onClick={() => {
              onChange(value);
            }}
            key={value}
            className={`relative flex items-start cursor-pointer ${
              horizontal ? "mb-2 ml-4" : ""
            }`}
          >
            <input
              name={id}
              readOnly
              id={value}
              type="radio"
              checked={currentValue === value}
              className="focus:ring-am-500 h-4 w-4 cursor-pointer text-am-600 border-gray-300"
            />
            <label
              htmlFor={value}
              className="ml-2 block text-sm font-medium cursor-pointer text-gray-700"
            >
              {name}
            </label>
          </div>
        ))}
      </fieldset>
    </div>
  );
};

export default RadioGroup;
