import { configureStore } from "@reduxjs/toolkit";
import configurationReducer from "./configurationSlice";
import runReducer from "./runSlice";

export const store = configureStore({
  reducer: {
    configuration: configurationReducer,
    run: runReducer,
  },
});
