import SearchSelect from "../../../ui-components/SearchSelect";
import {Fragment, useContext, useEffect, useRef, useState} from "react";
import Input from "../../../ui-components/Input";
import {ConfigureOptimizerContext} from "../State/ConfigureOptimizerContext";
import difference from "lodash/difference";
import {MultipleSliderFilter} from "../Components/SliderFilter";
import SplitDefinitionTable from "../Components/SplitDefinitionTable";
import Cached from "@material-ui/icons/Cached";
import ApiService from "../../../services/ApiService";
import Button from "../../../ui-components/Button";
import {ExclamationCircleIcon, PencilIcon} from "@heroicons/react/outline";
import Alert from "../../../ui-components/Alert";
import {ArrowDownIcon} from "@heroicons/react/solid";
import {isBlockValid} from "./utilities";

const ChildFilter = ({
  index,
  filterName,
  initialData,
  availableFilterData,
  addFunction,
  removeFunction,
  sendDataToParent,
}) => {
  const [state, setState] = useState([]);
  const stateRef = useRef();

  useEffect(() => {
    stateRef.current = state;
    sendDataToParent(state, index);
  }, [state]);

  useEffect(() => {
    if (!!initialData)
      setState(initialData);

    return () => {
      if (stateRef.current) {
        stateRef.current.forEach((removedElement) =>
          addFunction(removedElement)
        );
      }
    };
  }, []);

  return (
    <div className="flex gap-2 items-center m-2 w-80">
      <SearchSelect
        // placeholder={`${filterName}`}
        // disabled
        required
        options={availableFilterData}
        isMulti
        value={state}
        onChange={(newArray) => {
          const newArrayValues = newArray ? newArray : [];
          const currentValues = state ? state : [];
          const newArrayLenght = newArray ? newArray.length : 0;
          const currentArrayLenght = state ? state.length : 0;
          if (newArrayLenght > currentArrayLenght) {
            const addedElement = difference(newArrayValues, currentValues)[0];
            removeFunction(addedElement);
          } else {
            const removedElement = difference(currentValues, newArrayValues)[0];
            addFunction(removedElement);
          }
          setState(newArray);
        }}
      />
      {!state?.length && <ExclamationCircleIcon
        className="text-red-500  w-7 cursor-help"
        title="Inserire almeno un valore"
      /> }
    </div>
  );
};

const GenerateMultipleFilters = ({
  numberOfFilters,
  filterName,
  filterData,
  availableFilterData,
  addFunction,
  removeFunction,
  setSplit,
}) => {
  let components = [];
  const [selectedSplit, setSelectedSplit] = useState([{splitIndex: 0, data: filterData}]);
  const [prevNumberFilters] = useState(1);

  function removeData(tempList, splitIndex) {
    const objWithIdIndex = tempList.findIndex(
      (obj) => obj.splitIndex === splitIndex
    );

    if (objWithIdIndex > -1) {
      tempList.splice(objWithIdIndex, 1);
    }
  }

  function addData(data, tempList, splitIndex) {
    if (data?.length > 0) {
      tempList.push({ data, splitIndex });
    }
  }

  function sendDataToParent(data, splitIndex) {
    let tempList = selectedSplit;
    if (numberOfFilters >= prevNumberFilters) {
      removeData(tempList, splitIndex);
      addData(data, tempList, splitIndex);
    } else {
      removeData(tempList, splitIndex);
    }
    setSelectedSplit(tempList);
    setSplit(tempList);
  }
  for (let i = 0; i < numberOfFilters; i++) { 
    components.push(
      <ChildFilter
        key={i}
        index={i}
        filterName={filterName}
        initialData={i === 0 ? selectedSplit?.[i]?.data : null}
        availableFilterData={availableFilterData
          .filter(({value: outerValue}, idx) => !selectedSplit
            .some(({data}) => data
              .some(({value}) => value === outerValue)
            )
            &&
            availableFilterData.map(({value}) => value).indexOf(outerValue) === idx
          )
        }
        addFunction={addFunction}
        removeFunction={removeFunction}
        sendDataToParent={sendDataToParent}
      />
    );
  }

  return <>{components}</>;
};

function MultipleFilters({
  filterName,
  filterData,
  availableFilterData,
  addFunction,
  removeFunction,
  setSplit,
}) {
  const [currentNumberFilters, setCurrentNumberFilters] = useState(1);

  function calculateNumberOfFilters(newNumber) {
    const newFiltersBucket = availableFilterData.length; // 0
    const maxFiltersBucket = filterData.length; // 0
    if (newNumber < currentNumberFilters) {
      return newNumber;
    } else if (!newFiltersBucket) {
      return currentNumberFilters;
    } else if (newNumber > maxFiltersBucket) {
      return maxFiltersBucket;
    } else {
      return newNumber;
    }
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-2 text-black font-semibold flex justify-start items-center">
          {filterName}
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <Input
            value={currentNumberFilters}
            onChange={(e) => {
              setCurrentNumberFilters(
                calculateNumberOfFilters(parseFloat(e.target.value))
              );
            }}
            type="number"
          />
        </div>
        <div className={` col-span-9 flex flex-wrap`}>
          <GenerateMultipleFilters
            numberOfFilters={currentNumberFilters}
            filterName={filterName}
            filterData={filterData}
            availableFilterData={availableFilterData}
            addFunction={addFunction}
            removeFunction={removeFunction}
            setSplit={setSplit}
          />
        </div>
      </div>
    </>
  );
}

function MultipleSliderFiltersView({
  description,
  symbol,
  min,
  max,
  step,
  setSplit,
}) {
  const [currentNumberFilters, setCurrentNumberFilters] = useState(1);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-6 mb-6">
        <div className="col-span-2 text-black font-semibold flex justify-start items-center">
          {description}
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <Input
            value={currentNumberFilters}
            onChange={(e) => {
              setCurrentNumberFilters(parseFloat(e.target.value));
            }}
            type="number"
          />
        </div>
        <div
          className={`col-span-9 h-1 flex justify-center items-center w-full`}
        >
          <MultipleSliderFilter
            symbol={symbol}
            min={min}
            max={max}
            step={step}
            numberOfSlider={parseInt(currentNumberFilters) - 1}
            setSplit={setSplit}
          />
        </div>
      </div>
    </>
  );
}

function SplitDefinition() {
  const context = useContext(ConfigureOptimizerContext);
  const [combinationInput, setCombinationInput] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [minNumber, setMinNumber] = useState(0);
  const [maxNumber, setMaxNumber] = useState(900);
  const [minVolume, setMinVolume] = useState(0);
  const [maxVolume, setMaxVolume] = useState(900_000);

  useEffect(() => {
    const hasRedLines = context.blockSelected.some(({valid}) => !valid);
    context.setRedlines(hasRedLines);
  }, [tableData, context.blockSelected]);

  useEffect(() => {
    const regions = context.stateSplitSelected.map((group) =>
      group.data.map((item) => item.label)
    );
    const customer_categories = context.utilitySplitSelected.map((group) =>
      group.data.map((item) => item.label)
    );
    const customer_states = context.customerStateSplitSelected.map((group) =>
      group.data.map((item) => item.label)
    );
    const customer_types = context.customerTypeSplitSelected.map((group) =>
      group.data.map((item) => item.label)
    );
    const portals = context.portalSplitSelected.map((group) =>
      group.data.map((item) => item.label)
    );
    const rng_aging = context.agingSplitSelected.map((item) =>
      item.map((value) => value * 30)
    );
    const obj = {
      rng_volume: context.valueSplitSelected, // OK
      rng_eta: context.etaSplitSelected, // OK
      rng_aging: rng_aging, // OK
      grp_regioni: regions,
      grp_utenze: customer_categories,
      grp_stati_soggetto: customer_states,
      grp_tipologie_cliente: customer_types,
      grp_portali: portals,
    };

    setCombinationInput(obj);
  }, [context]);

  function generateCombinations(obj) {
    const keys = Object.keys(obj);
    const result = [];
    const combos = [];
    for (const key of keys) {
      combos.push(obj[key].length === 0 ? [[]] : obj[key]);
    }
    function helper(currCombo, i) {
      if (i === combos.length) {
        result.push(currCombo);
        return;
      }
      const currProp = combos[i];
      for (const val of currProp) {
        helper([...currCombo, val], i + 1);
      }
    }
    helper([], 0);

    const partial = result.map((combo) => {
      const newObj = {};
      for (let i = 0; i < keys.length; i++) {
        newObj[keys[i]] = combo[i];
      }
      return {
        ...newObj,
        grp_commodity: context.commodityScopeSelected.map((item) => item.value),
        grp_stati_contratto: context.contractStateScopeSelected.map((item) => item.value),
      };
    });

    // Add, to each block, a single spool station by picking from the selected ones
    // NOTE: blocks with mixed spool stations cannot be created
    return context.spoolStationScopeSelected.reduce(
      (acc, curr) => [
        ...acc,
        ...partial.map((item) => ({
          ...item,
          grp_stazione_spool: [curr.value]
        }))
      ],
      [],
    );
  }

  async function handleBlocksPreview(blocks) {
    return await ApiService.postApiJson(
      `optimization/practice/blocks-preview`,
      blocks
    );
  }

  async function fetchData() {
    const result = generateCombinations(combinationInput);
    const response = await handleBlocksPreview(result);
    const newData = response
      .map((block, idx) => ({
        ...block,
        id: idx,  // only used in row updates
        val_min_practices_count: 0,
        val_max_practices_count: 900,
        val_min_practices_volume: 0,
        val_max_practices_volume: 900_000,
      }))
      .map((block) => ({
        ...block,
        valid: isBlockValid(block),
      }));
    setTableData(newData);
    context.setSplitDefitionAligned();
  }

  function massiveRowUpdate(target, value) {
    // Update table data
    const newData = tableData
      .map((item) => ({...item, [target]: parseInt(value)}))
      .map((item) => ({...item, valid: isBlockValid(item)}));
    setTableData(newData);

    // Update selected blocks in context (copies of table data, that also need to be updated)
    const newBlockSelected = context.blockSelected.map((block) => {
      const updatedBlock = newData.find((item) => item.id === block.id);
      return updatedBlock ? updatedBlock : block;
    });
    context.setBlockSelected(newBlockSelected);
  }

  const MASSIVE_UPDATE_BTNS_INFO = [
    {
      name: "Cardinalità minima lotti",
      value: minNumber,
      onChange: setMinNumber,
      action: () => { massiveRowUpdate('val_min_practices_count', minNumber); },
    },
    {
      name: "Volume minimo lotti",
      value: minVolume,
      onChange: setMinVolume,
      action: () => { massiveRowUpdate('val_min_practices_volume', minVolume); },
    },
    {
      name: "Cardinalità massima lotti",
      value: maxNumber,
      onChange: setMaxNumber,
      action: () => { massiveRowUpdate('val_max_practices_count', maxNumber); },
    },
    {
      name: "Volume massimo lotti",
      value: maxVolume,
      onChange: setMaxVolume,
      action: () => { massiveRowUpdate('val_max_practices_volume', maxVolume); },
    },
  ]

  const errorText = context.blockSelected.length === 0 || context.blockSelected.length > 20
    ? "Per procedere occorre selezionare almeno un blocco (max. 20) cliccando sulle rispettive checkbox a sinistra di ogni riga"
    : (context.redLines
      ? <>
        Per procedere è necessario fare sì che le voci "Numero pratiche ad oggi" e "Volume pratiche ad oggi" di ciascuna
        riga risultino accettabili rispetto ai relativi range di minimo-massimo (anch'essi definiti per ciascuna riga). <br/>
        Per farlo è possibile modificare le singole righe (cliccando l'icona <PencilIcon className="w-3 inline" />)
        oppure modificarle in maniera massiva utilizzando gli appositi controlli
      </>
      : null
    );

  return (
    <>
      <MultipleSliderFiltersView
        description={"Valore"}
        symbol={"€"}
        min={context.valueScopeDefault[0]}
        max={context.valueScopeDefault[1]}
        step={50}
        setSplit={context.setValueSplitSelected}
      />
      <MultipleSliderFiltersView
        description={"Età"}
        symbol={"y"}
        min={context.ageScopeDefault[0]}
        max={context.ageScopeDefault[1]}
        step={1}
        setSplit={context.setEtaSplitSelected}
      />
      <MultipleSliderFiltersView
        description={"Aging"}
        symbol={"m"}
        min={context.agingScopeDefault[0]}
        max={context.agingScopeDefault[1]}
        step={1}
        setSplit={context.setAgingSplitSelected}
      />
      {context.stateScopeSelected.length > 0 ? (
        <MultipleFilters
          filterName={"Seleziona le regioni"}
          filterData={context.stateScopeSelected}
          availableFilterData={context.availableStateFilter}
          addFunction={context.addAvailableStateFilter}
          removeFunction={context.removeAvailableStateFilter}
          setSplit={context.setStateSplitSelected}
        />
      ) : null}
      {context.utilityScopeSelected.length > 0 ? (
        <MultipleFilters
          filterName={"Seleziona le utenze"}
          filterData={context.utilityScopeSelected}
          availableFilterData={context.availableUtilityFilter}
          addFunction={context.addAvailableUtilityFilter}
          removeFunction={context.removeAvailableUtilityFilter}
          setSplit={context.setUtilitySplitSelected}
        />
      ) : null}
      {context.customerStateScopeSelected.length > 0 ? (
        <MultipleFilters
          filterName={"Seleziona gli stati soggetto"}
          filterData={context.customerStateScopeSelected}
          availableFilterData={context.availableCustomerStateFilter}
          addFunction={context.addAvailableCustomerStateFilter}
          removeFunction={context.removeAvailableCustomerStateFilter}
          setSplit={context.setCustomerStateSplitSelected}
        />
      ) : null}
      {/*{context.contractStateScopeSelected.length > 0 ? (*/}
      {/*  <MultipleFilters*/}
      {/*    filterName={"Seleziona gli stati contratto"}*/}
      {/*    filterData={context.contractStateScopeSelected}*/}
      {/*    availableFilterData={context.availableContractStateFilter}*/}
      {/*    addFunction={context.addAvailableContractStateFilter}*/}
      {/*    removeFunction={context.removeAvailableContractStateFilter}*/}
      {/*    setSplit={context.setContractStateSplitSelected}*/}
      {/*  />*/}
      {/*) : null}*/}
      {/* {context.commodityScopeSelected.length > 0 ? (
        <MultipleFilters
          filterName={"Seleziona le commodity"}
          filterData={context.commodityScopeSelected}
          availableFilterData={context.availableCommodityFilter}
          addFunction={context.addAvailableCommodityFilter}
          removeFunction={context.removeAvailableCommodityFilter}
          setSplit={context.setCommoditySplitSelected}
        />
      ) : null} */}
      {context.customerTypeScopeSelected.length > 0 ? (
        <MultipleFilters
          filterName={"Seleziona la tipologia di cliente"}
          filterData={context.customerTypeScopeSelected}
          availableFilterData={context.availableCustomerTypeFilter}
          addFunction={context.addAvailableCustomerTypeFilter}
          removeFunction={context.removeAvailableCustomerTypeFilter}
          setSplit={context.setCustomerTypeSplitSelected}
        />
      ) : null}
      {context.portalScopeSelected.length > 0 ? (
        <MultipleFilters
          filterName={"Seleziona il portale"}
          filterData={context.portalScopeSelected}
          availableFilterData={context.availablePortalFilter}
          addFunction={context.addAvailablePortalFilter}
          removeFunction={context.removeAvailablePortalFilter}
          setSplit={context.setPortalSplitSelected}
        />
      ) : null}
      <div className="flex items-center gap-2 py-2">
        <ArrowDownIcon className="w-4" />
        <span className="text-black font-semibold">Applica a tutte le righe</span>
      </div>
      <div className="grid grid-cols-6 sm:grid-cols-12 gap-4 py-2 items-center">
        {MASSIVE_UPDATE_BTNS_INFO.map(({name, value, onChange, action}, idx) =>
          <Fragment key={idx}>
            <div className="col-span-2">
              {name}
            </div>
            <div className="col-span-2">
              <Input
                type='number'
                value={value}
                onChange={(e) => { onChange(e.target.value); }}
                disabled={!tableData?.length}
              />
            </div>
            <div className="col-span-2">
              <Button
                full
                onClick={action}
                textCls="text-sm"
                styleType="secondary"
                disabled={!tableData?.length}
              >
                Applica a tutti
              </Button>
            </div>
          </Fragment>
        )}
      </div>
      {context.isSplitDefinitionDisaligned ? (
        <div className="flex flex-row justify-end items-center mt-6">
          <div className="pr-4 cursor-pointer " onClick={fetchData}>
            <Cached />
          </div>
          <div className="p-4 bg-yellow-100 rounded-xl">
            Anteprima blocchi non aggiornata
          </div>
        </div>
      ) : null}
      <div
        className={`${
          context.isSplitDefinitionDisaligned ? "mt-4" : "mt-10"
        } mx-0`}
      >
        <SplitDefinitionTable data={tableData} setData={setTableData} />
      </div>

      {errorText && (
        <div className="mt-4">
          <Alert
            type="error"
            title="Impossibile procedere"
            text={errorText}
          />
        </div>
      )}
    </>
  );
}

export default SplitDefinition;
