import React from "react";

// Service
import ApiService from "../../../services/ApiService";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./Params";
import { tableIcons } from "../../TableIcons";
import AuthenticationService from "../../../services/AuthenticationService";

function UsersTable({ users, setUser, openAlert }) {
  const scopes = AuthenticationService.getUserScopes();

  const _users = users && _.values(users).map((user) => ({ ...user }));

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: tmp, ...rest } = users;
    setUser({ ...rest, [newData.id]: newData });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          ApiService.postApiJson(`users/${oldData.id}`, newData)
            .then(() => {
              manageUpdatedUser(oldData, newData);
              openAlert("success", "Utenza aggiornata con successo!");
              resolve();
            })
            .catch((err) => {
              console.error("Error during user update!", err);
              openAlert("error", "Impossibile aggiornare l'utenza!");
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        ApiService.deleteApi(`users/${encodeURIComponent(newData.id)}`, newData)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = users;
            setUser({ ...rest });
            openAlert("success", "Utenza eliminata con successo");
            resolve();
          })
          .catch((err) => {
            console.error("Error during user delete!", err);
            openAlert("error", "Impossibile eliminare l'utenza");
            reject();
          });
      });
    });

  return (
    <MaterialTable
      columns={COLUMNS}
      data={_users}
      icons={tableIcons}
      isLoading={_users === undefined}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        ...(scopes.indexOf("user:update") >= 0
          ? { onRowUpdate: _onRowUpdate }
          : {}),
        ...(scopes.indexOf("user:delete") >= 0
          ? { onRowDelete: _onRowDelete }
          : {}),
      }}
    />
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.users, nextProps.users);
});
