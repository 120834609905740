import React, { useCallback, useEffect, useState } from "react";
import Filters from "./Filters";
import FiltersPreview from "./FiltersPreview";

const getDefaultFilters = (columns) => {
  return columns.reduce(
    (acc, { defaultValue, key, title }) =>
      defaultValue ? [...acc, { value: defaultValue, key, title }] : acc,
    []
  );
};

const Kpi = ({ data, kpiData }) => {
  const [filters, setFilters] = useState(
    getDefaultFilters(kpiData.filterableColumns)
  );
  const [value, setValue] = useState(null);
  const [percentage, setPercetage] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);

  const clearFilters = useCallback(() => {
    setFilters(getDefaultFilters(kpiData.filterableColumns));
  }, []);

  useEffect(() => {
    if (data && kpiData?.getKpiValue) {
      let filteredData = data;

      if (filters.length) {
        const keysToCheck = [...new Set(filters.map((f) => f.key))];
        const keysToCheckValues = keysToCheck.map((key) =>
          filters.reduce(
            (acc, f) => (f.key === key ? [...acc, f.value] : acc),
            []
          )
        );
        filteredData = data.filter((row) => {
          return keysToCheck.reduce(
            (acc, key, index) =>
              !acc || keysToCheckValues[index].indexOf(row[key]) < 0
                ? false
                : acc,
            true
          );
        });
      }

      setValue(kpiData.getKpiValue(filteredData));

      if (kpiData?.percentage) {
        setPercetage(kpiData.percentage(filteredData));
      }
    }
  }, [filters, data, kpiData]);

  if (!data || !kpiData || !kpiData.getKpiValue) {
    return null;
  }

  const { title, description, valueTitle, filterableColumns, id } = kpiData;

  return (
    <div className="mb-4 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 flex flex-col w-full">
      <div
        className={`flex flex-col md:flex-row w-full justify-between md:items-center`}
      >
        <div className="flex flex-col mb-4 md:mb-0">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
            {title}
          </h2>
          <p className="mt-2 text-base text-gray-500">{description}</p>
          {filterableColumns?.length ? (
            <p
              onClick={() => setFilterVisible(!filterVisible)}
              className="text-blue-600 hover:text-blue-500 cursor-pointer text-sm"
            >
              {!filterVisible ? "Mostra filtri" : "Nascondi filtri"}
            </p>
          ) : null}
        </div>
        <div className="flex flex-col text-left md:text-right">
          <dt className="text-sm text-lg  font-medium text-gray-500 truncate">
            {valueTitle}
          </dt>
          <dd className="mt-1 text-5xl font-semibold text-am-600">{value}</dd>
          {percentage ? (
            <p className="text-gray-900 font-semibold text-base">
              {percentage}
            </p>
          ) : null}
        </div>
      </div>
      {filterableColumns?.length && filterVisible ? (
        <div
          className={`flex flex-col-reverse md:flex-row w-full justify-between mt-6`}
        >
          <Filters
            id={id}
            clearFilters={clearFilters}
            filters={filters}
            onChange={(filterValue, selectType) => {
              // remove and readd filter
              if (selectType === "select") {
                setFilters([
                  ...filters.filter((f) => f.key !== filterValue.key),
                  filterValue,
                ]);
                return;
              }

              // check if value is already in filter
              const remove = filters.find((f) => {
                return (
                  f.value === filterValue.value && f.key === filterValue.key
                );
              });

              if (remove) {
                setFilters([
                  ...filters.filter((f) => {
                    return !(
                      f.value === filterValue.value && f.key === filterValue.key
                    );
                  }),
                ]);
                return;
              }

              // push value in filter
              setFilters([...filters, filterValue]);
            }}
            filterableColumns={filterableColumns}
            data={data}
          />
          <FiltersPreview
            filters={filters}
            filterableColumns={filterableColumns}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Kpi;
