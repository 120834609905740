import { PageHeading } from "../../../ui-components/Container";
import LotListTable from "../Components/LotListTable";
import ArrowBack from "@material-ui/icons/ArrowBack";
import AccessTime from "@material-ui/icons/AccessTime";
import Settings from "@material-ui/icons/Settings";
import LotDetailPage from "./LotDetailPage";
import { useSelector, useDispatch } from "react-redux";
import { setDetailRun } from "../../../store/runSlice";
import moment from "moment";

export default function RunDetailPage() {
  const dispatch = useDispatch();
  const detailLotOpen = useSelector((state) => state.run.detailLotOpen);
  const currentRunData = useSelector((state) => state.run.currentRunData);

  return (
    <>
      {detailLotOpen ? (
        <LotDetailPage />
      ) : (
        <>
          <div className="flex flex-row items-center gap-5">
            <ArrowBack
              className="cursor-pointer mb-1"
              onClick={() => dispatch(setDetailRun(false))}
            />
            <PageHeading title="Dettaglio run di ottimizzazione" />
          </div>
          <div className="flex flex-row items-center mt-5">
            <AccessTime />
            <div className="ml-4">
              Run effettuata il {moment.utc(currentRunData.date).format("DD/MM/YYYY")}{" "}
              alle {moment.utc(currentRunData.date).format("HH:mm:ss")}
            </div>
          </div>
          <div className="flex flex-row items-center mt-2">
            <Settings />
            <div className="ml-4">Configurazione: <span className="font-bold">{currentRunData.name}</span></div>
          </div>
          <div className="mt-16 mb-4">
            <LotListTable />
          </div>
        </>
      )}
    </>
  );
}
