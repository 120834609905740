import {useCallback, useContext, useEffect, useState} from "react";
import ApiService from "../services/ApiService";
import {NotificationsContext} from "../ui-components/Notifications";
import {createFilterParams, createOrderingParams} from "../utils/pagination";
import moment from "moment";

const useSolicitsHistory = (target) => {

  // hooks
  const { push } = useContext(NotificationsContext);

  // state
  const [downloading, setDownloading] = useState(false);

  // hooks
  useEffect(() => {
    setDownloading(false);
  }, [target]);  // eslint-disable-line no-unused-expressions

  const baseFetch = (query, url, pagination, callback, resultParser) =>
    new Promise(async (resolve, reject) => {
      const filters = createFilterParams(query);
      const ordering = createOrderingParams(query);
      const params = new URLSearchParams({
        ...(pagination
          ? {
            ...({offset: query.page * query.pageSize}),
            ...({limit: query.pageSize}),
          }
          : {}),
        ...(filters && {filters}),
        ...(ordering && {ordering})
      });

      setDownloading(true);
      try {
        const result = await callback(url, '?' + params.toString());
        resolve(resultParser(result));
      } catch (e) {
        push({type: 'error', title: 'Errore durante il caricamento', text: 'Elenco inibizioni'});
        reject();
      } finally {
        setDownloading(false);
      }
    });

  const fetch = useCallback((query) =>
    baseFetch(
      query,
      `control-panel/inhibitions/${target}`,
      true,
      (...params) => ApiService.getApi(...params),
      ({items, total}) => ({data: items, page: query.page, totalCount: total})
    ),
    [target],  // eslint-disable-line no-unused-expressions
  );
  const exportCsv = useCallback((query) =>
    baseFetch(
      query,
      `control-panel/inhibitions/${target}/export`,
      false,
      (...params) => ApiService.getApiBlog(...params),
      (x) => x,  // blob passthrough
    )
      .then(
        (response) => {
          setDownloading(true);

          try {
            // create file link in browser's memory
            const href = URL.createObjectURL(response);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `inibizioni-${target}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
          finally {
            setDownloading(false);
          }
        }
      ),
    [target],  // eslint-disable-line no-unused-expressions
  );

  const getTableIdFields = () => {
    let specificFields;
    switch (target) {
      case 'fornitura':
        specificFields = ['id_fornitura'];
        break;
      case 'cliente':
        specificFields = ['id_cliente'];
        break;
      default:
        throw Error(`Unhandled target "${target}" - aborting`);
    }
    return [...specificFields, 'data_inizio_inibizione', 'data_fine_inibizione'];
  }
  const fixDate = (x) => x instanceof Date
    ? moment(x).format('YYYY-MM-DD')
    : x;
  const getRowId = (row) => getTableIdFields().reduce(
    (acc, curr) => ({...acc, [curr]: fixDate(row[curr])}),
    {},
  );
  const extendRowDataWithId = (row, old=null) => ({
    ...Object.entries(row)
      .filter(([key]) => key !== 'activity_type')
      .reduce(
        (acc, [key, value]) => ({...acc, [key]: fixDate(value)}),
        {},
      ),
    id: getRowId(old ?? row),
  })
  const getErrorDetail = (e) => {
    switch (e.response?.status) {
      case 422: return "Dati mancanti";
      case 409: return "Inibizione già esistente per questa entità nel periodo selezionato";
      case 404: return "Impossibile identificare l'entità selezionata";
      default: return null;
    }
  }

  const rowEditFns = {
    onRowAdd: (newData) =>
      new Promise(async (resolve, reject) => {
        try {
          await ApiService.postApiJson(`control-panel/inhibitions/${target}`, extendRowDataWithId(newData));
          push({type: "success", title: "Inibizione inserita correttamente"});
          resolve();
        } catch (e) {
          push({type: "error", title: "Si è verificato un errore", text: getErrorDetail(e)});
          reject();
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        // check if row content is equal - if so, don't do anything
        let isEqual = true;
        Object.entries(newData).forEach(([key, value]) => {
          if (oldData[key] !== value) {
            isEqual = false;
          }
        });
        setImmediate(async () => {
          if (isEqual) {
            resolve();
          } else {
            try {
              await ApiService.putApiJson(`control-panel/inhibitions/${target}/`, extendRowDataWithId(newData, oldData));
              push({type: "success", title: "Inibizione aggiornata con successo"});
              resolve();
            } catch (e) {
              push({type: "error", title: "Si è verificato un errore", text: getErrorDetail(e)});
              reject();
            }
          }
        });
      }),
    onRowDelete: (rowData) =>
      new Promise((resolve, reject) => {
        setImmediate(async () => {
          try {
            await ApiService.deleteApi(`control-panel/inhibitions/${target}`, getRowId(rowData));
            push({type: "success", title: "Inibizione eliminata correttamente"});
            resolve();
          } catch (e) {
            push({type: "error", title: "Si è verificato un errore", text: getErrorDetail(e)});
            reject();
          }
        })
      }),
  }

  return { fetch, exportCsv, rowEditFns, downloading };
}

export default useSolicitsHistory;