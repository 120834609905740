import React, {useContext, useMemo, useState} from "react";
import { NotificationsContext } from "../../../ui-components/Notifications";
import ApiService from "../../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshConfigurationData } from "../../../store/configurationSlice";
import useListOptimizerDataBlocksPreview from "../ConfigureOptimizer/Hooks/useListOptimizerDataBlocksPreview";

// UI
import MaterialTable from "material-table";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./ListOptimizerTableParams";
import { tableIcons } from "../../../ui-components/TableIcons";


const ListOptimizerTable = () => {

  const [blockPreviews, setBlockPreviews] = useState({});

  const ref = React.useRef(null);
  const { push } = useContext(NotificationsContext);
  const dispatch = useDispatch();
  const { fetchBlocksPreview } = useListOptimizerDataBlocksPreview(blockPreviews, setBlockPreviews);

  const configurationData = useSelector(
    (state) => state.configuration.configurationData
  );
  const refreshConfigurationData = useSelector(
    (state) => state.configuration.refreshConfigurationData
  );

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(async () => {
        if (isEqual) {
          resolve();
        } else {
          await ApiService.putApiJson(
            `optimization/configuration/${oldData.id}/`,
            {
              des_name: newData.configuration_name,
              flg_primary: newData.attivo,
            }
          )
            .then(() => {
              dispatch(setRefreshConfigurationData(!refreshConfigurationData));
              push({ title: "Riga aggiornata con successo", type: "success" });
              resolve();
            })
            .catch(() => {
              push({
                title: "Errore durante l'aggiornamento della riga",
                type: "error",
              });
              reject();
            });
        }
      });
    });

  return useMemo(() => {
    return <MaterialTable
      style={{
        backgroundColor: "#f5f5f5",
        boxShadow: "none",
        borderRadius: 20,
        border: "none",
      }}
      tableRef={ref}
      columns={COLUMNS}
      data={configurationData?.map((item) => ({...item})) ?? []}
      isLoading={!configurationData}
      icons={tableIcons}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        onRowUpdate: _onRowUpdate,
      }}
      onRowClick={(event, {id}, togglePanel) => {
        fetchBlocksPreview(id).then();
        togglePanel();
      }}
    />
  }, [configurationData, blockPreviews])
}


export default ListOptimizerTable;