export const getColumns = (lkp_ragione) => {
  return [
    {
      title: "Id Cliente",
      field: "id_cliente",
      headerStyle: { whiteSpace: "nowrap" },
    },
    {
      title: "Id Fornitura",
      field: "id_fornitura",
      headerStyle: { whiteSpace: "nowrap" },
    },
    {
      title: "Id Fornitura Terr",
      field: "id_fornitura_terr",
      headerStyle: { whiteSpace: "nowrap" },
    },
    {
      title: "Recapito",
      field: "recapito",
    },
    {
      title: "Ragione",
      field: "ragione",
      lookup: lkp_ragione,
      cellStyle: { minWidth: 350 },
      headerStyle: { minWidth: 350 },
    },
    {
      title: "Credito aperto",
      field: "con_credito_aperto",
      type: "boolean",
    },
  ];
};

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  pageSize: 5,
  sorting: true,
  filterCellStyle: {
    maxWidth: 400,
  },
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} utenze",
    labelRowsSelect: "Utenze",
  },
};
