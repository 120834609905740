import React from "react";
import Spinner from "./Spinner";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white hover:bg-gray-50",
  secondary: "text-am-700 bg-indigo-100 hover:bg-indigo-200",
  danger: "text-white bg-red-600 hover:bg-red-700",
  disabled: "text-white bg-gray-300",
};

const Button = ({
  full,
  className = "",
  children,
  styleType = "default",
  submitting = false,
  disabled = false,
  textCls = 'text-base',
  paddingCls = 'px-4 py-2',
  ...rest
}) => {
  let defaultClasses =
    `relative justify-center inline-flex items-center ${paddingCls} border border-transparent ${textCls} font-medium rounded-md shadow-sm focus:outline-none active:opacity-70 `;

  if (buttonStyles[styleType]) {
    defaultClasses += buttonStyles[styleType];
  } else {
    defaultClasses += buttonStyles.default;
  }

  return (
    <button
      disabled={submitting || disabled}
      className={`${defaultClasses} ${className} ${full ? "w-full" : ""} ${disabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}`}
      {...rest}
    >
      {children}
      {submitting ? (
        <Spinner classes={buttonStyles[styleType]} styleType={styleType} />
      ) : null}
    </button>
  );
};

export default Button;
