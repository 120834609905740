import React, { useState, useEffect } from "react";
import _ from "lodash";

// Services
import ApiService from "../../../services/ApiService";
import UsersTable from "./UsersTable";
import SimpleAlert from "../../../components/Alert/SimpleAlert";
import AddUserDialog from "./AddUserDialog";

// UI
import { LinearProgress } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { PageHeading } from "../../Container";

import Button from "../../Button";
import { UserAddIcon } from "@heroicons/react/solid";
import AuthenticationService from "../../../services/AuthenticationService";
import ErrorBoundary from "../../ErrorBoundary";

function Users() {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });
  const openAlert = (type, message) => {
    setAlert({ open: true, type, message });
  };
  const toggleAlert = () => {
    setAlert({ ...alert, open: !alert.open });
  };

  useEffect(() => {
    const fetchUsers = () => {
      setLoading(true);
      ApiService.getApi("users")
        .then((users) => {
          setUsers(
            users.reduce(
              (usersObj, user) => _.set(usersObj, [user.id], user),
              {}
            )
          );
        })
        .catch((err) => {
          console.error("Error when fetching Users:", err);
          openAlert("error", "Errore imprevisto");
        })
        .finally(() => setLoading(false));
    };

    if (!users) {
      fetchUsers();
    }
  }, [users]);

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);
  const canCreateUser = scopes.indexOf("user:create") >= 0;

  return (
    <>
      <LinearProgress style={{ visibility: loading ? "visible" : "hidden" }} />

      <PageHeading
        title="Gestione utenze"
        contentActions={
          canCreateUser ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              onClick={toggleNewUserDialog}
            >
              <UserAddIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />{" "}
              Aggiungi
            </Button>
          ) : null
        }
      />

      <ErrorBoundary from="users-table">
        <UsersTable users={users} setUser={setUsers} openAlert={openAlert} />
      </ErrorBoundary>
      {canCreateUser ? (
        <ErrorBoundary from="users-add-dialog">
          <AddUserDialog
            open={showNewUserDialog}
            toggleOpen={toggleNewUserDialog}
            users={users}
            setUsers={setUsers}
          />
        </ErrorBoundary>
      ) : null}
      <SimpleAlert alert={alert} toggleAlert={toggleAlert} />
    </>
  );
}

export default Users;
