import React, { useLayoutEffect } from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { setChartColor } from "./commons";

function PaymentTreeChart({ id, data }) {
  useLayoutEffect(() => {
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    // Add series
    let series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        singleBranchOnly: false,
        downDepth: 1,
        initialDepth: 3,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        centerStrength: 0.5,
      })
    );

    setChartColor(series);

    series.labels.template.setAll({
      fontSize: 12,
      text: "{category} \n [font-size: 6px][bold]{sum}[/] \n {perc}%[/]",
    });

    series.nodes.template.set(
      "tooltipText",
      "[bold]{category}[/] \n \n " +
        "Invii: [bold]{sum}[/] ({perc}%) \n " +
        "Importo: [bold]{importo}€[/] ({perc_importo}%) \n \n" +
        "Sul totale: \n [bold]# [/]{perc_totale}% [bold]€ [/]{perc_totale_importo}%"
    );

    series.circles.template.adapters.add("fill", function (fill, target) {
      return am5.color(target.dataItem.dataContext.color);
    });

    series.outerCircles.template.adapters.add(
      "stroke",
      function (fill, target) {
        return am5.color(target.dataItem.dataContext.color);
      }
    );

    series.links.template.adapters.add("stroke", function (fill, target) {
      return am5.color(target.dataItem.dataContext.color);
    });

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);

    // Make stuff animate on load
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, id]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{ minWidth: "400px", minHeight: "350px" }} />;
}

export default React.memo(PaymentTreeChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data);
});
