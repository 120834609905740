import React, { useContext, useEffect, useState } from "react";
import ApiService from "../services/ApiService";
import Badge from "../ui-components/Badge";
import { NotificationsContext } from "../ui-components/Notifications";
import Placeholder from "../ui-components/Placeholder";

const Container = ({ children }) => (
  <div className="flex items-center justify-flex-end">{children}</div>
);

const StatusBox = ({ children }) => (
  <div className="flex flex-col mr-8 last:mr-0">{children}</div>
);

const ApplicationStatus = () => {
  const [data, setData] = useState(null);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getApi("control-panel/status-bar");

        setData(response);
      } catch (error) {
        push({
          title: "Errore durante il caricamento dello stato dell'applicazione",
          type: "error",
        });
      }
    };

    if (!data) {
      fetchData();
    }
  }, []);

  if (!data) {
    return (
      <Container>
        <Placeholder />
      </Container>
    );
  }

  const lastSent = new Date(data.ultimo_invio);
  const nextSent = new Date(data.prossimo_invio);

  return (
    <Container>
      {!data.sistema_spento ? (
        <StatusBox>
          <p className="text-xs font-bold">Prossimo invio</p>
          <p>{nextSent.toLocaleDateString("it-IT")}</p>
        </StatusBox>
      ) : null}
      <StatusBox>
        <p className="text-xs font-bold">Ultimo invio</p>
        <p>{lastSent.toLocaleDateString("it-IT")}</p>
      </StatusBox>
      <StatusBox>
        {data.sistema_spento ? (
          <Badge text="DISATTIVATO" type="error" />
        ) : (
          <Badge text="ATTIVO" type="success" />
        )}
      </StatusBox>
    </Container>
  );
};

export default ApplicationStatus;
