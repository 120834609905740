import { PageHeading } from "../../../ui-components/Container";
import LotDetailTable from "../Components/LotDetailTable";
import ArrowBack from "@material-ui/icons/ArrowBack";
import VpnKey from "@material-ui/icons/VpnKey";
import { setDetailLot } from "../../../store/runSlice";
import { useDispatch, useSelector } from "react-redux";

export default function LotDetailPage() {
  const dispatch = useDispatch();
  const currentLotData = useSelector((state) => state.run.currentLotData);

  return (
    <>
      <div className="flex flex-row items-center gap-5">
        <ArrowBack
          className="cursor-pointer mb-1"
          onClick={() => dispatch(setDetailLot(false))}
        />
        <PageHeading title="Dettaglio lotto" />
      </div>
      <div className="flex flex-row items-center mt-5">
        <VpnKey />
        <div className="ml-4">
          Assegnatario: <span className="font-bold">{currentLotData.adr_ammagamma}</span> (Ammagamma)
          {!!currentLotData.adr_iren && <><span className="font-bold">,{" "}{currentLotData.adr_iren}</span> (Iren)</>}
        </div>
      </div>
      <div className="mt-14 mb-4">
        <LotDetailTable />
      </div>
    </>
  );
}
