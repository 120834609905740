import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import AuthHeader from "../../layout/AuthHeader";
import PoweredBy from "../../layout/PoweredBy";
import ApiService from "../../../services/ApiService";
import AuthenticationService from "../../../services/AuthenticationService";
import Alert from "../../Alert";
import Button from "../../Button";
import Container from "../../Container";
import Input from "../../Input";
import UpdatePassword from "../../forms/UpdatePassword";

const Registration = () => {
  const { token } = useParams();
  const [uid, setUid] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    ApiService.publicPostApi("users/validate", { token: token }, true)
      .then((data) => {
        setUid(data.id);
        setEmail(data.email);
      })
      .catch((err) => {
        console.error("Error:", err);
        setError("Not authorized");
      })
      .finally(() => setLoading(false));
  }, [token]);

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError("");
      ApiService.publicPostApi("users/signup", { uid: uid, password: password }, true)
        .then(() => {
          AuthenticationService.login(email, password)
            .then(() => {
              const scopes = AuthenticationService.getUserScopes();
              if (
                scopes.includes("optimization:read") &&
                !scopes.includes("frame-a:read")
              ) {
                window.location = "/run-optimizer";
              } else {
                window.location = "/home";
              }
            })
            .catch(() => {
              setError("Autenticazione fallita");
            });
        })
        .catch((err) => {
          setError("Errore durante il salvataggio");
        })
        .finally(() => setLoading(false));
    },
    [password, uid, email]
  );

  return (
    <div className="bg-gray-100 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Container>
        <AuthHeader title="Registrazione utente" />

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form action="#" method="POST" onSubmit={submit}>
              <div>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  label="Email"
                  type="email"
                  disabled
                />
              </div>

              <UpdatePassword
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                showPassword={showPassword}
              />
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-am-600 focus:ring-am-500 border-gray-300 rounded"
                    onChange={(e) => {
                      setShowPassword(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Mostra password
                  </label>
                </div>
              </div>

              <div>
                <Button full type="submit" submitting={loading}>
                  Registrati
                </Button>
              </div>
              {error ? (
                <div className="mt-4">
                  <Alert slim type="error" title={error} />
                </div>
              ) : null}
            </form>
          </div>
          <PoweredBy />
        </div>
      </Container>
    </div>
  );
};

export default Registration;
