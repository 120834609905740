import formatCurrency from "../../../utils/formatCurrency";
import formatPercentage from "../../../utils/formatPercentage";

export const COLUMNS = [
  {
    title: "ID pratica",
    field: "id",
    type: "string",
  },
  {
    title: "Codice contratto",
    field: "cod_contract",
    type: "string",
  },
  {
    title: "Volume",
    field: "val_volume",
    type: "numeric",
    render: ({val_volume}) => formatCurrency(val_volume),
  },
  {
    title: "Performance",
    field: "val_performance",
    type: "numeric",
    render: ({val_performance}) => formatPercentage(val_performance),
  },
  {
    title: "Recuperato",
    field: "val_collected",
    type: "numeric",
    render: ({val_collected}) => formatCurrency(val_collected),
  },
];

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 20,
  },
  filterCellStyle: { paddingTop: 8, maxWidth: 200 },
  cellStyle: { border: "none" },
  actions: {
    border: "none",
  },
  actionsCellStyle: { border: "none" },
  pageSize: 10,
  pageSizeOptions: [5, 10, 20],
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
};
