import React, {useRef} from "react";
import MaterialTable, {MTableToolbar} from "material-table";
import {tableIcons} from "../../ui-components/TableIcons";
import {LOCALIZATION, OPTIONS} from "./Params/InhibitionsCommons";
import {COLUMNS as clientColumns} from "./Params/ClientInhibitions";
import {COLUMNS as fornituraColumns} from "./Params/FornituraInhibitions";


const InhibitionsTable = ({title, columns, fetchDataFn, exportCsvFn, rowEditFns, downloading}) => {

  // refs
  const tableRef = useRef(null);

  // utilities
  const refresh = () => { tableRef.current?.onQueryChange(undefined); };
  return <MaterialTable
    tableRef={tableRef}
    columns={columns}
    data={fetchDataFn}
    editable={rowEditFns}
    icons={tableIcons}
    localization={LOCALIZATION}
    isLoading={downloading}
    options={OPTIONS}
    title={title}
    components={{
      Toolbar: props => (
        <div style={{backgroundColor: "#F9FAFB"}}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    actions={[
      {
        icon: tableIcons.Refresh,
        tooltip: 'Aggiorna',
        isFreeAction: true,
        onClick: refresh,
      },
      {
        icon: tableIcons.Download,
        tooltip: 'Esporta CSV',
        isFreeAction: true,
        onClick: async () => {
          const query = tableRef.current?.state?.query ?? {};
          await exportCsvFn(query);
        },
      }
    ]}
  />
}

const memoCheckerFn = (props, nextProps) =>
  props.fetchDataFn === nextProps.fetchDataFn &&
  props.downloading === nextProps.downloading;

export const FornituraInhibitionsTable = React.memo(
  ({fetchDataFn, exportCsvFn, rowEditFns, downloading}) =>
    <InhibitionsTable
      title="Inibizioni fornitura"
      columns={fornituraColumns}
      {...{fetchDataFn, exportCsvFn, rowEditFns, downloading}}
    />,
  memoCheckerFn,
);

export const ClientInhibitionsTable = React.memo(
  ({fetchDataFn, exportCsvFn, rowEditFns, downloading}) =>
    <InhibitionsTable
      title="Inibizioni cliente"
      columns={clientColumns}
      {...{fetchDataFn, exportCsvFn, rowEditFns, downloading}}
    />,
  memoCheckerFn,
);
