import { PageHeading } from "../../../ui-components/Container";
import RunOptimizerTable from "../Components/RunOptimizerTable";
import SearchSelect from "../../../ui-components/SearchSelect";
import Button from "../../../ui-components/Button";
import RunDetailPage from "./RunDetailPage";
import ApiService from "@services/ApiService";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {NotificationsContext} from "../../../ui-components/Notifications";
import useListOptimizerDataBlocksPreview from "../ConfigureOptimizer/Hooks/useListOptimizerDataBlocksPreview";
import Collapsible from "../../../ui-components/Collapsible";
import SplitDefinitionTable from "../Components/SplitDefinitionTable";
import Placeholder from "../../../ui-components/Placeholder";
import useListOptimizerData from "../ConfigureOptimizer/Hooks/useListOptimizerData";
import {CheckIcon} from "@heroicons/react/solid";
import {RefreshIcon} from "@heroicons/react/outline";
import Badge from "../../../ui-components/Badge";


export default function RunOptimizerPage() {

  // context
  const {push} = useContext(NotificationsContext);

  // state
  const [selected, setSelected] = useState(null);
  const [selectedBlocks, setSelectedBlocks] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // hooks
  useListOptimizerData(true);
  const { fetchBlocksPreview }
    = useListOptimizerDataBlocksPreview(selectedBlocks, setSelectedBlocks);

  // selectors
  const configurationData = useSelector(
    (state) => state.configuration.configurationData
  );
  const detailRunOpen = useSelector((state) => state.run.detailRunOpen);
  const activeRun = useSelector((state) => state.run.activeRun);

  // utilities
  const selectedConfiguration = configurationData?.find(({id}) => id === selected?.value);
  const configurationsOptions = [...(configurationData || [])]
    ?.sort((a, b) => b.tms_created_on - a.tms_created_on)
    ?.map(({id, des_name}) => ({value: id, label: des_name}));
  const invalidBlocksCount = Object.values(selectedBlocks?.[selected?.value] || {})
    .filter((b) => !b.valid)
    .length
  const renderPreviewOutcome = () => {
    if (invalidBlocksCount > 0)
      return (
        <span className="text-red-500">
          ({invalidBlocksCount} non validi)
        </span>
      )
    if (Object.keys(selectedBlocks).length === 0)
      return (
        <span className="text-gray-400">
          <RefreshIcon className="w-5 inline animate-spin" />
        </span>
      )
    return (
      <span className="text-green-500">
        <CheckIcon className="w-6 inline" />
      </span>)
  }

  // ref
  const lastSelectedValue = React.useRef(null);

  useEffect(() => {
    if (!configurationData)
      return;

    setSelected(configurationsOptions?.[0]);
  }, [configurationData]);

  useEffect(() => {
    if (!selected || lastSelectedValue.current === selected.value)
      return;

    lastSelectedValue.current = selected.value;
    (async () => {
      setSelectedBlocks({});
      await fetchBlocksPreview(selected.value);
    })()
  }, [selected]);

  async function handleRun() {
    setSubmitting(true)
    try {
      await ApiService.postApiJson("optimization/run", selected.value);
      push({type: 'success', title: 'Nuova run creata con successo!'});
      setRefresh(!refresh);
    }
    catch {
      push({type: 'error', title: 'Si è verificato un errore'})
    }
    finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      {detailRunOpen ? (
        <RunDetailPage />
      ) : (
        <>
          <PageHeading title="Suggerimenti di assegnazione" />
          <div className="flex flex-col gap-8 my-8">
            <div className="flex gap-8">
              <div className="flex-1">
                <SearchSelect
                  placeholder={"Seleziona configurazione"}
                  isDisabled={!selected || activeRun}
                  options={configurationsOptions}
                  value={selected}
                  onChange={(e) => {
                    setSelected(e);
                  }}
                />
              </div>
              <Button
                className="float-right h-10 flex"
                onClick={() => handleRun()}
                disabled={activeRun || !selected || invalidBlocksCount > 0}
                submitting={submitting}
              >
                Nuova Run
              </Button>
            </div>
            <div>
              <Collapsible title={<>
                <span className="mr-2">Anteprima blocchi configurazione</span>
                {renderPreviewOutcome()}
              </>}>
                {
                  Object.keys(selectedBlocks).length === 0
                    ? <Placeholder height="h-32" />
                    : (
                      <div className="flex flex-col gap-4">
                        {!!selectedConfiguration && (
                          <div className="flex gap-2 flex-wrap items-center">
                            <p className="text-xs font-bold">
                              ADR selezionate:
                            </p>
                            {selectedConfiguration.adrs.map(({des_name}) => (
                              <Badge text={des_name} />
                            ))}
                          </div>
                        )}
                        <SplitDefinitionTable
                          data={selectedBlocks[selected.value]}
                          editable={false}
                        />
                      </div>
                    )
                }
              </Collapsible>
            </div>
            <RunOptimizerTable refresh={refresh} />
          </div>
        </>
      )}
    </>
  );
}
