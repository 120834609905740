import React, { useContext } from "react";

// UI
import MaterialTable from "material-table";

// Table Params
import { COLUMNS, getOptions, LOCALIZATION } from "./SplitDefinitionTableParams";
import { tableIcons } from "../../../ui-components/TableIcons";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";
import {isBlockValid} from "../ConfigureOptimizer/utilities";

export default function SplitDefinitionTable({ data, setData, editable=true }) {
  const ref = React.useRef(null);
  const context = useContext(ConfigureOptimizerContext);

  function onSelectionChange(rows) {
    context.setBlockSelected(rows);
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          let filteredData = data.filter((item) => item.id !== newData.id);
          filteredData.push({...newData, valid: isBlockValid(newData)});
          setData(filteredData);
          resolve();
        }
      });
    });

  return (
    <>
      <MaterialTable
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          borderRadius: 20,
          border: "none",
          zIndex: 0,
        }}
        tableRef={ref}
        columns={COLUMNS}
        data={data}
        icons={tableIcons}
        options={getOptions(editable)}
        localization={LOCALIZATION}
        editable={editable && {
          onRowUpdate: _onRowUpdate,
        }}
        onSelectionChange={editable && onSelectionChange}
      />
    </>
  );
}
