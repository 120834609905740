import formatCurrency from "../../../utils/formatCurrency";
import _ from "lodash";

export const COLUMNS = [
  {
    title: 'Data sollecito',
    field: 'DATA_SOLLECITO',
    type: 'date',
    defaultSort: 'desc',
  },
  {
    title: 'ID sollecito',
    field: 'ID_SOLLECITO',
    type: 'numeric',
  },
  {
    title: 'ID cliente',
    field: 'ID_CLIENTE',
    type: 'numeric',
  },
  {
    title: 'ID fornitura',
    field: 'ID_FORNITURA',
    type: 'numeric',
  },
  {
    title: 'Contratto SAP',
    field: 'CONTRATTO_SAP',
    type: 'string',
  },
  {
    title: 'Nome',
    field: 'NOME',
    type: 'string',
  },
  {
    title: 'Cognome',
    field: 'COGNOME',
    type: 'string',
  },
  {
    title: 'Classe fornitura',
    field: 'CLASSE_FORNITURA',
    lookup: ['luce', 'gas', 'acqua', 'teleriscaldamento']
      .reduce((acc, curr) => ({...acc, [curr]: _.capitalize(curr)}), {}),
  },
  {
    title: 'Codice contratto',
    field: 'CODICE_CONTRATTO',
    type: 'string',
    operator: "==",
  },
  {
    title: 'Stato contratto',
    field: 'STATO_CONTRATTO',
    lookup: ['Attivo', 'Cessato', 'N/A'].reduce((acc, curr) => ({...acc, [curr]: curr}), {}),
  },
  {
    title: 'Indirizzo fornitura',
    field: 'INDIRIZZO_FORNITURA',
    type: 'string',
  },
  {
    title: 'Intimazione sospensione',
    field: 'INTIMAZIONE_SOSPENSIONE',
    type: 'boolean',
  },
  {
    title: 'Data sospensione',
    field: 'DATA_SOSPENSIONE',
    type: 'date',
  },
  {
    title: 'Modalità sollecito',
    field: 'MODALITA_SOLLECITO',
    lookup: ['sms', 'email'].reduce((acc, curr) => ({...acc, [curr]: _.capitalize(curr)}), {}),
  },
  {
    title: 'Numero telefono',
    field: 'NUMERO_TELEFONO',
    type: 'string',
  },
  {
    title: 'Email',
    field: 'EMAIL',
    type: 'string',
  },
  {
    title: 'Severità sollecito',
    field: 'SEVERITA_SOLLECITO',
    lookup: ['soft', 'hard'].reduce((acc, curr) => ({...acc, [curr]: _.capitalize(curr)}), {}),
  },
  {
    title: 'ID fattura',
    field: 'ID_FATTURA',
    type: 'numeric',
  },
  {
    title: 'Importo fattura',
    field: 'IMPORTO_FATTURA',
    type: 'currency',
    render: ({IMPORTO_FATTURA: field}) => formatCurrency(field),
  },
  {
    title: 'Importo residuo da pagare fattura',
    field: 'IMPORTO_RES_DA_PAGARE_FATTURA',
    type: 'currency',
    render: ({IMPORTO_RES_DA_PAGARE_FATTURA: field}) => formatCurrency(field),
  },
  {
    title: 'Data emissione fattura',
    field: 'DATA_EMISSIONE_FATTURA',
    type: 'date',
  },
  {
    title: 'Data scadenza fattura',
    field: 'DATA_SCADENZA_FATTURA',
    type: 'date',
  },
  {
    title: 'In scadenza',
    field: 'IN_SCADENZA_FATTURA',
    type: 'boolean',
  },
  {
    title: 'Importo totale',
    field: 'IMPORTO_TOTALE',
    type: 'currency',
    render: ({IMPORTO_TOTALE: field}) => formatCurrency(field),
  },
  {
    title: 'Importo totale residuo da pagare',
    field: 'IMPORTO_TOTALE_RES_DA_PAGARE',
    type: 'currency',
    render: ({IMPORTO_TOTALE_RES_DA_PAGARE: field}) => formatCurrency(field),
  },
  {
    title: 'Formato sollecito',
    field: 'FORMATO_SOLLECITO',
    lookup: ['pweb', 'ptext', 'pvideo'].reduce((acc, curr) => ({...acc, [curr]: curr.toUpperCase()}), {}),
  },
  {
    title: 'Numero fattura',
    field: 'NUM_FATTURA',
    type: 'string',
  },
  {
    title: 'Città fornitura',
    field: 'CITTA_FORNITURA',
    type: 'string',
  },
  {
    title: 'ID fattura terr',
    field: 'ID_FATTURA_TERR',
    type: 'string',
    operator: "==",
  },
  {
    title: 'ID fornitura terr',
    field: 'ID_FORNITURA_TERR',
    type: 'string',
    operator: "==",
  },
  {
    title: 'ID sistema provenienza',
    field: 'ID_SISTEMA_PROVENIENZA',
    type: 'numeric',
  },
  {
    title: 'Numero fatture',
    field: 'NUMERO_FATTURE',
    type: 'numeric',
  },
].map((obj) => ({
  ...obj,
  headerStyle: {minWidth: 200}
}));

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  pageSize: 5,
  sorting: true,
  filterCellStyle: {
    maxWidth: 400,
  },
  headerStyle: {
    backgroundColor: '#F9FAFB',
    fontWeight: 'bold',
    zIndex: 5,
  },
  debounceInterval: 500,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} solleciti",
    labelRowsSelect: "solleciti",
  },
};
