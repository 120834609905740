import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

const StyledValueLabel = withStyles({
  offset: {
    top: -28,
    left: (props) =>
      props.index === 0 ? "calc(-50% + -20px)" : "calc(-50% + 12px)",
  },
  circle: {
    transform: (props) =>
      props.index === 0 ? "rotate(-90deg)" : "rotate(0deg)",
  },
  label: {
    transform: (props) =>
      props.index === 0 ? "rotate(90deg)" : "rotate(0deg)",
    fontSize: 9,
    fontWeight: 800,
  },
})(ValueLabel);

const SLIDER = withStyles({
  root: {
    color: "#003646",
    height: 2,
    paddingBottom: 6,
    margin: 0,
  },
  track: {
    height: 4,
  },
  thumb: {
    background: "transparent",
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  rail: {
    height: 4,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
  },
})(Slider);

const MULTIPLESLIDER = withStyles({
  root: {
    color: "#003646",
    height: 10,
    paddingBottom: 6,
    margin: 0,
  },
  thumb: {
    height: 4,
    width: 4,
  },
})(Slider);

export function MultipleCustomSlider({
  sliderContent,
  setSliderContent,
  min,
  max,
  step,
  marks,
}) {
  return (
    <MULTIPLESLIDER
      track={false}
      style={{ backgroundColor: "transparent" }}
      valueLabelDisplay="on"
      value={sliderContent}
      onChange={(e, newValue) => {
        const actualValues = newValue.map((v) => {
          if ([min, max].includes(v))
            return v;
          return Math.round(v / step) * step;
        });
        setSliderContent(actualValues);
      }}
      min={min || min === 0 ? min : 50}
      max={max || max === 0 ? max : 2000}
      step={step ? step : 50}
      marks={marks ? marks : []}
      ValueLabelComponent={StyledValueLabel}
    />
  );
}

export const CustomSlider = ({
  sliderContent,
  setSliderContent,
  min,
  max,
  step,
  marks,
}) => (
  <SLIDER
    style={{ backgroundColor: "transparent" }}
    valueLabelDisplay="on"
    value={sliderContent}
    onChange={(e, newValue) => {
      const actualValues = newValue.map((v) => {
        if ([min, max].includes(v))
          return v;
        return Math.round(v / step) * step;
      });
      setSliderContent(actualValues);
    }}
    min={min !== null && min !== undefined ? min : 50}
    max={max !== null && max !== undefined ? max : 2000}
    step={step ? step : 50}
    marks={marks ? marks : []}
    ValueLabelComponent={StyledValueLabel}
  />
);


export default CustomSlider;