import "./App.css";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
// import filterRoutesByScopes from "./utils/filterRoutesByScopes";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AuthenticationService from "./services/AuthenticationService";

function App() {
  const scopes = AuthenticationService.getUserScopes();
  function redirectPage() {
    if (
      scopes.includes("optimization:read") &&
      !scopes.includes("frame-a:read")
    ) {
      return "/run-optimizer";
    } else {
      return "/home";
    }
  }
  const redirectUrl = redirectPage();
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Notifications>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={() => (
                <Redirect to={{ pathname: redirectUrl }} />
              )}
            />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route exact path="/registration/:token" component={Registration} />

            <PrivateRoute
              path={[
                // ...filterRoutesByScopes(menu)
                ...menu
                  .reduce(
                    (acc, route) => [
                      ...acc,
                      ...(route.dropdown ? route.dropdown : [route]),
                    ],
                    []
                  )
                  .map((route) => route.href),
                "/profile",
              ]}
              component={Dashboard}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Notifications>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
