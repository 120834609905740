import React from "react";

// Service
import ApiService from "../services/ApiService";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

import { tableIcons } from "./TableIcons";
import AuthenticationService from "../services/AuthenticationService";

const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'elemento??",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    labelRowsSelect: "",
  },
};

const SimpleTable = ({ rows, setRow, openAlert, columns, apiKey, roleKey }) => {
  const scopes = AuthenticationService.getUserScopes();

  const _rows = rows && _.values(rows).map((user) => ({ ...user }));

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: tmp, ...rest } = rows;
    setRow({ ...rest, [newData.id]: newData });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          ApiService.postApiJson(`${apiKey}/${oldData.id}`, newData)
            .then(() => {
              manageUpdatedUser(oldData, newData);
              openAlert("success", "Elemento aggiornato con successo!");
              resolve();
            })
            .catch((err) => {
              console.error("Error during user update!", err);
              openAlert("error", "Impossibile aggiornare l'elemento");
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        ApiService.deleteApi(
          `${apiKey}/${encodeURIComponent(newData.id)}`,
          newData
        )
          .then(() => {
            const { [newData.id]: tmp, ...rest } = rows;
            setRow({ ...rest });
            openAlert("success", "Elemento eliminato con successo");
            resolve();
          })
          .catch(() => {
            openAlert("error", "Impossibile eliminare l'elemento");
            reject();
          });
      });
    });

  return (
    <MaterialTable
      columns={columns}
      data={_rows}
      icons={tableIcons}
      isLoading={_rows === undefined}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        ...(scopes.indexOf(`${roleKey}:update`) >= 0
          ? { onRowUpdate: _onRowUpdate }
          : {}),
        ...(scopes.indexOf(`${roleKey}:delete`) >= 0
          ? { onRowDelete: _onRowDelete }
          : {}),
      }}
    />
  );
};

export default React.memo(SimpleTable, (props, nextProps) => {
  // Render component only when rows changes
  return _.isEqual(props.rows, nextProps.rows);
});
