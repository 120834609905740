import React from "react";
import { withRouter } from "react-router-dom";
import ApiService from "../services/ApiService";
import ErrorPage from "./pages/ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  componentDidCatch = (error, info) => {
    this.setState({ hasError: true, error, info });
    // send api to server
    try {
      ApiService.publicPostApi("client-error", {
        stack: info,
        error,
        from: this.props.from || "main",
        path: `${window.location.pathname}${window.location.search}`,
        agent:
          window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : "",
      });
    } catch (error) {
      // error client error
    }
  };

  render() {
    const { children, component, hide } = this.props;

    const { hasError } = this.state;

    if (hasError) {
      if (component) {
        return component;
      }
      if (hide) {
        return null;
      }

      return <ErrorPage />;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
