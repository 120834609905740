export const getMonthsFromDate = (startDate) => {
  const months = [];

  const now = new Date();
  const end = new Date(now.getFullYear(), now.getMonth(), 1);
  const start = new Date(startDate);

  for (let i = 0; start <= end; i++) {
    const year = start.getFullYear();
    const month = start.getMonth() + 1;  // Months are zero-based, so we add 1
    const formattedMonth = `${year}-M${month < 10 ? '0' + month : month}`;
    months.push(formattedMonth);
    start.setMonth(start.getMonth() + 1);  // Move to the next month
  }

  return months;
}