import React, { useLayoutEffect } from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  getXYChart,
  addCursor,
  getRenderAxisX,
  getRenderAxisY,
  getTooltip,
  addSeriesBullet,
} from "./commons";
import AlertAnalyticsEmptyData from "../AlertAnalyticsEmptyData";

function NotificationStackedAreaChart({ id, data }) {
  useLayoutEffect(() => {
    if (!data?.length)
      return;

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    root.dateFormatter.set("dateFormat", "dd/MM/yyyy");

    // Create chart
    let chart = getXYChart(root, { layout: root.verticalLayout });
    addCursor(root, chart);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "week", count: 1 },
        renderer: getRenderAxisX(root, false),
      })
    );
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: getRenderAxisY(root),
      })
    );

    xAxis.data.setAll(data);

    // Add series
    function createSeries(field, name) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: true,
          connect: false,
          valueYField: field,
          valueXField: "data",
          tooltip: getTooltip(
            root,
            "[bold]{name}[/] \n {data.formatDate()}: {valueY}"
          ),
        })
      );

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      // Add bullet
      addSeriesBullet(root, series, 3, 0);

      series.data.processor = am5.DataProcessor.new(root, {
        numericFields: [field],
        dateFields: ["data"],
        dateFormat: "yyyy-MM-dd",
      });
      series.data.setAll(data);
      series.appear(1000);

      legend.data.push(series);
    }

    createSeries("num_invii_scaduto", "Scaduto");
    createSeries("num_invii_scadere", "A scadere");

    return () => {
      root.dispose();
    };
  }, [data, id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data?.length)
    return <AlertAnalyticsEmptyData />;

  return <div id={id} style={{ minWidth: "500px", minHeight: "400px" }} />;
}

export default React.memo(NotificationStackedAreaChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data);
});
