export const openFiltreableColumns = [
  { key: "scadere_scaduto", title: "Scaduto/Scadere" },
  { key: "classe_fornitura", title: "Classe fornitura" },
  { key: "classe_cliente", title: "Classe cliente" },
  { key: "aging_bin", title: "Aging bin" },
  { key: "stato_contratto", title: "Stato contratto" },
];

export const defaultFilters = [];

export const getFilteredData = (data, filters) => {
  const days = [...new Set(data.map((d) => d.dat_history))];
  const filtersKeys = [...new Set(filters.map((f) => f.key))].map((k) => {
    const values = filters
      .filter((f) => f.key === k)
      .reduce((acc, f) => [...acc, f.value], []);

    return { key: k, values };
  });

  const reducedData = filters.length
    ? filtersKeys.reduce((acc, filter) => {
        if (!acc.length) {
          return data.filter(
            (row) => filter.values.indexOf(row[filter.key]) >= 0
          );
        }

        return acc.filter((row) => filter.values.indexOf(row[filter.key]) >= 0);
      }, [])
    : data;

  return days.map((dat_history) => {
    const onlyDayData = reducedData.filter(
      (d) => d.dat_history === dat_history
    );
    const numFatture = onlyDayData.reduce((acc, d) => acc + d.n_fatture, 0);
    return {
      dat_history,
      val_to_pay: onlyDayData.reduce((acc, d) => acc + d.val_to_pay, 0),
      val_aging: numFatture > 0 ? onlyDayData.reduce((acc, d) => acc + d.val_aging, 0) / numFatture : 0,
    };
  })
};
