import React from "react";

const Logo = ({ height = "h-10", width = "w-auto" }) => (
  <div className="flex-shrink-0 flex items-center px-4">
    <img
      className={`${height} ${width}`}
      src={process.env.PUBLIC_URL + "/logo.png"}
      alt="ammagamma logo"
    />
  </div>
);

export default Logo;
