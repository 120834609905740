export const COLUMNS = [
  {
    title: "Nome",
    field: "des_name",
    type: "string",
  },
  {
    title: "Domain",
    field: "des_domain",
    type: "string",
  },
];

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  paging: true,
  sorting: true,
  selection: true,
  headerStyle: {
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 20,
  },
  filterCellStyle: { paddingTop: 8, maxWidth: 200 },
  cellStyle: { border: "none" },
  actions: {
    border: "none",
  },
  actionsCellStyle: { border: "none" },
  pageSize: 20,
  pageSizeOptions: [5, 10, 20],
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
};
