export function getFilterValue(f) {
  //if (f.column.lookup) {
    // for the case of partial matches on arrays
    //return f.value.map((v) => `%${v}%`);
  //}
  switch (f.column.type) {
    case "string":
      if (f.column.operator === "==")
        return f.value;
      return `%${f.value}%`;
    case "boolean":
      return f.value === 'checked' ? true : false;
    case "date":
      return f.value.toISOString().split('T')[0];
    default:
      return f.value
  }
}

export function getFilterOperator(f) {
  if (f.column.lookup) {
    // substitute with "ilike any" if you want to search for multiple values
    // uncomment also rows 17:20
    return "in";
  }
  switch (f.column.type) {
    case "string":
      if (f.column.operator === "==")
        return "=";
      return "ilike";
    case "boolean":
      return "bool";
    default:
      return f.operator
  }
}

export function createFilterParams(query) {
  if (!query) {
    return "";
  }
  const filters = query.filters.filter((q) => {
    if (Array.isArray(q.value)) {
      return q.value.length > 0
    }
    return true
  }).map((f) => ({
    value: getFilterValue(f),
    operator: getFilterOperator(f),
    column: f.column.field,
  }))
  return filters.length ? JSON.stringify(filters) : '';
}

export function createOrderingParams(query) {
  if (!query?.orderBy) {
    return "";
  }
  const order = {
    orderDirection: query.orderDirection,
    column: query.orderBy.field,
  };
  return JSON.stringify(order);
}
