import { useContext } from "react";
import Button from "../../../ui-components/Button";
import { PageHeading } from "../../../ui-components/Container";
import ListOptimizerTable from "../Components/ListOptimizerTable";
import useListOptimizerData from "./Hooks/useListOptimizerData";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";
import {PlusIcon} from "@heroicons/react/solid";

function ListOptimizerPage() {
  useListOptimizerData();

  const context = useContext(ConfigureOptimizerContext);

  return (
    <>
      <PageHeading title="Lista configurazioni" />
      <div className="pt-4 bg-red flex items-end justify-end mb-4">
        <Button
          onClick={() => context.toggleConfigurePage()}
        >
          <PlusIcon className="w-4 mr-2" />
          <span>Aggiungi</span>
        </Button>
      </div>
      <div className="pt-4">
        <ListOptimizerTable />
      </div>
    </>
  );
}

export default ListOptimizerPage;
