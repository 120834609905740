import React, { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import Label from "./Label";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validator = (type, value) => {
  switch (type) {
    case "email":
      return validateEmail(value);

    default:
      return true;
  }
};

const Input = ({
  label,
  name,
  type = "text",
  validation,
  required,
  errorMessage,
  ...rest
}) => {
  const [valid, setValid] = useState(true);
  return (
    <div className="">
      {label ? <Label>{label}</Label> : null}
      <div className=" relative rounded-md shadow-sm">
        <input
          onFocus={() => {
            setValid(true);
          }}
          onBlur={(e) => {
            if (required) {
              if (!e.target.value) {
                setValid(false);
                return;
              }

              if (validation) {
                setValid(validation(e.target.value));
                return;
              }

              setValid(validator(type, e.target.value));
            }
          }}
          type={type}
          name={type}
          className="px-2 py-2 border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
          {...rest}
        />
        {!valid ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {!valid && errorMessage ? (
        <p className="mt-1 text-xs text-red-600" id="email-error">
          {errorMessage}
        </p>
      ) : (
        <div className="" />
      )}
    </div>
  );
};

export default Input;
