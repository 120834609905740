import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detailRunOpen: false,
  detailLotOpen: false,
  currentRunId: null,
  currentLotId: null,
  activeRun: false,
  currentRunData: {},
  currentLotData: {},
};

export const runSlice = createSlice({
  name: "run",
  initialState,
  reducers: {
    setDetailRun: (state, action) => {
      state.detailRunOpen = action.payload;
    },
    setDetailLot: (state, action) => {
      state.detailLotOpen = action.payload;
    },
    setCurrentRunId: (state, action) => {
      state.currentRunId = action.payload;
    },
    setCurrentLotId: (state, action) => {
      state.currentLotId = action.payload;
    },
    setActiveRun: (state, action) => {
      state.activeRun = action.payload;
    },
    setCurrentRun: (state, action) => {
      state.currentRunData = action.payload;
    },
    setCurrentLot: (state, action) => {
      state.currentLotData = action.payload;
    },
  },
});

export const {
  setDetailRun,
  setDetailLot,
  setCurrentRunId,
  setCurrentLotId,
  setActiveRun,
  setCurrentRun,
  setCurrentLot,
} = runSlice.actions;

export default runSlice.reducer;
