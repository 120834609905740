import React, { useContext } from "react";

// UI
import MaterialTable from "material-table";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./ADRTableParams";
import { tableIcons } from "../../../ui-components/TableIcons";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";

export default function ADRTable() {
  const ref = React.useRef(null);
  const context = useContext(ConfigureOptimizerContext);

  function onSelectionChange(rows) {
    context.setADRSelectedList(rows);
  }

  return (
    <>
      <MaterialTable
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          borderRadius: 20,
          border: "none",
        }}
        tableRef={ref}
        columns={COLUMNS}
        data={context.adrList}
        icons={tableIcons}
        options={OPTIONS}
        localization={LOCALIZATION}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
}
