import React, { useEffect, useState } from "react";
import Kpi from "../../components/Kpi";
import ApiService from "../../services/ApiService";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import Placeholder from "../../ui-components/Placeholder";

const KpiList = ({ list, apiUrl, loadedData }) => {
  const [data, setData] = useState(loadedData || null);
  const [error, setError] = useState(false);

  useEffect(() => {
    let cancel = false;
    const fetch = async () => {
      try {
        const response = await ApiService.getApi(apiUrl);
        if (!cancel) {
          setData(response);
        }
      } catch (error) {
        if (!cancel) {
          setError(error);
        }
      }
    };

    if (apiUrl) {
      fetch();
    }

    return () => {
      cancel = true;
    };
  }, []);

  if (error) {
    throw error;
  }

  if (!data) {
    return (
      <div className="flex flex-col w-full mb-4">
        <Placeholder height="h-60" classNames="mb-4 rounded-lg" />
        <Placeholder height="h-60" classNames="rounded-lg" />
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      {list.map((kpi) => (
        <ErrorBoundary key={kpi.id} from={kpi.id} hide>
          <Kpi data={data} kpiData={kpi} />
        </ErrorBoundary>
      ))}
    </div>
  );
};

export default KpiList;
