import React from "react";

export const COLUMNS = [
  {
    title: "E-mail",
    field: "email",
  },
  {
    title: "Nome",
    field: "full_name",
    render: ({ full_name }) => <b>{full_name}</b>,
  },
  {
    title: "Ruolo",
    field: "role_id",
    render: ({ role }) => <b className="capitalize">{role.name}</b>,
  },

  {
    title: "Disabilitato",
    field: "disabled",
    type: "boolean",
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'utenza?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} utenti",
    labelRowsSelect: "Utenti",
  },
};
