import React from "react";
import SliderMui from "@material-ui/core/Slider";

const Slider = ({ value, onChange, label, ...rest }) => {
  return (
    <div className="w-full">
      <SliderMui
        getAriaLabel={() => label}
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        getAriaValueText={(v) => v}
        {...rest}
      />
    </div>
  );
};

export default Slider;
