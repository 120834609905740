import React from "react";

const colors = [
  {
    regular: "bg-blue-200",
    textRegular: "text-blue-400",
    textExtraBold: "text-blue-800",
  },
  {
    regular: "bg-green-200",
    textRegular: "text-green-400",
    textExtraBold: "text-green-800",
  },
  {
    regular: "bg-gray-200",
    textRegular: "text-gray-400",
    textExtraBold: "text-gray-800",
  },
  {
    regular: "bg-red-200",
    textRegular: "text-red-400",
    textExtraBold: "text-red-800",
  },
  {
    regular: "bg-yellow-200",
    textRegular: "text-yellow-400",
    textExtraBold: "text-yellow-800",
  },

  {
    regular: "bg-indigo-200",
    textRegular: "text-indigo-400",
    textExtraBold: "text-indigo-800",
  },
  {
    regular: "bg-purple-200",
    textRegular: "text-purple-400",
    textExtraBold: "text-purple-800",
  },
  {
    regular: "bg-pink-200",
    textRegular: "text-pink-400",
    textExtraBold: "text-pink-800",
  },
];

const KpiBadge = ({ value, title, colorIndex = 0 }) => {
  const { textExtraBold, textRegular, regular } = colors[colorIndex];

  return (
    <div className="md:ml-3 md:mr-0 mr-3 mb-2 flex">
      <span
        className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${regular} ${textExtraBold} md-text-right capitalize`}
      >
        <svg
          style={{
            minWidth: "0.5rem",
            minHeight: "0.5rem",
          }}
          className={`-ml-1 mr-1.5 h-2 w-2 ${textRegular}`}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx="4" cy="4" r="3" />
        </svg>
        {`${value}`}
      </span>
    </div>
  );
};

export default KpiBadge;
