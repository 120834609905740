import React, { Fragment } from "react";

// UI
import { PageHeading } from "../../ui-components/Container";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import KpiList from "./KpiList";
import {
  openCredit,
  openCreditAging,
  solicitousHistorySend,
  solicitousHistorySendPaid,
  totalContracts,
  totalContractsNotReachable,
  totalSolicitousHistorySend,
  totalSolicitousHistorySendPaid,
} from "./kpis";
import ApplicationStatus from "../../components/ApplicationStatus";
import Container from "../../ui-components/Container";

function Home() {
  return (
    <Fragment>
      <div className="sticky w-full top-0 bg-white z-10">
        <Container>
          <div className="flex-shrink-0 flex justify-between items-center w-full h-16 bg-white border-b border-gray-200 justify-between items-center">
            <p className="hidden md:block">Stato applicazione</p>

            <ApplicationStatus />
          </div>
        </Container>
      </div>
      <div>
        <PageHeading title="Home" />
      </div>
      <ErrorBoundary from="open-credit" hide>
        <KpiList
          list={[
            solicitousHistorySend,
            solicitousHistorySendPaid,
            totalSolicitousHistorySend,
            totalSolicitousHistorySendPaid,
          ]}
          apiUrl="landing/solicitous"
        />
        <KpiList list={[openCreditAging, openCredit]} apiUrl="landing/open" />
        <KpiList
          list={[totalContracts, totalContractsNotReachable]}
          apiUrl="landing/consumers"
        />
      </ErrorBoundary>
    </Fragment>
  );
}

export default Home;
