import React from "react";
import Button from "../../../ui-components/Button";
import { useContext } from "react";
import { ConfigureOptimizerContext } from "../State/ConfigureOptimizerContext";
import ApiService from "../../../services/ApiService";

function StepperControl() {
  const context = useContext(ConfigureOptimizerContext);
  const adrIdList = context.adrSelectedList?.map((item) => item.id);
  const newBlockSelected = context.blockSelected.map((obj) => {
    // Create a copy of the object
    const newObj = { ...obj };

    delete newObj.id;
    delete newObj.tableData;

    return newObj;
  });

  const obj = {
    des_name: context.configurationName,
    flg_primary: true,
    adrs_ids: adrIdList,
    blocks: newBlockSelected,
  };

  const isNextDisabled =
    (context.currentStep === 1 && context.isScopeDisaligned) ||
    (context.currentStep === 2 && (
      context.blockSelected.length === 0 ||
      context.blockSelected.length > 20 ||
      context.redLines ||
      context.isSplitDefinitionDisaligned
    )) ||
    (context.currentStep === 3 && !context.adrSelectedList?.length) ||
    (context.currentStep === 4 && !context.configurationName);

  function handleSave() {
    if (isNextDisabled)
      return;

    if (context.currentStep > 3) {
      ApiService.postApiJson(`optimization/configuration`, obj)
        .then(() => {
          context.toggleConfigurePage();
          context.removeStep();
          context.removeStep();
        })
        .catch(() => {
          console.error("Error when fetching data");
        });
    }
    else
      context.addStep();
  }

  function handleBack() {
    context.setBlockSelected([]);
    context.removeStep();
  }

  return (
    <div className="flex justify-around mt-12 mb-8">
      <Button styleType={"default"} onClick={handleBack}>
        {context.currentStep > 1 ? "Precedente" : "Esci"}
      </Button>
      <Button onClick={handleSave} disabled={isNextDisabled}>
        {context.currentStep > 3 ? "Salva" : "Successivo"}
      </Button>
    </div>
  );
}

export default StepperControl;
