import React, { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import ChevronRight from "@material-ui/icons/ChevronRight";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Info from "@material-ui/icons/Info";

import {
  SearchIcon,
  FilterIcon,
  TrashIcon,
  PencilIcon,
  ArrowCircleUpIcon,
  CloudUploadIcon,
  ExclamationCircleIcon,
  ClockIcon,
  PlusIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

const classSmall =
  "flex-shrink-0 h-4 w-4 opacity-60 group-hover:opacity-80";
const classMedium =
  "flex-shrink-0 h-6 w-6 opacity-60 group-hover:opacity-80";

export const tableIcons = {
  Add: forwardRef((props, ref) => <PlusIcon {...props} className={classMedium} ref={ref} />),
  Refresh: forwardRef((props, ref) => <RefreshIcon {...props} className={classMedium} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Download: forwardRef((props, ref) => <SaveAlt {...props} className={classMedium} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Info: forwardRef((props, ref) => <Info {...props} ref={ref} />),

  Delete: forwardRef((props, ref) => (
    <TrashIcon {...props} ref={ref} className={classMedium} />
  )),
  Edit: forwardRef((props, ref) => (
    <PencilIcon {...props} ref={ref} className={classMedium} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterIcon {...props} ref={ref} className={classSmall} />
  )),
  Search: forwardRef((props, ref) => (
    <SearchIcon {...props} ref={ref} className={classSmall} />
  )),
  ArrowCircleUp: forwardRef((props, ref) => (
    <ArrowCircleUpIcon {...props} ref={ref} className={classSmall} />
  )),
  CloudUpload: forwardRef((props, ref) => (
    <CloudUploadIcon {...props} ref={ref} className={classMedium} />
  )),
  Error: forwardRef((props, ref) => (
    <ExclamationCircleIcon {...props} ref={ref} className={`${classMedium} text-red-600`} />
  )),
  Clock: forwardRef((props, ref) => (
    <ClockIcon {...props} ref={ref} className={classMedium} />
  )),
  Spinner: forwardRef((props, ref) => (
    <svg
      class="animate-spin m-1 h-5 w-5 text-black"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )),
};
