import axios from "axios";
import { API_URL } from "../../utils/constants";
import AuthenticationService from "../AuthenticationService";

export default class ApiService {
  static getHeaders() {
    const userData = AuthenticationService.getUserData();
    return { Authorization: "Bearer " + userData.access_token };
  }

  static async getApiBlog(typeObject, args) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = args === undefined ? baseUrl : baseUrl + `${args}`;
    return await axios
      .get(url, { headers: this.getHeaders(), responseType: 'blob' })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async getApi(typeObject, args) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = args === undefined ? baseUrl : baseUrl + `${args}`;
    return await axios
      .get(url, { headers: this.getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async postApiJson(typeObject, data) {
    const jsonData = JSON.stringify(data);
    let requestHeaders = this.getHeaders();
    requestHeaders["Content-Type"] = "application/json";
    return await axios
      .post(`${API_URL}/${typeObject}/`, jsonData, { headers: requestHeaders })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async putApiJson(typeObject, data) {
    const jsonData = JSON.stringify(data);
    let requestHeaders = this.getHeaders();
    requestHeaders["Content-Type"] = "application/json";
    return await axios
      .put(`${API_URL}/${typeObject}`, jsonData, { headers: requestHeaders })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async publicPostApi(typeObject, data, json=false) {
    const jsonData = JSON.stringify(data);
    const requestHeaders = json ? {"Content-Type": "application/json"} : {};
    return await axios
      .post(`${API_URL}/${typeObject}`, jsonData, { headers: requestHeaders })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async deleteApi(typeObject, data=null) {
    const config = {headers: this.getHeaders(), ...(!!data ? {data} : {})}
    return await axios
      .delete(`${API_URL}/${typeObject}/`, config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}
